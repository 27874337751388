import React from 'react';
import { useDateFormatter } from '@drivekyte/date-utils';
import { Box, Typography } from '@drivekyte/web-components';
import { useMedia } from '@drivekyte/ui';
import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { SERVICE_AREAS_ROAD_TRIPS } from '@/constants/service-area-pages';
import { ServiceArea } from '@/types/service-area';
import imageShimmer from '@/utils/image-shimmer';
import { cityData } from './data';

const MAX_IMAGE_WIDTH = 290;
const MAX_IMAGE_HEIGHT = 228;

const DEFAULT_DAYS_AHEAD = 7;

type ServiceAreaItemProps = ServiceArea & { callToActionLabel: string };

const ServiceAreaItem = ({
  cover_image_url,
  name,
  callToActionLabel,
}: ServiceAreaItemProps) => {
  const router = useRouter();
  const { formatISOWithoutTimezone } = useDateFormatter();

  const media = useMedia();
  const isMobile = media.xs;

  const citySlug = name
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace('fort', 'ft') as (typeof SERVICE_AREAS_ROAD_TRIPS)[number];

  const handleClick = async () => {
    const startDateValue = dayjs().add(DEFAULT_DAYS_AHEAD, 'day').toDate();
    const startDate = formatISOWithoutTimezone(startDateValue);
    const key = camelCase(citySlug);
    const linkData = cityData[key];

    await router.push({
      pathname: 'https://kyte.com/subscription/car-picker',
      query: {
        start_address: linkData.address,
        start_timezone: linkData.timezone,
        start_date: startDate,
      },
    });
  };

  return (
    <Box
      display="flex"
      position="relative"
      borderRadius="round"
      height={`${MAX_IMAGE_HEIGHT}px`}
      cursor="pointer"
      onPress={handleClick}
    >
      <Image
        fill
        src={cover_image_url}
        style={{ objectFit: 'cover' }}
        alt={`${name} Image`}
        placeholder="blur"
        blurDataURL={imageShimmer(MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT)}
        quality={isMobile ? 1 : 75}
      />
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        position="absolute"
        bottom="0"
        padding="base"
      >
        <Typography variant="headline-2" color="secondary">
          {name}
        </Typography>
        <Box>
          <Typography variant="body-1" color="secondary">
            {callToActionLabel}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Image = styled(NextImage)`
  border-radius: ${({ theme }) => theme.borderRadius.base}px;
`;

export default ServiceAreaItem;

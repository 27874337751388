import { ThemeBackground } from '@/components/cms/types';
import { useMedia } from '@drivekyte/ui';
import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
} from '@drivekyte/web-components';
import { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
// using the old growth component, since the cms component takes strapi props
import SectionHeader from '@/components/growth/section-header/';
import { ComponentDataContext } from '@/utils/strapi-cms/component-data-context';
import ReviewItem from './components/review';
import {
  Review,
  ReviewProps,
  ReviewTrackerResponse,
  ReviewTrackerReviews,
} from './types';

enum ReviewsTemplate {
  CUSTOM = 'custom',
  GENERAL = 'general',
  AIRPORT = 'airport',
}

const fetchReviewsFromReviewTrackers = async (
  url: string,
  authorization: string,
): Promise<ReviewTrackerResponse> => {
  const response = await fetch(url, {
    headers: {
      Authorization: authorization,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch reviews');
  }

  return response.json();
};

const Reviews = ({ data }: { data: ReviewProps }) => {
  const media = useMedia();
  const isMobile = media.xs;
  const [fetchedReviews, setFetchedReviews] = useState<ReviewTrackerReviews[]>(
    [],
  );
  const [reviewSourceName, setReviewSourceName] = useState<string>('');
  const { generalReviewSection, airportSpecificReviewSection } =
    useContext(ComponentDataContext);

  const mapReviewsTemplateToData = {
    [ReviewsTemplate.GENERAL]: generalReviewSection as Partial<ReviewProps>,
    [ReviewsTemplate.AIRPORT]:
      airportSpecificReviewSection as Partial<ReviewProps>,
    [ReviewsTemplate.CUSTOM]: data,
  };
  const reviewsData = mapReviewsTemplateToData[data.type] || data;

  useEffect(() => {
    const fetchReviews = async () => {
      if (
        !data.reviewTrackers ||
        !data.reviewTrackers.url ||
        !data.reviewTrackers.authorization
      ) {
        return;
      }
      try {
        const response = await fetchReviewsFromReviewTrackers(
          data.reviewTrackers.url,
          data.reviewTrackers.authorization,
        );
        setReviewSourceName(data.reviewTrackers.reviewSourceName ?? '');
        const maxNumberOfReviews = data.reviewTrackers.maxNumberOfReviews ?? 5;

        if (response.reviews.length > maxNumberOfReviews) {
          setFetchedReviews(response.reviews.slice(0, maxNumberOfReviews));
        } else {
          setFetchedReviews(response.reviews);
        }
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    void fetchReviews();
  }, []);

  return (
    <ShadedContainer
      backgroundTheme={data.theme}
      style={{
        minHeight: isMobile ? '1210px' : '892px',
      }}
    >
      <Container>
        <Box
          display="flex"
          flexDirection="row"
          paddingBottom="xxxxLarge"
          paddingTop="xxxxLarge"
        >
          <Grid container spacing={!isMobile ? 6 : 2}>
            <Grid item md={4} sm={12}>
              <SectionHeader
                primaryText={reviewsData?.header?.primaryText || ''}
                successText={reviewsData?.header?.successText}
                captionText={reviewsData?.header?.captionText}
                captionBoldText={reviewsData?.header?.captionBoldText}
                noMargin
              />
            </Grid>
            <Grid container item md={8} xs={12} direction="row" spacing={1}>
              {fetchedReviews.length === 0 &&
                (reviewsData?.reviews || []).map(
                  (
                    { title, date, text, authorName, source }: Review,
                    index: number,
                  ) => (
                    <Box key={title}>
                      <ReviewItem
                        title={title}
                        link={source}
                        date={date}
                        text={text}
                        author={authorName}
                        isReviewTracker={false}
                      />
                      {index < (reviewsData?.reviews || []).length - 1 && (
                        <ReviewDivider />
                      )}
                    </Box>
                  ),
                )}
              {fetchedReviews.length > 0 &&
                fetchedReviews.map(
                  (
                    {
                      published_at,
                      content,
                      author,
                      source_name,
                      url,
                      rating,
                    }: ReviewTrackerReviews,
                    index: number,
                  ) => (
                    <Box key={`${author}` + `${index}`} width={'100%'}>
                      <ReviewItem
                        link={{
                          url: url,
                          newTab: true,
                          text: reviewSourceName || source_name,
                        }}
                        date={published_at}
                        text={content}
                        author={author}
                        isReviewTracker={true}
                        rating={rating}
                      />
                      {index < fetchedReviews.length - 1 && <ReviewDivider />}
                    </Box>
                  ),
                )}
            </Grid>
          </Grid>
        </Box>
        <Hidden smUp>
          <Divider />
        </Hidden>
      </Container>
    </ShadedContainer>
  );
};

type ShadedContainerProps = {
  padding?: string;
  backgroundTheme?: ThemeBackground;
};

const ShadedContainer = styled.div<ShadedContainerProps>`
  ${({ backgroundTheme, theme, padding }) => css`
    background: ${backgroundTheme === 'light-green'
      ? 'rgba(9, 138, 34, 0.05)'
      : 'none'};
    padding: ${padding ? theme.spacings[padding] || 0 : 0}px 0;
  `}
`;

const ReviewDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.borderColors.primary};
  margin-top: ${({ theme }) => theme.spacings.xxxLarge}px;
  margin-bottom: ${({ theme }) => theme.spacings.xxxLarge}px;
`;

export default Reviews;

import SearchForm from '@/components/search-form';
import { Box, Typography, useMedia } from '@drivekyte/ui';
import Image, { StaticImageData } from 'next/image';

type SearchMobileProps = {
  headline: string;
  description: string;
  ctaText: string;
  backgroundImageSrc: StaticImageData | string;
  backgroundImageAltText: string;
  startAddressLabel?: string;
  startAddressPlaceholder?: string;
  startAddressModalTitle?: string;
  returnAddressLabel?: string;
  returnAddressPlaceholder?: string;
  returnAddressModalTitle?: string;
};

const SearchMobile = ({
  headline,
  description,
  ctaText,
  backgroundImageSrc,
  backgroundImageAltText,
  startAddressLabel,
  startAddressPlaceholder,
  startAddressModalTitle,
  returnAddressLabel,
  returnAddressPlaceholder,
  returnAddressModalTitle,
}: SearchMobileProps) => {
  const media = useMedia();

  return (
    <Box display="flex" position="relative" alignItems="center" gap="$small">
      <Box width="100%" height={media.gtXs ? 320 : 180} borderRadius="$small">
        <Image
          fill
          src={backgroundImageSrc}
          alt={backgroundImageAltText}
          style={{ objectFit: 'cover', borderRadius: 'inherit' }}
          quality={100}
          priority
        />
      </Box>
      <Box gap="$small" width="100%">
        <Box>
          <Typography variant="headingLarge">{headline}</Typography>
          <Typography variant="paragraphMedium" marginTop="$xTiny">
            {description}
          </Typography>
        </Box>
        <SearchForm
          ctaText={ctaText}
          startAddressLabel={startAddressLabel}
          startAddressPlaceholder={startAddressPlaceholder}
          startAddressModalTitle={startAddressModalTitle}
          returnAddressLabel={returnAddressLabel}
          returnAddressPlaceholder={returnAddressPlaceholder}
          returnAddressModalTitle={returnAddressModalTitle}
        />
      </Box>
    </Box>
  );
};

export default SearchMobile;

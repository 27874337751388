import React from 'react';
import { Box, Container } from '@drivekyte/web-components';
import { MediaData } from '@/components/cms/types';

type VideoProps = {
  video: MediaData;
  width: number;
  height: number;
  controls: boolean;
  autoPlay: boolean;
  muted: boolean;
};

const Video = ({ data }: { data: VideoProps }) => {
  const {
    video: {
      data: {
        attributes: { url, mime },
      },
    },
    width,
    height,
    controls,
    autoPlay,
    muted,
  } = data;

  return (
    <Container>
      <Box marginVertical="xxLarge">
        <video
          controls={controls}
          width={width}
          height={height}
          autoPlay={autoPlay}
          muted={muted}
        >
          <source src={url} type={mime} />
        </video>
      </Box>
    </Container>
  );
};

export default Video;

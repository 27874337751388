import React from 'react';
import { Typography } from '@drivekyte/web-components';
import styled from 'styled-components';
import CmsLink from '@/components/cms/link/link';
import Markdown from '@/components/markdown';
import { ReviewProps } from './types';
import Image from 'next/image';
import StarRating from './stars-rating';
import { Box } from '@drivekyte/ui';

function convertDate(isoString) {
  const date = new Date(isoString);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

const Review = ({
  title,
  author,
  date,
  text,
  link,
  isReviewTracker,
  rating,
}: ReviewProps) => {
  if (isReviewTracker) {
    date = convertDate(date);
  }
  return (
    <Box zIndex={1}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor="transparent"
      >
        <Box maxWidth="100vw">
          <Box marginBottom="tiny">
            {rating ? (
              <StarRating rating={rating} />
            ) : (
              <Image
                src="/images/icons/5stars.png"
                alt="5 star rating"
                width={107}
                height={20}
              />
            )}
          </Box>
          {title && (
            <Typography variant="headline-3" color="primary">
              {title}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        marginTop="tiny"
        marginBottom="small"
      >
        <Typography variant="label-3" color="quaternary">
          {author}
        </Typography>
        <Divider />
        <Typography variant="label-3" fontWeight={400} color="quaternary">
          {date}
        </Typography>
        <Divider />
        <Typography variant="label-3" fontWeight={400} color="quaternary">
          <CmsLink data={link} author={author} />
        </Typography>
      </Box>
      <Markdown>{`"${text}"`}</Markdown>
    </Box>
  );
};

const Divider = styled.div`
  height: 14px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.quinary};
  margin-left: ${({ theme }) => theme.spacings.xSmall}px;
  margin-right: ${({ theme }) => theme.spacings.xSmall}px;
`;

export default Review;

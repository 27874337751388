import { AsFeaturedInSectionType, Media } from '@/components/cms/types';

export enum Variation {
  Row = 'row',
  Stacked = 'stacked',
}

export type ComponentProps = {
  isMobile: boolean;
  title: string;
  displayImages: Media[];
  fullWidth?: boolean;
};

export type AsFeaturedInData = {
  id: number;
  __component?: string;
  template: AsFeaturedInSectionType;
  title: string;
  image: {
    data: Media[];
  };
  fullWidth: boolean;
};

export type AsFeaturedInProps = {
  data: AsFeaturedInData;
  variation?: Variation;
};

export type AsFeaturedInAttributes = {
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  asFeaturedIn: {
    id: number;
    title: string;
    template: string;
    fullWidth: boolean;
    image: {
      data: Media[];
    };
  };
};

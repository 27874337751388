import Rating from '@/components/cms/shared-search-components/rating';
import InputAddress from '@/components/search-bar/components/input-address';
import useBookingCart from '@/hooks/use-booking-cart';
import useCalendarValidDates from '@/hooks/use-calendar-valid-dates';
import usePlacesSearch from '@/hooks/use-places-search-wrapper';
import useSearch from '@/hooks/use-search';
import { ComponentDataContext } from '@/utils/strapi-cms/component-data-context';
import {
  Box,
  Button,
  createTimePickerOptions,
  Typography,
} from '@drivekyte/web-components';
import { useContext, useEffect } from 'react';
import Divider from './components/divider';
import EndDate from './components/end-date';
import ReturnToggler from './components/return-toggler';
import SearchBoxContainer from './components/search-box-container';
import SearchSubmit from './components/search-submit';
import StartDate from './components/start-date';
import { SearchContentProps } from './types';

const SearchContent = ({
  submitButtonLabel = 'Submit',
  buttonPosition = 'inline',
  rating,
  showSameLocationToggler = true,
  searchFieldLowerRightText,
}: SearchContentProps) => {
  const { serviceArea } = useContext(ComponentDataContext);

  const { updateBookingCart } = useBookingCart();
  const {
    bookingCart,
    inputState,
    isFetchingQuotes,
    isFormValid,
    isLoadingTimeSlots,
    onChange,
    onChangeAddressToggle,
    onChangeEndAddress,
    onChangeEndDate,
    onChangeStartAddress,
    onChangeStartDate,
    onSubmitWithQuotes,
    setInputState,
    timeSlotsData,
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    timeSlotsStartError,
    timeSlotsEndError,
  } = useSearch();

  useCalendarValidDates({
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    startTz: timeSlotsData?.start.tz,
    endTz: timeSlotsData?.end.tz,
    selectedDates: bookingCart,
    onChange,
  });

  const { place, name } = serviceArea || {};
  const placesSearchValue = place || '';

  const { isLoading, data } = usePlacesSearch(placesSearchValue);

  const hasData = !isLoading && data && data.length > 0;

  useEffect(() => {
    if (!place || !name) {
      return;
    }

    const bookingAddress = data?.find(
      (address) => address.place_type === 'airport',
    );

    const address = bookingAddress?.map_to?.formatted_address || place;

    // TODO verify here if the new logic is needed
    /* we prefill address data for pages which have a relation with service area */
    updateBookingCart({
      start_place: place,
      start_address: address,
      end_place: place,
      end_address: address,
      service_area_code: name,
      end_service_area_code: name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData]);

  return (
    <>
      <SearchBoxContainer onSubmit={onSubmitWithQuotes}>
        {bookingCart.isSameDeliveryReturnLocations ? (
          <Box display="flex" flex={6.5} flexGrow={1}>
            <InputAddress
              active={inputState.showStartAddress}
              initialInputValue={
                bookingCart.startPlace ?? bookingCart.startAddress
              }
              label="Delivery & Return Location"
              onSelect={onChangeStartAddress}
              placeholder="Type an address and city..."
            />
            <Divider />
          </Box>
        ) : (
          <Box display="flex" flex={6.5} flexGrow={1}>
            <Box width="50%">
              <InputAddress
                active={inputState.showStartAddress}
                initialInputValue={
                  bookingCart.startPlace ?? bookingCart.startAddress
                }
                onSelect={onChangeStartAddress}
                label="Delivery Location"
                placeholder="Type an address and city..."
              />
            </Box>
            <Divider />
            <Box width="50%">
              <InputAddress
                active={inputState.showEndAddress}
                initialInputValue={
                  bookingCart.endPlace ?? bookingCart.endAddress
                }
                onSelect={onChangeEndAddress}
                label="Return Location"
                placeholder="Type an address and city..."
              />
            </Box>
            <Divider />
          </Box>
        )}
        <Box display="flex" flex={2.5} cursor="pointer">
          <StartDate
            active={!timeSlotsStartError && inputState.showStartDate}
            label="From"
            onChange={onChangeStartDate}
            onChangeInputState={setInputState}
            startDate={bookingCart.startDate}
            timePickerProps={{
              loading: isLoadingTimeSlots,
              options:
                timeSlotsData?.start.timeSlots ||
                createTimePickerOptions(
                  7,
                  22,
                  bookingCart.startDate,
                  '2-digit',
                ),
              titleText: 'Available delivery times',
            }}
            disabled={timeSlotsStartError}
            emptyTitleText="No times available"
            hasNoOptions={timeSlotsData?.start.timeSlots.length === 0}
          />
        </Box>
        <Divider />
        <Box display="flex" flex={2.5} cursor="pointer">
          <EndDate
            active={!timeSlotsEndError && inputState.showEndDate}
            endDate={bookingCart.endDate}
            label="To"
            onChange={onChangeEndDate}
            onChangeInputState={setInputState}
            startDate={bookingCart.startDate}
            timePickerProps={{
              loading: isLoadingTimeSlots,
              options:
                timeSlotsData?.end.timeSlots ||
                createTimePickerOptions(7, 22, bookingCart.endDate, '2-digit'),
              titleText: 'Available return times',
            }}
            disabled={timeSlotsEndError}
            emptyTitleText="No times available"
            hasNoOptions={timeSlotsData?.end.timeSlots.length === 0}
          />
        </Box>
        {buttonPosition === 'inline' && (
          <Box display="flex" margin="small">
            <SearchSubmit
              submitButtonLabel={submitButtonLabel}
              loading={isFetchingQuotes || isLoadingTimeSlots}
              disabled={!isFormValid}
            />
          </Box>
        )}
      </SearchBoxContainer>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        style={{ height: 28 }}
      >
        <Box alignItems="center" display="flex" flexDirection="row">
          {showSameLocationToggler && (
            <ReturnToggler
              sameLocationToggleLabel="Same delivery & return locations"
              value={bookingCart.isSameDeliveryReturnLocations}
              onChange={onChangeAddressToggle}
            />
          )}
        </Box>
        {rating && <Rating data={rating} />}
        {searchFieldLowerRightText && (
          <Typography color="quaternary" variant="label-1">
            {searchFieldLowerRightText}
          </Typography>
        )}
      </Box>
      {buttonPosition === 'bottom' && (
        <Box display="flex" width="100%" marginTop="xLarge">
          <Button
            onPress={onSubmitWithQuotes}
            loading={isFetchingQuotes}
            disabled={!isFormValid || isLoadingTimeSlots}
            type="submit"
            variant="primary"
            fullWidth
          >
            {submitButtonLabel}
          </Button>
        </Box>
      )}
    </>
  );
};

export default SearchContent;

import React, { useEffect, useState } from 'react';
import { useDateFormatter } from '@drivekyte/date-utils';
import { Calendar, createTimePickerOptions } from '@drivekyte/web-components';
import dayjs from 'dayjs';
import usePush from '@/hooks/use-push';
import useTimeSlots from '@/hooks/use-time-slots';

type EnhancedCalendarProps = {
  onClose: () => void;
  onSubmit: (
    nextEndDate: string,
    nextStartDate: string,
    nextEndTimeZone?: string,
    nextStartTimeZone?: string,
  ) => void;
  visible: boolean;
  endDate: Date;
  endAddress: string;
  startDate: Date;
  startAddress: string;
  disableEditStartDate?: boolean;
  disableStartTime?: boolean;
  disableEndTime?: boolean;
  serviceAreaUUID?: string;
  endServiceAreaUUID?: string;
};

const EnhancedCalendar = ({
  onClose,
  onSubmit,
  visible,
  endAddress,
  endDate,
  startAddress,
  startDate,
  disableEditStartDate,
  disableStartTime,
  disableEndTime,
  serviceAreaUUID,
  endServiceAreaUUID,
}: EnhancedCalendarProps) => {
  const push = usePush();
  const { formatISOWithoutTimezone } = useDateFormatter();

  const [tempDates, setTempDates] = useState({
    endDate,
    startDate,
  });

  const { data, isLoading, timeSlotsStartError, timeSlotsEndError } =
    useTimeSlots({
      endAddress,
      startAddress,
      endDate: tempDates.endDate || tempDates.startDate,
      startDate: tempDates.startDate,
      serviceAreaUUID,
      endServiceAreaUUID,
    });

  const handleDateChanged = (nextStartDate: Date, nextEndDate: Date) =>
    setTempDates({
      endDate: nextEndDate,
      startDate: nextStartDate,
    });

  const handleSubmit = (nextStartDate: Date, nextEndDate?: Date) => {
    if (nextStartDate && nextEndDate) {
      onSubmit(
        formatISOWithoutTimezone(nextEndDate),
        formatISOWithoutTimezone(nextStartDate),
        data?.end.tz,
        data?.start.tz,
      );
    }
  };

  useEffect(() => {
    if (data?.end.tz && data?.start.tz)
      push({
        end_timezone: data?.end.tz,
        start_timezone: data?.start.tz,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.end.tz, data?.start.tz]);

  return (
    <Calendar
      headerTitleText="Date & Time"
      emptyStartDateText="Select a start date"
      emptyEndDateText="Select an end date"
      emptyTimeslotsText="No times available"
      loading={isLoading}
      submitButtonText="Save"
      endDate={endDate}
      onRequestToClose={onClose}
      onChangeDates={handleDateChanged}
      onSubmit={handleSubmit}
      visible={visible}
      startDate={startDate}
      startTimePickerOptions={
        data?.start.timeSlots ??
        createTimePickerOptions(7, 22, tempDates.startDate)
      }
      endTimePickerOptions={
        data?.end.timeSlots ?? createTimePickerOptions(7, 22, tempDates.endDate)
      }
      datePickerProps={{
        fromMonth: startDate,
        disabledDays: [
          {
            before: disableEditStartDate ? startDate : new Date(),
          },
        ],
        initialMonth: startDate,
        toMonth: dayjs(startDate).add(1, 'year').toDate(),
      }}
      disableEditStartDate={disableEditStartDate}
      disableStartTime={disableStartTime || timeSlotsStartError}
      disableEndTime={disableEndTime || timeSlotsEndError}
      helpText="Be ready to receive your Kyte +/- 15 min of the scheduled time"
    />
  );
};

export default EnhancedCalendar;

import React from 'react';
import { Button } from '@drivekyte/web-components';

type SearchSubmitProps = {
  loading?: boolean;
  disabled?: boolean;
  submitButtonLabel: string;
};

const SearchSubmit = ({
  loading,
  disabled,
  submitButtonLabel,
}: SearchSubmitProps) => (
  <Button
    loading={loading}
    disabled={disabled}
    type="submit"
    variant="primary"
    fullWidth
  >
    {submitButtonLabel}
  </Button>
);

export default SearchSubmit;

import { Check, Close } from '@drivekyte/icons';
import { getTokenValue, useTheme } from '@drivekyte/ui';
import React from 'react';

interface TableDataProps {
  isLast: boolean;
  isHighlighted?: boolean;
  checked?: boolean;
}

const TableData: React.FC<TableDataProps> = ({
  checked,
  isLast,
  isHighlighted,
}) => {
  const theme = useTheme();

  return (
    <td
      style={{
        ...(isHighlighted
          ? {
              borderRight: `2px solid ${theme.green400.val}`,
              borderLeft: `2px solid ${theme.green400.val}`,
            }
          : {}),
        boxSizing: 'border-box',
        borderBottomLeftRadius: isLast ? getTokenValue('$large', 'radius') : 0,
        borderBottomRightRadius: isLast ? getTokenValue('$large', 'radius') : 0,
        borderBottomWidth: isLast && isHighlighted ? 2 : 0,
        borderBottomColor: theme.green400.val,
        borderBottomStyle: 'solid',
        borderTop: `1px solid ${theme.borderDefault.val}`,
        height: '64px',
        verticalAlign: 'middle',
        textAlign: 'center',
      }}
    >
      {checked ? (
        <Check size={28} color="$contentPositive" />
      ) : (
        <Close size={28} color="$contentDisabled" />
      )}
    </td>
  );
};

export default TableData;

import React from 'react';
import { Box, Switch, Typography } from '@drivekyte/web-components';
import useTranslation from '@/hooks/use-translation';

export type DeliveryAndReturnTogglerProps = {
  onChange: (nextValue: boolean) => void;
  value: boolean;
  enableAddressToggler: boolean;
};

const DeliveryAndReturnToggler = ({
  value,
  onChange,
  enableAddressToggler = true,
}: DeliveryAndReturnTogglerProps) => {
  const { t } = useTranslation('pages.landing-page.search-box.where');
  const handleChange = (nextValue: boolean) => onChange(nextValue);

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      <Box marginRight="small">
        <Switch
          onChange={handleChange}
          value={value}
          disabled={!enableAddressToggler}
        />
      </Box>
      <Box>
        <Typography color="primary" variant="label-1">
          {t('same-location-toggler')}
        </Typography>
      </Box>
    </Box>
  );
};

export default DeliveryAndReturnToggler;

import useTranslation from '@/hooks/use-translation';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import { QueryBookingCart } from '@/types/booking-cart';
import { Box } from '@drivekyte/ui';
import { Place } from '@drivekyte/use-places-search';
import { getIsMappingToLot } from '@/utils/get-mapping-to-lot/get-is-mapping-to-lot';
import WhereItem from './components/where-item';

type WhereProps = {
  startAddress?: string;
  endAddress?: string;
  isSameDeliveryReturnLocations: boolean;
  onChangeAddress: (nextAddressQuery: Partial<QueryBookingCart>) => void;
  startAddressLabel?: string;
  startAddressPlaceholder?: string;
  startAddressModalTitle?: string;
  returnAddressLabel?: string;
  returnAddressPlaceholder?: string;
  returnAddressModalTitle?: string;
};

const Where = ({
  startAddress = '',
  endAddress = '',
  isSameDeliveryReturnLocations,
  onChangeAddress,
  startAddressLabel,
  startAddressPlaceholder,
  startAddressModalTitle,
  returnAddressLabel,
  returnAddressPlaceholder,
  returnAddressModalTitle,
}: WhereProps) => {
  const { t } = useTranslation('pages.checkout.where-and-when');
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  const handleChangeAddress = (key: string) => (place: Place) => {
    const address = place.map_to?.display_text || place.display_text;

    const isMappingToPickupAtLot = getIsMappingToLot(place);

    trackEvent(AnalyticsEvents.BookingFlow.SearchAddressSubmitted, {
      address: place.display_text,
      is_start_leg: key === 'start',
      is_end_leg: key === 'end' || isSameDeliveryReturnLocations,
    });

    if (isSameDeliveryReturnLocations) {
      onChangeAddress({
        start_address: address,
        end_address: address,
        start_place: place.display_text,
        end_place: place.display_text,
        service_area_uuid: place.service_area_uuid,
        service_area_code: place.service_area_code,
        is_mapping_start_to_pickup_at_lot: isMappingToPickupAtLot,
        is_mapping_end_to_pickup_at_lot: isMappingToPickupAtLot,
        end_service_area_uuid: place.service_area_uuid,
        end_service_area_code: place.service_area_code,
        start_address_exclusion_zone_id: place.map_to?.exclusion_zone?.uuid,
        end_address_exclusion_zone_id: place.map_to?.exclusion_zone?.uuid,
      });
    } else {
      onChangeAddress({
        [`${key}_address`]: address,
        [`${key}_place`]: place.display_text,
        [`is_mapping_${key}_to_pickup_at_lot`]: isMappingToPickupAtLot,
        [`${key === 'end' ? 'end_' : ''}service_area_uuid`]:
          place.service_area_uuid,
        [`${key === 'end' ? 'end_' : ''}service_area_code`]:
          place.service_area_code,
        [`${key === 'end' ? 'end_' : 'start_'}address_exclusion_zone_id`]:
          place.map_to?.exclusion_zone?.uuid,
      });
    }
  };

  return (
    <Box gap="$tiny">
      {isSameDeliveryReturnLocations ? (
        <WhereItem
          label={startAddressLabel}
          title={startAddressModalTitle ?? t('delivery.modal.title')}
          onChange={handleChangeAddress('start')}
          value={startAddress}
          placeholder={startAddressPlaceholder}
          isStartLeg
          isEndLeg
        />
      ) : (
        <>
          <WhereItem
            label={startAddressLabel}
            title={startAddressModalTitle ?? t('delivery.modal.title')}
            onChange={handleChangeAddress('start')}
            value={startAddress}
            placeholder={startAddressPlaceholder}
            isStartLeg
          />
          <WhereItem
            label={returnAddressLabel}
            title={returnAddressModalTitle ?? t('return.modal.title')}
            onChange={handleChangeAddress('end')}
            value={endAddress}
            placeholder={returnAddressPlaceholder}
            isEndLeg
          />
        </>
      )}
    </Box>
  );
};

export default Where;

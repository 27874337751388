import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@drivekyte/web-components';
import useQuery from '@/hooks/use-query';
import { ServiceArea } from '@/types/service-area';
import ServiceAreaItem from '../service-area-item';

type ServiceAreasProps = {
  children: React.ReactNode;
  serviceAreas: ServiceArea[];
  expandLabel: string;
  clickOnItemLabel: string;
};

const ServiceAreas = ({
  children,
  serviceAreas,
  expandLabel,
  clickOnItemLabel,
}: ServiceAreasProps) => {
  const query = useQuery();
  const [expanded, setExpanded] = useState<boolean>(!!query('service_area'));
  const serviceAreasFiltered = expanded
    ? serviceAreas
    : serviceAreas.slice(0, 4);

  return (
    <Container disableGutters>
      <Box id="service-area-list" paddingBottom="xxxxLarge" paddingTop="xLarge">
        {children}
        <Grid container spacing={3}>
          {serviceAreasFiltered.map(
            ({ abbreviation, center, cover_image_url, id, name }) => (
              <Grid item md={3} sm={6} xs={12} key={id}>
                <ServiceAreaItem
                  abbreviation={abbreviation}
                  center={center}
                  cover_image_url={cover_image_url}
                  id={id}
                  name={name}
                  callToActionLabel={clickOnItemLabel}
                />
              </Grid>
            ),
          )}
        </Grid>
        {expanded ? null : (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            marginTop="xLarge"
          >
            <Button variant="secondary" onPress={() => setExpanded(true)}>
              {expandLabel}
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ServiceAreas;

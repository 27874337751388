import React from 'react';
import InputDate from '../input-date';
import {
  START_DISABLED_DATES_BEFORE,
  START_FROM_MONTH,
  START_TO_MONTH,
} from '@/constants/booking-flow';
import { formatDateTime } from '@/utils/date-formatter';
import { StartDateProps } from './types';

const StartDate = ({
  active,
  label,
  onChange,
  onChangeInputState,
  startDate,
  timePickerProps,
  disabled,
  hasNoOptions,
  emptyTitleText,
}: StartDateProps) => {
  const handleActive = () => {
    onChangeInputState({ showStartDate: true });
  };

  const handleDeactivate = () => {
    onChangeInputState({ showStartDate: false });
  };

  return (
    <InputDate
      datePickerProps={{
        disabledDays: START_DISABLED_DATES_BEFORE,
        fromMonth: START_FROM_MONTH,
        initialMonth: startDate,
        toMonth: START_TO_MONTH,
      }}
      formattedValue={hasNoOptions ? emptyTitleText : formatDateTime(startDate)}
      label={label}
      onSelect={onChange}
      popoverProps={{
        offset: [52, 12],
        onRequestToClose: handleDeactivate,
        onRequestToOpen: handleActive,
        visible: active,
      }}
      timePickerProps={{
        loading: timePickerProps.loading,
        options: timePickerProps.options,
        titleText: timePickerProps.titleText,
        tooltip: timePickerProps.tooltip,
      }}
      value={startDate}
      disabled={disabled}
    />
  );
};

export default StartDate;

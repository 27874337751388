import { Advantage } from '@/components/cms/advantages-car-rental/types';
import { AsFeaturedInAttributes } from '@/components/cms/as-featured-in/types';
import { FaqAttributes } from '@/components/cms/faq/types';
import { HowItWorksAttributes } from '@/components/cms/how-it-works/types';
import { ReviewSectionAttributes } from '@/components/cms/reviews/types';
import { ServiceAreaAtributes } from '@/components/cms/service-areas/types';
import { createContext } from 'react';

export type ComponentData = {
  carRentalAdvantages: Advantage[];
  serviceArea: ServiceAreaAtributes | null;
  generalHowItWorks: HowItWorksAttributes | null;
  generalReviewSection: ReviewSectionAttributes | null;
  airportSpecificReviewSection: ReviewSectionAttributes | null;
  generalAsFeaturedIn: AsFeaturedInAttributes | null;
  generalFaq: FaqAttributes | null;
};

export const ComponentDataContext = createContext<ComponentData>({
  carRentalAdvantages: [],
  serviceArea: null,
  generalHowItWorks: null,
  generalReviewSection: null,
  airportSpecificReviewSection: null,
  generalAsFeaturedIn: null,
  generalFaq: null,
});

import React from 'react';
import { Box, Typography } from '@drivekyte/web-components';

/*
 * Example of blockquote markdown:
 * > "Lorem ipsum dolor sit amet, consectetur adipiscing elit. {John Doe}
 *
 * If there is text in curly brackets, it will be displayed below the blockquote.
 */

const boxStyle = {
  borderLeft: '2px solid #e0e0e0',
  padding: '5px 0 5px 1.4rem',
};

const BlockQuoteComponent = ({ children }: any) => {
  const fullText =
    children && children[1].props.children ? children[1].props.children : '';

  let quoteText: string = fullText[0];
  let subText: string | null = fullText[0].match(/\{([^)]+)\}/);

  if (subText) {
    subText = subText[1];
    quoteText = quoteText
      .replace(subText, '')
      .replace(/\{|\}$/g, '')
      .trim();
  }

  return (
    <Box style={boxStyle} marginVertical="large">
      <Typography variant="headline-3" color="primary" fontWeight={700}>
        {quoteText}
      </Typography>
      {subText && (
        <Box marginTop="small">
          <Typography variant="body-1" color="primary">
            {subText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BlockQuoteComponent;

import React from 'react';
import { Box, Button, Hidden, Typography } from '@drivekyte/web-components';
import { useMedia } from '@drivekyte/ui';
import AsFeaturedIn from '@/components/cms/as-featured-in';
import { Variation } from '@/components/cms/as-featured-in/types';
import WPTypography from '@/components/typography';
import useSearch from '@/hooks/use-search';
import SearchBoxBig from '../search-box-big';
import SearchBoxCompact from '../search-box-compact';
import { SearchStackedProps } from './types';

// Note: This component is used in `search-general`, but it could also be used as a standalone component.

const SearchStacked = (props: SearchStackedProps) => {
  const {
    headlinePrimary,
    headlineSuccess,
    descriptionDesktop,
    descriptionMobile,
    submitLabel,
    asFeaturedIn,
  } = props?.data;

  const media = useMedia();
  const isMobile = media.xs;

  const {
    isFormValid,
    isLoadingTimeSlots,
    isFetchingQuotes,
    onSubmitWithQuotes,
  } = useSearch();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent="center"
    >
      <WPTypography
        variant="h1"
        color="primary"
        fontFamily="Gilroy"
        fontWeight={700}
        fontSize={isMobile ? '24' : '36'}
        lineHeight={isMobile ? 36 : 42}
        textAlign={isMobile ? 'center' : 'left'}
      >
        {headlinePrimary}
      </WPTypography>
      <WPTypography
        variant="h1"
        color="success"
        fontFamily="Gilroy"
        fontWeight={isMobile ? 800 : 700}
        fontSize={isMobile ? '24' : '36'}
        lineHeight={isMobile ? 36 : 42}
        textAlign={isMobile ? 'center' : 'left'}
      >
        {headlineSuccess}
      </WPTypography>
      <Box marginTop="small" marginBottom="small" whiteSpace="pre">
        {isMobile ? (
          <WPTypography
            color="quinary"
            variant="div"
            lineHeight={20}
            fontWeight={500}
            fontSize={12}
            textAlign={isMobile ? 'center' : 'left'}
          >
            {descriptionMobile}
          </WPTypography>
        ) : (
          <Typography
            color="quinary"
            variant="headline-3"
            center={isMobile}
            fontWeight={600}
          >
            {descriptionDesktop}
          </Typography>
        )}
      </Box>
      <Hidden smDown>
        <Box marginBottom="xxxLarge">
          <SearchBoxBig
            buttonPosition="bottom"
            submitButtonLabel={submitLabel}
          />
        </Box>
      </Hidden>
      <Hidden only={['xl', 'lg', 'md']}>
        <SearchBoxCompact>
          <Box marginTop="small" marginBottom="xxLarge">
            <Button
              fullWidth
              loading={isLoadingTimeSlots || isFetchingQuotes}
              disabled={!isFormValid}
              onPress={onSubmitWithQuotes}
            >
              {submitLabel}
            </Button>
          </Box>
        </SearchBoxCompact>
      </Hidden>
      <AsFeaturedIn data={asFeaturedIn} variation={Variation.Stacked} />
    </Box>
  );
};

export default SearchStacked;

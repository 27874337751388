import styled from 'styled-components';

const SearchContainerResponsive = styled.div`
  padding-left: ${({ theme }) => theme.spacings.small}px;
  padding-right: ${({ theme }) => theme.spacings.small}px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

export default SearchContainerResponsive;

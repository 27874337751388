import React from 'react';
import { Box, Container } from '@drivekyte/web-components';
import SplitParagraph from '@/components/cms/split-paragraph';
import WPTypography from '@/components/typography';
import LocalTip from './components/local-tip';
import TripItem from './components/trip-item';
import { LocalTripsProps, Trip } from './types';

const LocalTrips = (props: LocalTripsProps) => {
  const header = props?.data?.header;
  const trips = props?.data?.trip || [];

  return (
    <Container>
      <Box paddingVertical="large">
        <LocalTip
          tipText={header?.tipText || ''}
          primaryText={header?.primaryText}
          successText={header?.successText || ''}
          caption={header?.captionText || ''}
        />

        {trips.map((trip: Trip, index: number) => (
          <TripItem
            key={trip.title}
            index={index + 1}
            title={trip.title}
            subtitle={trip.subtitle}
            image={trip?.image?.data}
            description={trip.description}
            carClass={trip.carClass}
            recommendation={trip.recommendation}
            onSearch={() => {}}
          />
        ))}
        <Box marginVertical="xxxLarge">
          <Box paddingRight="base" marginBottom="xLarge" width="100%">
            <WPTypography
              variant="h2"
              color="primary"
              fontFamily="Gilroy"
              fontSize="28"
              lineHeight={32}
              fontWeight={700}
              textAlign="center"
            >
              {props?.data?.conclusion || ''}
            </WPTypography>
          </Box>

          <SplitParagraph>{props?.data?.description || ''}</SplitParagraph>
        </Box>
      </Box>
    </Container>
  );
};

export default LocalTrips;

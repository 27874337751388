import { forwardRef, useEffect, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';
import { BaseInput, BaseInputProps, InputLabel } from '../base-input';
import { InputMask } from '../masked-input';

export const BasicInput = forwardRef<any, BaseInputProps>(
  (
    {
      value,
      placeholder,
      hintText,
      state = 'default',
      disabled,
      label,
      mask,
      keyboardType,
      onFocus,
      onBlur,
      ...props
    }: BaseInputProps,
    ref,
  ) => {
    const [focus, setFocus] = useState(false);
    const inputRef = useRef<any>(null);
    const inputId = `${label}-id`;
    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.id = inputId;
      }
    }, []);

    const handleFocus = (e: any) => {
      setFocus(true);
      inputRef.current?.focus();
      onFocus?.(e);
    };

    const handleBlur = (e: any) => {
      setFocus(false);
      onBlur?.(e);
    };

    const hasMask = !!mask;
    const isActive = focus || !!value || !!placeholder;

    return (
      <BaseInput state={state}>
        <BaseInput.InputFrame focus={focus} disabled={disabled}>
          <InputLabel
            size={isActive ? 'small' : 'base'}
            onPress={handleFocus}
            inputId={inputId}
          >
            {label}
          </InputLabel>
          {hasMask ? (
            <InputMask
              {...props}
              disabled={disabled}
              onBlur={handleBlur}
              onFocus={handleFocus}
              options={{ mask }}
              placeholder={placeholder}
              type="custom"
              value={value}
              refInput={(inputMaskRef) => (inputRef.current = inputMaskRef)}
            />
          ) : (
            <BaseInput.InputField
              {...props}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              ref={mergeRefs([ref, inputRef])}
              id={inputId}
            />
          )}
        </BaseInput.InputFrame>
        {!!hintText && (
          <BaseInput.HintText state={state}>{hintText}</BaseInput.HintText>
        )}
      </BaseInput>
    );
  },
);

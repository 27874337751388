import React, { MouseEvent, useEffect, useRef } from 'react';
import { DateTimePicker, EmptyState, Popover } from '@drivekyte/web-components';
import { BasicInput, Box, Typography } from '@drivekyte/ui';
import styled from 'styled-components';
import { InputDateProps } from './types';

const InputDate = ({
  datePickerProps,
  formattedValue,
  label,
  onSelect,
  popoverProps,
  timePickerProps,
  value,
  disabled,
}: InputDateProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isActive = popoverProps.visible;
  const optionsCount = timePickerProps.options.length;
  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus();
    }
  }, [isActive]);

  const handleOpenPopover = (event?: MouseEvent) => {
    event?.preventDefault();
    if (!disabled) popoverProps.onRequestToOpen();
  };

  const renderOptionsEmptyState = () => (
    <EmptyStateContainer>
      <EmptyState
        descriptionText="There are no time slots available for this date."
        illustrationName="empty-state"
        titleText="Check your location"
      />
    </EmptyStateContainer>
  );
  return (
    <Box width="100%">
      <Popover
        offset={popoverProps.offset}
        onRequestToClose={popoverProps.onRequestToClose}
        renderContent={() => (
          <Box style={{ textAlign: 'center' }}>
            <PickUpSpreadWrapper>
              <Typography variant="paragraphSmall">
                Be ready to receive your Kyte +/- 15 min of the scheduled time
              </Typography>
            </PickUpSpreadWrapper>
            <Box padding="$large">
              <DateTimePicker
                datePickerProps={datePickerProps}
                onSelect={onSelect}
                selectedDate={value}
                timePickerProps={{
                  loading: timePickerProps.loading,
                  titleText: timePickerProps.titleText,
                  options: timePickerProps.options,
                  renderEmptyState: optionsCount
                    ? undefined
                    : renderOptionsEmptyState,
                }}
              />
            </Box>
          </Box>
        )}
        visible={popoverProps.visible}
        width="632px"
        zIndex={99}
      >
        <BasicInput
          id={label}
          label={label}
          // @ts-ignore
          onFocus={handleOpenPopover}
          readOnly
          ref={inputRef}
          value={formattedValue}
        />
      </Popover>
    </Box>
  );
};

const EmptyStateContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
  zoom: 0.8;
`;

const PickUpSpreadWrapper = styled.div`
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.base}px;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.base}px;
  background-color: ${({ theme }) => theme.colors.marinerLight};
`;

export default InputDate;

import dynamic from 'next/dynamic';

import Advantages from '@/components/cms/advantages';
import AdvantagesCarRental from '@/components/cms/advantages-car-rental/advantages-car-rental';
import AsFeaturedIn from '@/components/cms/as-featured-in';
import Banner from '@/components/cms/banner';
import DownloadCTA from '@/components/cms/download-cta/download-cta';
import BookDiscountedRideWithImageCta from '@/components/cms/book-discounted-ride-with-image-cta';
import BookDiscountedRideCta from '@/components/cms/book-discounted-ride-cta';
import Faq from '@/components/cms/faq';
import Fleet from '@/components/cms/fleet';
import HowItWorks from '@/components/cms/how-it-works';
import LinkPond from '@/components/cms/link-pond';
import LocalTrips from '@/components/cms/local-trips';
import RentalHero from '@/components/cms/rental-hero';
import Reviews from '@/components/cms/reviews';
import RichText from '@/components/cms/rich-text';
import SearchGeneral from '@/components/cms/search-general';
import SearchHero from '@/components/cms/search-hero';
import SectionHeader from '@/components/cms/section-header';
import ServiceAreas from '@/components/cms/service-areas';
import ServiceAreasList from '@/components/cms/service-areas-list';
import ServiceMap from '@/components/cms/service-map';
import Rating from '@/components/cms/shared-search-components/rating';
import Stepper from '@/components/cms/stepper';
import Video from '@/components/cms/video/video';
import ComparisonTable from '../comparison-table';
import IconProps from '../icon-props';
import ImageModule from '../image-module';
import VisionModule from '../vision-module';

const SubscriptionHero = dynamic(() =>
  import('@/components/cms/subscription-hero/subscription-hero'),
);

const ImageText = dynamic(() =>
  import('@/components/cms/image+text/image+text'),
);

const sectionComponents = {
  // sections
  'sections.advantages': Advantages,
  'sections.advantages-car-rental': AdvantagesCarRental,
  'sections.as-featured-in': AsFeaturedIn,
  'sections.banner': Banner,
  'sections.comparison-table': ComparisonTable,
  'sections.download-cta': DownloadCTA,
  'sections.book-discounted-ride-cta-with-image':
    BookDiscountedRideWithImageCta,
  'sections.book-discounted-ride-cta': BookDiscountedRideCta,
  'sections.faq': Faq,
  'sections.fleet': Fleet,
  'sections.how-it-works': HowItWorks,
  'sections.icon-props': IconProps,
  'sections.image-module': ImageModule,
  'sections.image-text': ImageText,
  'sections.link-pond': LinkPond,
  'sections.local-trips': LocalTrips,
  'sections.reviews': Reviews,
  'sections.rich-text': RichText,
  'sections.search-general': SearchGeneral,
  'sections.search-hero': SearchHero,
  'sections.service-area': ServiceAreas,
  'sections.service-areas-list': ServiceAreasList,
  'sections.service-map': ServiceMap,
  'sections.stepper': Stepper,
  'sections.subscription-hero': SubscriptionHero,
  'sections.rating': Rating,
  'sections.rental-hero': RentalHero,
  'sections.video': Video,
  'sections.vision-module': VisionModule,
  // elements
  'elements.header': SectionHeader,
};

export default sectionComponents;

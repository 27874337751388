import React from 'react';
import { Box, Hidden } from '@drivekyte/web-components';
import NextImage from '@/components/cms/image/image';
import { Banner as BannerType } from './types';

const DEFAULT_HEIGHT_MOBILE = 187;
const DEFAULT_HEIGHT_DESKTOP = 705;

const Banner = ({ data }: { data: BannerType }) => (
  <>
    <Hidden only={['sm', 'xs']}>
      <Box width="100%">
        <NextImage
          media={data?.image}
          height={data.maxImageHeightDesktop || DEFAULT_HEIGHT_DESKTOP}
          isBanner
        />
      </Box>
    </Hidden>
    <Hidden only={['md', 'lg', 'xl']}>
      <Box
        display="flex"
        marginBottom="base"
        justifyContent="center"
        marginHorizontal="base"
        height="100%"
      >
        <NextImage
          media={data?.image}
          height={data.maxImageHeightMobile || DEFAULT_HEIGHT_MOBILE}
          isBanner
        />
      </Box>
    </Hidden>
  </>
);

export default Banner;

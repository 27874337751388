import styled, { css } from 'styled-components';

const SearchContainerLarge = styled.div`
  ${({ theme }) => css`
    border: ${theme.borderWidths.base}px solid ${theme.borderColors.primary};
    border-radius: ${theme.borderRadius.large}px;
    padding: ${theme.spacings.small}px;
    box-shadow: ${theme.boxShadows.base};
    height: 420px;

    form > div {
      height: auto;
      flex-flow: column wrap;
      border: none;
      box-shadow: none;
    }
    form > div > div {
      box-shadow: ${theme.boxShadows.base};
      border: ${theme.borderWidths.base}px solid ${theme.borderColors.primary};
      &:first-child {
        border-radius: ${theme.borderRadius.base}px;
        margin-bottom: ${theme.spacings.xSmall}px;
      }
      &:nth-child(2) {
        border-radius: ${theme.borderRadius.base}px ${theme.borderRadius.base}px
          0 0;
        border-bottom: none;
        padding: ${theme.spacings.xxSmall};
      }
      &:nth-child(3) {
        display: none;
      }
      &:last-child {
        border-radius: 0 0 ${theme.borderRadius.base}px
          ${theme.borderRadius.base}px;
      }
    }
  `}
`;

export default SearchContainerLarge;

import { Box, Typography } from '@drivekyte/ui';
import Image from 'next/image';
import SearchContent from './components/search-content';

const SearchDesktop = ({
  headline,
  description,
  ctaText,
  backgroundImageSrc,
  backgroundImageAltText,
}) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    minHeight={640}
    flexWrap="wrap"
    position="relative"
    alignItems="center"
  >
    <Box
      width={420}
      minHeight={432}
      backgroundColor="$backgroundPrimary"
      borderRadius="$medium"
      zIndex={1}
      style={{ boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.20)' }}
      padding="$large"
      gap="$small"
    >
      <Typography variant="headingLarge">{headline}</Typography>
      <Typography variant="paragraphMedium">{description}</Typography>
      <SearchContent submitButtonLabel={ctaText} />
    </Box>
    <Box
      width="80%"
      height={560}
      position="absolute"
      borderRadius="$medium"
      right={0}
    >
      <Image
        fill
        src={backgroundImageSrc}
        alt={backgroundImageAltText}
        style={{ objectFit: 'cover', borderRadius: 'inherit' }}
        quality={100}
        priority
      />
    </Box>
  </Box>
);

export default SearchDesktop;

import React from 'react';
import { Box, Typography } from '@drivekyte/web-components';
import WPTypography from '@/components/typography';
import { SectionHeaderProps } from './types';

const SectionHeader = (props: SectionHeaderProps) => {
  const tipText = props?.data?.tipText;
  const successText = props?.data?.successText;
  const captionText = props?.data?.captionText;

  return (
    <Box>
      {tipText && (
        <Box marginBottom="xSmall">
          <Typography variant="label-2" color="tertiary">
            {tipText}
          </Typography>
        </Box>
      )}
      <Box marginBottom="xSmall">
        <WPTypography
          variant="h2"
          color="primary"
          fontFamily="Gilroy"
          fontSize="28"
          lineHeight={32}
          fontWeight={700}
        >
          {props?.data?.primaryText || ''}
        </WPTypography>
        {successText && (
          <WPTypography
            variant="h2"
            color="success"
            fontFamily="Gilroy"
            fontWeight={700}
            fontSize="28"
            lineHeight={32}
          >
            {successText}
          </WPTypography>
        )}
      </Box>
      {captionText && (
        <Box whiteSpace="pre">
          <WPTypography
            variant="span"
            color="quaternary"
            fontSize="13"
            lineHeight={16}
          >
            {captionText}
          </WPTypography>
        </Box>
      )}
    </Box>
  );
};

export default SectionHeader;

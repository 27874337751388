/* eslint-disable react/prop-types */

/* eslint react/forbid-prop-types: 0 */
import { Colors } from '@drivekyte/web-components';
import React from 'react';
import styled, { css } from 'styled-components';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'div'
  | 'span'
  | 'p'
  | 'a';
type FontFamily = 'Gilroy' | 'Hanken Grotesk';

export type TypographyProps = {
  children: React.ReactNode;
  color: Colors;
  ellipsis?: boolean;
  fontFamily?: FontFamily;
  fontSize: React.CSSProperties['fontSize'];
  fontWeight?: number;
  htmlTitle?: string;
  hoverColor?: Colors;
  lineHeight?: number;
  lineThrough?: boolean;
  testID?: string;
  dataConceptualExperiment?: string;
  textAlign?: React.CSSProperties['textAlign'];
  variant: TypographyVariant;
  fontStyle?: React.CSSProperties['fontStyle'];
};

const Typography = ({
  children,
  color,
  ellipsis,
  fontFamily = 'Hanken Grotesk',
  fontSize = 12,
  fontWeight,
  hoverColor,
  htmlTitle,
  lineHeight,
  lineThrough,
  testID,
  dataConceptualExperiment,
  textAlign = 'left',
  variant = 'span',
  fontStyle = 'normal',
}: TypographyProps) => (
  <Text
    $color={color}
    $ellipsis={ellipsis}
    $fontFamily={fontFamily}
    $fontSize={fontSize}
    $fontWeight={fontWeight}
    $hoverColor={hoverColor}
    $lineHeight={lineHeight}
    $lineThrough={lineThrough}
    $textAlign={textAlign}
    $fontStyle={fontStyle}
    as={variant}
    data-testid={testID}
    data-conceptual-experiment={dataConceptualExperiment}
    title={htmlTitle}
  >
    {children}
  </Text>
);

const Text = styled.div<{
  $color: Colors;
  $ellipsis?: boolean;
  $fontFamily: FontFamily;
  $fontSize: React.CSSProperties['fontSize'];
  $fontWeight?: number;
  $hoverColor?: Colors;
  $lineHeight?: number;
  $lineThrough?: boolean;
  $fontStyle?: string;
  $textAlign: React.CSSProperties['textAlign'];
}>`
  padding: 0;
  margin: 0;

  ${({
    $color,
    $fontFamily,
    $fontSize,
    $fontWeight,
    $lineHeight,
    $textAlign,
    $fontStyle,
    theme,
  }) => css`
    color: ${theme.colors[$color]};
    font-family: ${$fontFamily};
    font-size: ${$fontSize}px;
    font-weight: ${$fontWeight};
    line-height: ${$lineHeight}px;
    text-align: ${$textAlign};
    font-style: ${$fontStyle};
  `};

  ${({ theme, $color, $lineThrough }) =>
    $lineThrough &&
    css`
      text-decoration-color: ${theme.colors[$color]};
      text-decoration: line-through;
    `}

  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  ${({ $hoverColor, theme }) =>
    $hoverColor &&
    css`
      &:hover {
        color: ${theme.colors[$hoverColor]};
      }
    `}
`;

export default Typography;

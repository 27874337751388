import { MediaData } from '@/components/cms/types';
import getStrapiMediaUrl from '@/utils/strapi-cms/get-strapi-media-url';
import { Box } from '@drivekyte/web-components';
import Image from 'next/image';
import { CSSProperties } from 'react';

type NextImageType = {
  media: MediaData;
  width?: number;
  height?: number;
  isBanner?: boolean;
  borderRadius?: string;
  objectFit?: CSSProperties['objectFit'];
  priority?: boolean;
  quality?: number;
  fill?: boolean;
};

const NextImage = ({
  media,
  isBanner,
  borderRadius = '0',
  objectFit = 'none',
  fill = false,
  priority = false,
  ...props
}: NextImageType) => {
  const url = media?.data?.attributes?.url;
  const alternativeText = media?.data?.attributes?.alternativeText;
  const width = media?.data?.attributes?.width || 702;
  const height = media?.data?.attributes?.height || 500;

  const loader = ({ src }: { src: string }) => {
    return getStrapiMediaUrl(src);
  };

  // The image has a fixed width and height
  if (props?.width && props?.height) {
    return (
      <Image
        loader={loader}
        src={url}
        alt={alternativeText || ''}
        style={{ borderRadius, objectFit }}
        priority={priority}
        {...props}
      />
    );
  }

  if (isBanner) {
    return (
      <Box width="100%" height={`${props.height}px`} overflow="hidden">
        <Image
          loader={loader}
          fill
          style={{ borderRadius, objectFit: 'cover' }}
          src={url}
          alt={alternativeText || ''}
          priority={priority}
        />
      </Box>
    );
  }

  // The image is responsive
  if (props.width && props.height) {
    return (
      <Image
        loader={loader}
        width={width}
        height={height}
        style={{ borderRadius, objectFit: 'contain' }}
        src={url}
        alt={alternativeText || ''}
        priority={priority}
      />
    );
  }
  if (fill) {
    return (
      <Box position="relative" width="100%" height="100%">
        <Image
          loader={loader}
          src={url}
          alt={alternativeText || ''}
          fill
          style={{ borderRadius, objectFit: 'cover' }}
          priority={priority}
        />
      </Box>
    );
  }

  return (
    <Image
      loader={loader}
      style={{
        borderRadius,
        objectFit: 'cover',
        maxWidth: '100%',
        height: 'auto',
      }}
      width={width}
      height={height}
      sizes="(max-width: 768px) 100vw, 50vw"
      src={url}
      priority={priority}
      alt={alternativeText || ''}
    />
  );
};

export default NextImage;

import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete, AutoCompleteItem, Box } from '@drivekyte/web-components';
import { useRouter } from 'next/router';
import SearchEmptyState from '@/components/search-empty-state';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import usePlacesSearch, { Place } from '@/hooks/use-places-search-wrapper';
import { ServiceAreaLabel } from '@/hooks/use-service-area-labels';
import AutoCompleteInput from './components/auto-complete-input/auto-complete-input';
import ListFooter from './components/list-footer';
import mapAutocompleteData from './utils/mapAutocompleteData';
import useOutsideServiceAreaExperiment from '@/hooks/experiments/use-outside-service-area-experiment/use-outside-service-area-experiment';
import useSendSearchEvent from '@/hooks/use-send-search-event';
import useVagueToLotRedirect from '@/hooks/use-vague-to-lot-redirect';

export type InputAddressProps = {
  active?: boolean;
  initialInputValue: string;
  label: string;
  onSelect: (address: Place) => void;
  placeholder: string;
  testID?: string;
  loadingTestID?: string;
  isEditTrip?: boolean;
  onInputFocus?: () => void;
};

const InputAddress = ({
  active = false,
  initialInputValue,
  label,
  onSelect,
  placeholder,
  testID,
  loadingTestID,
  isEditTrip,
  onInputFocus,
}: InputAddressProps) => {
  const sendSearchEvent = useSendSearchEvent();
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  const [selectedServiceArea, setSelectedServiceArea] = useState<string | null>(
    null,
  );
  const [outsideServiceArea, setOutsideServiceArea] = useState<boolean>(false);
  // TODO: Remove this when the experiment has concluded: https://app.posthog.com/experiments/4986
  const { isOutsideServiceAreaExperimentTreatment } =
    useOutsideServiceAreaExperiment();
  useVagueToLotRedirect();

  const [hasFocus, setHasFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const isSubscription = router.asPath.includes('subscription');
  const isNotInitialValue = inputValue !== initialInputValue;
  const placesSearchValue =
    isNotInitialValue && !outsideServiceArea ? inputValue : '';
  const { isLoading, isIdle, data } = usePlacesSearch(placesSearchValue);
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  useEffect(() => {
    if (active) {
      inputRef.current?.focus();
      setHasFocus(true);
    }
  }, [active]);

  useEffect(() => {
    //Force value update if the address value is changed by the parent component
    setInputValue(initialInputValue);
  }, [initialInputValue]);

  const hasData = !isLoading && data && data.length > 0;

  useEffect(() => {
    if (hasData && !!selectedServiceArea) {
      setSelectedServiceArea(null);
      const serviceAreaPlace = data.find(
        (place) => place.place_type === 'city',
      );
      if (serviceAreaPlace) onSelect(serviceAreaPlace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData, selectedServiceArea]);

  const handleSelect = (autoCompleteItem: AutoCompleteItem<Place> | null) => {
    if (autoCompleteItem?.extraData)
      sendSearchEvent(autoCompleteItem?.extraData);

    trackEvent(AnalyticsEvents.Search.SuggestionClicked, {
      search_input: inputValue,
      suggestion_text: autoCompleteItem?.value,
      address: autoCompleteItem?.extraData?.formatted_address,
      element_id: testID,
      service_area: autoCompleteItem?.extraData?.service_area_code,
      is_covered_location: autoCompleteItem?.extraData?.is_covered_location,
      place_type: autoCompleteItem?.extraData?.place_type,
      is_edit_trip: isEditTrip,
      initial_input: initialInputValue,
      place_lat: autoCompleteItem?.extraData?.lat,
      place_lng: autoCompleteItem?.extraData?.lng,
      is_subscription: isSubscription,
      outside_service_area_exp: isOutsideServiceAreaExperimentTreatment,
    });

    if (autoCompleteItem?.extraData?.is_covered_location === false) {
      setInputValue(autoCompleteItem.value);
      setOutsideServiceArea(true);
      setHasFocus(true);
      return;
    }

    if (autoCompleteItem?.value && autoCompleteItem?.extraData) {
      setInputValue(autoCompleteItem.value);
      onSelect(autoCompleteItem.extraData);
    }
    setHasFocus(false);
  };

  const handleInputChange = (newInputValue: string) => {
    if (newInputValue !== inputValue) {
      if (outsideServiceArea) {
        setOutsideServiceArea(false);
      }
      if (selectedServiceArea) {
        setSelectedServiceArea(null);
      }
      setInputValue(newInputValue);
    }
  };

  const onFocusHandler = () => {
    if (!hasFocus) {
      if (onInputFocus) onInputFocus();
      setHasFocus(true);
    }
  };

  const onBlurHandler = () => {
    if (hasFocus) setHasFocus(false);
  };

  const onOuterPressHandler = () => {
    if (hasFocus) setHasFocus(false);
  };

  const handleSelectedServiceArea = (item: ServiceAreaLabel) => {
    setOutsideServiceArea(false);
    setSelectedServiceArea(item.code);
    trackEvent(AnalyticsEvents.Search.CityTagSelected, {
      serviceArea: item.code,
      testID,
      searchValue: inputValue,
      is_subscription: isSubscription,
      is_edit_trip: isEditTrip,
      outside_service_area_exp: isOutsideServiceAreaExperimentTreatment,
    });
    setInputValue(item.cityAddress);
    setHasFocus(false);
  };

  const autoCompleteData = mapAutocompleteData(
    data?.filter(
      (place) =>
        place.is_covered_location || isOutsideServiceAreaExperimentTreatment,
    ),
  );

  const showAutoComplete =
    hasFocus && isNotInitialValue && (!isIdle || outsideServiceArea);

  return (
    <AutoComplete<Place>
      open={showAutoComplete}
      inputValue={inputValue}
      loading={isLoading}
      onInputValueChange={handleInputChange}
      onOuterPress={onOuterPressHandler}
      testID={testID}
      loadingTestID={loadingTestID}
      data={autoCompleteData}
      onSelect={handleSelect}
      customEmptyState={
        <Box padding="base">
          <SearchEmptyState
            onServiceAreaItemPress={handleSelectedServiceArea}
            hideServiceAreas={isEditTrip}
            outsideServiceArea={outsideServiceArea}
          />
        </Box>
      }
      renderInput={(inputOptions) => (
        <AutoCompleteInput
          label={label}
          placeholder={placeholder}
          inputRef={inputRef}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          options={inputOptions}
          hasFocus={hasFocus}
        />
      )}
      listFooterComponent={
        isLoading || !data?.length ? undefined : () => <ListFooter />
      }
    />
  );
};

export default InputAddress;

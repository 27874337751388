import { Box, Typography, useMedia } from '@drivekyte/ui';
import Link from '../link';
import { LinkPondItemProps } from './link-pond.type';

export const LinkPondItem = ({
  text,
  url,
  Icon,
  newTab,
  backgroundColor,
  textColor,
}: LinkPondItemProps) => {
  const media = useMedia();

  const getLinkStyle = () => {
    if (media.gtLg)
      return { width: '100%', maxWidth: '23.5%', textDecoration: 'none' };
    if (media.gtMd)
      return { width: '100%', maxWidth: '31.3%', textDecoration: 'none' };
    if (media.gtSm)
      return { width: '100%', maxWidth: '47.4%', textDecoration: 'none' };
    if (media.gtXs)
      return { width: '100%', maxWidth: '30%', textDecoration: 'none' };

    return { width: '100%', maxWidth: '100%', textDecoration: 'none' };
  };

  return (
    <Link data={{ url, text, newTab }} style={getLinkStyle()}>
      <Box
        backgroundColor={`$${backgroundColor?.value}` ?? '$green100'}
        paddingVertical="$medium"
        paddingHorizontal="$small"
        width="100%"
        borderRadius="$small"
        gap="$tiny"
        flexDirection="row"
        alignItems="center"
      >
        {/* @ts-ignore */}
        <Icon
          size={32}
          color={textColor ? `$${textColor.value}` : '$green600'}
        />
        <Typography
          variant="headingMedium"
          color={textColor ? `$${textColor?.value}` : '$green600'}
          overflow="hidden"
          textOverflow="ellipsis"
          textWrap="nowrap"
        >
          {text}
        </Typography>
      </Box>
    </Link>
  );
};

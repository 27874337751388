import React from 'react';
import { Box } from '@drivekyte/web-components';
import WPTypography from '@/components/typography';
import { SplitParagraphProps } from './types';

const SplitParagraph = ({
  children,
  lineHeight,
  color,
  fontSize,
  fontWeight,
}: SplitParagraphProps) => (
  <>
    {children.split('\n').map((paragraph: string) => (
      <Box key={paragraph.slice(0, 10)} marginBottom="small">
        <WPTypography
          variant="p"
          fontSize={fontSize || '16'}
          fontWeight={fontWeight}
          color={color || 'primary'}
          lineHeight={lineHeight || 20}
        >
          {paragraph}
        </WPTypography>
      </Box>
    ))}
  </>
);

export default SplitParagraph;

import { useMutation } from 'react-query';
import request, { ApiResponse, RequestError } from '@/utils/request';

const postEvent = async ({ eventName, params }: any) => {
  const { data: response } = await request<ApiResponse<ResponseType>>({
    method: 'POST',
    url: 'v2/users/me/event_log',
    data: {
      event_type: eventName,
      event_data: params,
    },
  });

  return response.data;
};

const useEventLog = <RequestType>() => {
  return useMutation<ResponseType, RequestError, RequestType>(postEvent);
};

export default useEventLog;

import React, { useEffect } from 'react';
import {
  Box,
  Icon,
  LoadingIndicator,
  Typography,
} from '@drivekyte/web-components';
import AnalyticsEvents from '@/config/analytics-events';
import {
  LiveDataComponent,
  LiveDataSocialProofParams,
} from '@/types/live-data-social-proof';
import useAnalyticsWithSource from '@/hooks/use-analytics-with-source';

export type LiveDataNoticeProps = {
  location: `${string}-${LiveDataComponent}`;
  isLoading: boolean;
  message?: string;
  center?: boolean;
  liveDataParams: LiveDataSocialProofParams | null;
  liveDataComponentId?: string;
};

const LiveDataNotice = ({
  center,
  message,
  location,
  isLoading,
  liveDataParams,
  liveDataComponentId,
}: LiveDataNoticeProps) => {
  const { trackEvent } = useAnalyticsWithSource();

  if (isLoading) {
    return (
      <Box
        flex="1"
        display="flex"
        marginTop="small"
        flexDirection="row"
        alignItems="centers"
        alignContent="center"
        justifyContent="flex-end"
      >
        <LoadingIndicator />
      </Box>
    );
  }

  if (!message) return <></>;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    trackEvent(
      AnalyticsEvents.LiveDataSocialProofEvents.LiveDataSocialProofDisplayed,
      {
        client_component_id: liveDataParams?.client_component_id,
        component_id: liveDataComponentId,
        service_area_uuid: liveDataParams?.service_area_uuid,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      flex="1"
      id={location}
      display="flex"
      flexDirection="row"
      alignItems="centers"
      alignContent="center"
      justifyContent={center ? 'center' : 'flex-end'}
    >
      <Icon color="info" name="24-hurry" />
      <Box marginLeft="xTiny">
        <Typography color="info" variant="label-1">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default LiveDataNotice;

import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
} from '@drivekyte/web-components';
import Banner from '@/components/cms/banner';
import SearchStacked from '@/components/cms/shared-search-components/search-stacked';
import { SearchContainerResponsive } from '@/components/cms/shared-search-components/styled-components';
import { SearchGeneralProps } from './types';

const SearchGeneral = (props: SearchGeneralProps) => {
  const data = props?.data;
  const banner = data?.banner;

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        paddingBottom="xxxxLarge"
      >
        <Grid container direction="row">
          {banner && (
            <Grid item md={5} xs={12} justifyContent="center">
              <Banner data={banner} />
            </Grid>
          )}
          <Grid item md={7} xs={12}>
            <SearchContainerResponsive>
              <SearchStacked
                data={{
                  headlinePrimary: data?.headlinePrimary || '',
                  headlineSuccess: data?.headlineSuccess || '',
                  descriptionDesktop: data?.descriptionDesktop || '',
                  descriptionMobile: data?.descriptionMobile || '',
                  submitLabel: data?.searchSubmitLabel || 'Submit',
                  asFeaturedIn: data?.asFeaturedIn,
                }}
              />
            </SearchContainerResponsive>
          </Grid>
        </Grid>
      </Box>
      <Hidden smUp>
        <Container>
          <Box marginBottom="xxLarge">
            <Divider />
          </Box>
        </Container>
      </Hidden>
    </>
  );
};

export default SearchGeneral;

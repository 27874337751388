/* allow overriding hero title on landing pages by providing query params "?pt={base64_encoded_string}" */
const updateHeroTitleFromUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const pt = urlParams.get('pt');

  if (pt !== null && pt.length !== 0) {
    const titleElement = document.querySelector(
      '[data-conceptual-experiment="title"]',
    );
    if (titleElement) {
      titleElement.textContent = atob(pt);
    }
  }
};

export default updateHeroTitleFromUrlParams;

import React, { useEffect } from 'react';
import { Box, IconButton } from '@drivekyte/web-components';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import SearchContent from '@/components/cms/shared-search-components/search-content';
import { SearchBoxBigProps } from './types';

const SearchBoxBig = ({
  buttonPosition = 'inline',
  submitButtonLabel = 'Submit',
  onChangeVisibility,
  onClose,
}: SearchBoxBigProps) => {
  const { ref, inView } = useInView({ initialInView: true });

  useEffect(() => {
    if (onChangeVisibility) {
      onChangeVisibility(inView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Box ref={ref}>
      <Box marginTop="medium">
        {onClose ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <IconButton name="24-close-big" onPress={onClose} />
          </Box>
        ) : null}
        <SearchContent
          submitButtonLabel={submitButtonLabel}
          buttonPosition={buttonPosition}
        />
      </Box>
    </Box>
  );
};

export default SearchBoxBig;

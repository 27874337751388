import { useQuery } from 'react-query';
import { ServiceArea } from '@/types/service-area';
import request, { ApiResponse, RequestError } from '@/utils/request';
import {
  CACHE_DURATION_MS,
  STALE_DURATION_MS,
} from '@/constants/requests-configuration';

const PRIORITIES = ['SF', 'LA', 'NYC', 'BK', 'JC', 'MIA'];

const fetchServiceAreas = async () => {
  const { data: response } = await request<ApiResponse<Array<ServiceArea>>>({
    url: 'v2/service_areas',
  });

  return orderServiceAreasBasedOnPriority(response.data);
};

const useServiceAreas = () =>
  useQuery<ServiceArea[], RequestError>('serviceAreas', fetchServiceAreas, {
    staleTime: STALE_DURATION_MS.large,
    cacheTime: CACHE_DURATION_MS.large,
    keepPreviousData: true,
  });

const orderServiceAreasBasedOnPriority = (serviceAreas: Array<ServiceArea>) => {
  return serviceAreas.sort(
    (a: ServiceArea, b: ServiceArea) =>
      getPriorities(a.abbreviation) - getPriorities(b.abbreviation),
  );
};

const getPriorities = (serviceAreaCode: string) => {
  return PRIORITIES.indexOf(serviceAreaCode) > -1
    ? PRIORITIES.indexOf(serviceAreaCode)
    : 6;
};

export default useServiceAreas;

import styled from 'styled-components';

type ShadowTextProps = {
  enabled?: boolean;
};

const ShadowText = styled.span<ShadowTextProps>`
  ${({ theme, enabled }) => (enabled ? theme.colors.primary : 'transparent')};
`;

export default ShadowText;

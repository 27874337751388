import React from 'react';
import {
  Box,
  Dialog,
  LoadingIndicator,
  Typography,
} from '@drivekyte/web-components';
import { useMedia } from '@drivekyte/ui';
import { SearchContainerLarge } from '@/components/cms/shared-search-components/styled-components';
import SearchBar from '@/components/search-bar';
import ServiceAreaMap from '@/components/service-area-details/components/service-area-map';
import useServiceAreaDetails from '@/hooks/use-service-area-details';
import WhereAndWhen from '@/components/where-and-when';
import { ServiceAreaModalProps } from './types';

const ServiceAreaModal = ({
  modalTitle,
  submitButtonLabel,
  serviceAreaId,
  onRequestToClose,
}: ServiceAreaModalProps) => {
  const media = useMedia();
  const isMobile = media.xs;

  const { data, isLoading } = useServiceAreaDetails(serviceAreaId, true);

  return (
    <Dialog onRequestToClose={onRequestToClose} visible size="large">
      <Box
        display="flex"
        width="100%"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box
          marginBottom="tiny"
          marginRight={isMobile ? 'none' : 'small'}
          width={isMobile ? '100%' : '60%'}
        >
          {isMobile ? (
            <>
              <Box marginBottom="xSmall">
                <Typography center variant="headline-3" color="primary">
                  {modalTitle}
                </Typography>
              </Box>
              <WhereAndWhen />
            </>
          ) : (
            <SearchContainerLarge>
              <Box marginBottom="xSmall">
                <Typography center variant="headline-3" color="primary">
                  {modalTitle}
                </Typography>
              </Box>
              <SearchBar buttonPosition="bottom" cta={submitButtonLabel} />
            </SearchContainerLarge>
          )}
        </Box>
        <Box width={isMobile ? '100%' : '40%'} height="100%" minHeight="464px">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            data && (
              <ServiceAreaMap
                geojson={data.geojson}
                exceptions={[]}
                onExceptionPress={() => {}}
                center={data.center}
              />
            )
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ServiceAreaModal;

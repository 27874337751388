import { Currency } from '@drivekyte/currency-utils';
import create from 'zustand';

type CurrencyStore = {
  currentCurrency: Currency;
  updateCurrentCurrency: (newCurrency: Currency) => void;
};

const useCurrentCurrency = create<CurrencyStore>((set) => ({
  currentCurrency: 'USD',
  updateCurrentCurrency: (newCurrency) => set({ currentCurrency: newCurrency }),
}));

export default useCurrentCurrency;

import { Box, Switch, Typography } from '@drivekyte/ui';
import useTranslation from '@/hooks/use-translation';

type SameLocationTogglerProps = {
  value: boolean;
  onChange: (nextToggleValue: boolean) => void;
  disabled?: boolean;
  isHomepage?: boolean;
};

const SameLocationToggler = ({
  value,
  onChange,
  disabled,
  isHomepage,
}: SameLocationTogglerProps) => {
  const { t } = useTranslation('components.same-location-toggler');
  return (
    <Box display="flex" flexDirection="row" gap="$small" alignItems="center">
      <Switch
        value={value}
        onChange={onChange}
        size="small"
        disabled={disabled}
      />
      <Typography
        variant="paragraphSmall"
        color={isHomepage ? '$contentInversePrimary' : 'unset'}
      >
        {t('updated-title')}
      </Typography>
    </Box>
  );
};

export default SameLocationToggler;

import React, { useMemo, useState } from 'react';
import { Box, Divider, Grid, Hidden } from '@drivekyte/web-components';
import { useMedia } from '@drivekyte/ui';
import ServiceAreaModal from '@/components/cms/service-area-modal';
import SectionHeader from '@/components/growth/section-header';
import ServiceAreaItem from './components/service-area-item';
import ServiceAreaPlaceholder from './components/service-area-placeholder';
import { PLACEHOLDER } from './constants';
import { ServiceAreasProps } from './types';

const ServiceAreas = (props: ServiceAreasProps) => {
  const header = props.data?.header;
  const allowBookingModal = props.data?.allowBookingModal || false;

  const [showModal, setShowModal] = useState<number | null>(null);

  const media = useMedia();

  const serviceAreas = useMemo(() => {
    const data = props.data?.serviceAreas?.data;
    if (!data) return [];

    const rest = data.length % 4;
    const placeholders = Array(rest)
      .fill(0)
      .map((_, index) => ({
        id: 1000 + index,
        attributes: {
          uuid: PLACEHOLDER,
          name: PLACEHOLDER,
          fullName: PLACEHOLDER,
          image: null,
        },
      }));

    return [...data, ...placeholders];
  }, [props]);

  return (
    <>
      <SectionHeader
        tipText={header?.tipText || ''}
        primaryText={header?.primaryText}
        successText={header?.successText}
        captionText={header?.captionText}
        captionBoldText={header?.captionBoldText}
        marginBottom="medium"
      />
      <Grid container>
        <Grid item md={8} sm={12}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap={media.sm ? 'nowrap' : 'wrap'}
            overflowX={media.sm ? 'scroll' : undefined}
          >
            {serviceAreas.map((serviceArea, index) => (
              <Grid item key={serviceArea.id}>
                {serviceArea.attributes.name === PLACEHOLDER ? (
                  <ServiceAreaPlaceholder />
                ) : (
                  <>
                    <ServiceAreaItem
                      imageUrl={
                        serviceArea.attributes?.image?.data?.attributes?.url ||
                        'https://kyte-assets.s3.us-west-1.amazonaws.com/service-areas/san-francisco.png'
                      }
                      name={serviceArea.attributes.fullName}
                      allowBookingModal={allowBookingModal}
                      onClick={() => setShowModal(index)}
                    />

                    {allowBookingModal && showModal !== null && (
                      <ServiceAreaModal
                        modalTitle={serviceAreas[showModal].attributes.fullName}
                        submitButtonLabel="Submit"
                        serviceAreaId={serviceAreas[showModal].attributes.uuid}
                        onRequestToClose={() => setShowModal(null)}
                      />
                    )}
                  </>
                )}
              </Grid>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Hidden smUp>
        <Divider />
      </Hidden>
    </>
  );
};

export default ServiceAreas;

import { Box, Typography, useMedia } from '@drivekyte/ui';
import Image from 'next/image';
import { IconPropData } from './icon-props.type';

export const IconProp = ({
  header,
  description,
  backgroundColor,
  image,
}: IconPropData) => {
  const media = useMedia();

  return (
    <Box
      $gtSm={{
        gap: '$small',
        flexDirection: 'row',
        width: '33.33%',
      }}
      gap="$tiny"
      flex={1}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box
        width={72}
        height={72}
        $gtSm={{
          width: 96,
          height: 96,
          borderRadius: 48,
        }}
        backgroundColor={
          backgroundColor ? `$${backgroundColor.value}` : '$green50'
        }
        borderRadius={36}
        overflow="hidden"
        alignItems="center"
        justifyContent="center"
      >
        {!!image && image.imageData.data && (
          <Image
            width={media.gtSm ? image.width : image.width * 0.78}
            height={media.gtSm ? image.height : image.height * 0.78}
            style={{
              objectFit: 'contain',
              maxWidth: media.gtSm ? 70 : 50,
              maxHeight: media.gtSm ? 70 : 50,
            }}
            src={image.imageData.data.attributes.url}
            alt={image.imageData.data.attributes.name}
          />
        )}
      </Box>
      <Box
        flex={1}
        flexWrap="wrap"
        $gtSm={{ alignItems: 'flex-start' }}
        alignItems="center"
      >
        {!!header && <Typography variant="headingSmall">{header}</Typography>}
        <Typography
          variant="paragraphMedium"
          flexWrap="wrap"
          textAlign="center"
          $gtSm={{ textAlign: 'left' }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

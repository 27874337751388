import * as Icons from '@drivekyte/icons';
import { Box, Typography, useMedia } from '@drivekyte/ui';
import { StepperItemProps } from './stepper.type';

const Step = ({ title, description, isRight, linkIcon }: StepperItemProps) => {
  const media = useMedia();
  // @ts-ignore
  // eslint-disable-next-line import/namespace
  const Icon = Icons[linkIcon];
  return (
    <Box
      $gtSm={{
        flexDirection: isRight ? 'row-reverse' : 'row',
      }}
      gap="$medium"
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      {media.gtSm && (
        <>
          <Box maxWidth="44%" width="100%" />
          <Box
            width={48}
            height={48}
            backgroundColor="$green50"
            alignItems="center"
            justifyContent="center"
            borderRadius={24}
          >
            {/* @ts-ignore */}
            <Icon size={32} color={'$green600'} />
          </Box>
        </>
      )}
      <Box width="100%" $gtSm={{ maxWidth: '44%' }}>
        <Typography
          variant="headingSmall"
          $gtSm={{
            textAlign: isRight ? 'right' : 'left',
          }}
          textAlign="center"
        >
          {title}
        </Typography>
        <Typography
          variant="paragraphMedium"
          $gtSm={{
            textAlign: isRight ? 'right' : 'left',
          }}
          textAlign="center"
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default Step;

/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

type InputTextProps = {
  id: string;
  onBlur?: () => void;
  onChangeText?: (nextValue: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  value: string;
};

const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      id,
      onBlur,
      onChangeText,
      onFocus,
      placeholder,
      readOnly,
      value,
      ...props
    }: InputTextProps,
    ref,
  ) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChangeText) onChangeText(event.currentTarget.value);
    };

    return (
      <Input
        autoComplete="off"
        id={id}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={ref}
        value={value}
        {...props}
      />
    );
  },
);

const Input = styled.input`
  border: none;
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.colors.quinary};
  }

  ${({ theme }) => css`
    background: ${theme.shapeFills.transparent};
    color: ${theme.colors.primary};
    font-family: ${theme.typographies['label-3'].fontFamily};
    font-size: ${theme.typographies['label-3'].fontSize}px;
    font-weight: ${theme.typographies['label-3'].fontWeight};
    line-height: ${theme.typographies['label-3'].lineHeight}px;
  `}
`;

export default InputText;

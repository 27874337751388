import React from 'react';
import { Icon } from '@drivekyte/web-components';
import styled from 'styled-components';
import { Exception, Location } from '@/types/service-area';

type ExceptionMarkerProps = {
  center: Location;
  exception: Exception;
  onPress: (exception: Exception) => void;
};

const ExceptionMarker = ({
  center,
  exception,
  onPress,
}: ExceptionMarkerProps) => {
  const handlePress = () => onPress(exception);

  return (
    <Container lat={center.lat} lng={center.lng} onClick={handlePress}>
      <Icon name="24-info-big" color="primary" />
    </Container>
  );
};

const Container = styled.div<{ lat: number; lng: number }>`
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(4px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  animation: float 1.5s ease-in-out infinite;
`;

export default ExceptionMarker;

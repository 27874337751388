import mapAutocompleteData from '@/components/search-bar/components/input-address/utils/mapAutocompleteData';
import SearchEmptyState from '@/components/search-empty-state';
import useOutsideServiceAreaExperiment from '@/hooks/experiments/use-outside-service-area-experiment/use-outside-service-area-experiment';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import { ServiceAreaLabel } from '@/hooks/use-service-area-labels';
import useSearchContext from '@/pages/home-page/components/hero-section/components/hero-desktop/components/search/context/use-search-context';
import { AutoComplete, AutoCompleteItem, BasicInput, Box } from '@drivekyte/ui';
import usePlacesSearch, { Place } from '@/hooks/use-places-search-wrapper';
import { useEffect, useRef, useState } from 'react';
import useVagueToLotRedirect from '@/hooks/use-vague-to-lot-redirect';
import useAddressInputState from '@/hooks/use-address-input-state';

export type InputAddressProps = {
  id: string;
  initialInputValue: string;
  label: string;
  onSelect: (address: Place) => void;
  placeholder: string;
  testID?: string;
  loadingTestID?: string;
  isEditTrip?: boolean;
  active?: boolean;
};

const InputAddress = ({
  id,
  label,
  initialInputValue,
  placeholder,
  onSelect,
  testID,
  active,
}: InputAddressProps) => {
  const { addressInputValue, setAdressInputValue } = useAddressInputState(
    (state) => state[id],
  );

  const [selectedServiceArea, setSelectedServiceArea] = useState<string | null>(
    null,
  );

  const [outsideServiceArea, setOutsideServiceArea] = useState<boolean>(false);
  // TODO: Remove this when the experiment has concluded: https://app.posthog.com/experiments/4986
  const { isOutsideServiceAreaExperimentTreatment } =
    useOutsideServiceAreaExperiment();

  const isNotInitialValue = addressInputValue !== initialInputValue;
  const placesSearchValue =
    isNotInitialValue && !outsideServiceArea ? addressInputValue : '';
  const { isLoading, isIdle, data } = usePlacesSearch(placesSearchValue);
  useVagueToLotRedirect();

  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  useEffect(() => {
    if (active) {
      inputRef.current?.focus();
    }
  }, [active]);

  useEffect(() => {
    //Force value update if the address value is changed by the parent component
    setAdressInputValue(initialInputValue);
  }, [initialInputValue]);

  const hasData = !isLoading && data && data.length > 0;

  useEffect(() => {
    if (hasData && !!selectedServiceArea) {
      setSelectedServiceArea(null);
      const serviceAreaPlace = data.find(
        (place) => place.place_type === 'city',
      );
      if (serviceAreaPlace) onSelect(serviceAreaPlace);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData, selectedServiceArea]);

  const handleSelect = (autoCompleteItem: AutoCompleteItem<Place> | null) => {
    trackEvent(AnalyticsEvents.Search.SuggestionClicked, {
      search_input: addressInputValue,
      suggestion_text: autoCompleteItem?.value,
      address: autoCompleteItem?.extraData?.formatted_address,
      element_id: testID,
      service_area: autoCompleteItem?.extraData?.service_area_code,
      is_covered_location: autoCompleteItem?.extraData?.is_covered_location,
      place_type: autoCompleteItem?.extraData?.place_type,
      initial_input: initialInputValue,
      place_lat: autoCompleteItem?.extraData?.lat,
      place_lng: autoCompleteItem?.extraData?.lng,
      outside_service_area_exp: isOutsideServiceAreaExperimentTreatment,
    });
    if (autoCompleteItem?.extraData?.is_covered_location === false) {
      setAdressInputValue(autoCompleteItem.value);
      setOutsideServiceArea(true);
      return;
    }
    if (autoCompleteItem?.value && autoCompleteItem?.extraData) {
      setAdressInputValue(autoCompleteItem.value);
      onSelect(autoCompleteItem.extraData);
    }

    // close the dropdown once an item is selected
    searchContext.onInputBlur();
  };

  const handleSelectedServiceArea = (item: ServiceAreaLabel) => {
    setOutsideServiceArea(false);
    setSelectedServiceArea(item.code);
    trackEvent(AnalyticsEvents.Search.CityTagSelected, {
      serviceArea: item.code,
      testID,
      searchValue: addressInputValue,
      is_edit_trip: false,
      outside_service_area_exp: isOutsideServiceAreaExperimentTreatment,
    });
    setAdressInputValue(item.cityAddress);
  };

  const handleInputChange = (newInputValue: string) => {
    if (newInputValue !== addressInputValue) {
      if (outsideServiceArea) {
        setOutsideServiceArea(false);
      }
      if (selectedServiceArea) {
        setSelectedServiceArea(null);
      }
      setAdressInputValue(newInputValue);
    }
  };

  const autoCompleteData = mapAutocompleteData(
    data?.filter(
      (place) =>
        place.is_covered_location || isOutsideServiceAreaExperimentTreatment,
    ) ?? [],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const searchContext = useSearchContext();

  const isFocused = searchContext.inputFocused === id;
  const showAutoComplete =
    isFocused && isNotInitialValue && (!isIdle || outsideServiceArea);

  const handleOnFocus = () => searchContext.onInputFocus(id);
  const handleOnBlur = () => {
    if (!showAutoComplete) searchContext.onInputBlur();
  };
  const handleOuterPress = () => searchContext.onInputBlur();

  return (
    <AutoComplete<Place>
      open={showAutoComplete}
      inputValue={addressInputValue}
      loading={isLoading}
      onInputValueChange={handleInputChange}
      onOuterPress={handleOuterPress}
      testID={testID}
      data={autoCompleteData}
      onSelect={handleSelect}
      customEmptyState={
        <Box padding="base">
          <SearchEmptyState
            onServiceAreaItemPress={handleSelectedServiceArea}
            hideServiceAreas={false}
            outsideServiceArea={outsideServiceArea}
          />
        </Box>
      }
      renderInput={(inputOptions) => (
        <BasicInput
          // @ts-ignore
          ref={inputRef}
          {...inputOptions}
          placeholder={placeholder}
          label={label}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
      )}
      containerStyle={{
        borderRadius: '$small',
        width: 354,
        backgroundColor: '$backgroundPrimary',
        shadowColor: 'rgba(0, 0, 0, 0.14)',
        shadowOffset: { width: 0, height: 1 },
        shadowRadius: 8,
        left: 0,
        padding: showAutoComplete ? '$medium' : 0,
        top: 60,
      }}
    />
  );
};

export default InputAddress;

export const cityData: { [key: string]: any } = {
  atlanta: {
    address: '3800 East Main Street, College Park, GA 30337',
    timezone: 'America/New_York',
  },
  boston: {
    address: '700 Atlantic Ave, Boston, MA 02110',
    timezone: 'America/New_York',
  },
  brooklyn: {
    address: '34 N 7th St, Brooklyn, NY 11249',
    timezone: 'America/New_York',
  },
  chicago: {
    address: '3355 W Belmont Ave, Chicago, IL 60618',
    timezone: 'America/Chicago',
  },
  denver: {
    address: '1701 Wynkoop St, Denver, CO 80202',
    timezone: 'America/Denver',
  },
  ftLauderdale: {
    address: ' 230 SE 1st St, Fort Lauderdale, FL',
    timezone: 'America/New_York',
  },
  jerseyCity: {
    address: '1 Raymond Plaza West, Newark, NJ 07102',
    timezone: 'America/New_York',
  },
  losAngeles: {
    address: '1960 E Grand Ave, El Segundo, CA 90245',
    timezone: 'America/Los_Angeles',
  },
  miami: {
    address: '3861 NW 21st St, Miami, FL 33142',
    timezone: 'America/New_York',
  },
  newYorkCity: {
    address: '165 West 65th Street, New York, NY 10023',
    timezone: 'America/New_York',
  },
  philadelphia: {
    address: '1 Arrivals Rd, Philadelphia, PA 19153',
    timezone: 'America/New_York',
  },
  portland: {
    address: '9605 NE Sandy Blvd, Portland, OR 97220',
    timezone: 'America/Los_Angeles',
  },
  sanFrancisco: {
    address: '500 John Daly Boulevard, Daly City, CA 94014',
    timezone: 'America/Los_Angeles',
  },
  seattle: {
    address: '2918 S 176th St, SeaTac, WA 98188',
    timezone: 'America/Los_Angeles',
  },
  washingtonDc: {
    address: '1750 S Clark St, Arlington, VA 22202',
    timezone: 'America/New_York',
  },
};

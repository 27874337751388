import React from 'react';
import useSearch from '@/hooks/use-search';
import useCalendarValidDates from '@/hooks/use-calendar-valid-dates';
import { Box, Typography, Switch, Button } from '@drivekyte/ui';
import InputAddress from '../input-address';
import StartDate from '../start-date';
import EndDate from '../end-date';
import { createTimePickerOptions } from '@drivekyte/web-components';
import useTranslation from '@/hooks/use-translation';
import useVagueToLotRedirect from '@/hooks/use-vague-to-lot-redirect';

type SearchContentProps = {
  submitButtonLabel?: string;
};

const SearchContent = ({ submitButtonLabel = 'Go' }: SearchContentProps) => {
  const { t } = useTranslation('components.same-location-toggler');
  const {
    bookingCart,
    inputState,
    isFetchingQuotes,
    isFormValid,
    isLoadingTimeSlots,
    onChange,
    onChangeAddressToggle,
    onChangeEndAddress,
    onChangeEndDate,
    onChangeStartAddress,
    onChangeStartDate,
    onSubmitWithQuotes,
    setInputState,
    timeSlotsData,
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    timeSlotsStartError,
    timeSlotsEndError,
  } = useSearch();
  const { enableAddressToggler } = useVagueToLotRedirect();

  const { isSameDeliveryReturnLocations } = bookingCart;

  useCalendarValidDates({
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    startTz: timeSlotsData?.start.tz,
    endTz: timeSlotsData?.end.tz,
    selectedDates: bookingCart,
    onChange,
  });

  return (
    <Box gap="$small">
      <Box gap="$tiny">
        {isSameDeliveryReturnLocations ? (
          <InputAddress
            label="Delivery location"
            placeholder="Type an address and city..."
            onSelect={onChangeStartAddress}
            initialInputValue={
              bookingCart.startPlace || bookingCart.startAddress
            }
            id="start-address"
            active={inputState.showStartAddress}
          />
        ) : (
          <>
            <InputAddress
              label="Delivery location"
              placeholder="Type an address and city..."
              onSelect={onChangeStartAddress}
              initialInputValue={
                bookingCart.startPlace || bookingCart.startAddress
              }
              id="start-address"
              active={inputState.showStartAddress}
            />
            <InputAddress
              label="Return location"
              placeholder="Type an address and city..."
              initialInputValue={bookingCart.endPlace || bookingCart.endAddress}
              onSelect={onChangeEndAddress}
              id="end-address"
              active={inputState.showEndAddress}
            />
          </>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          gap="$tiny"
          width="100%"
        >
          <Box flex={1}>
            <StartDate
              active={!timeSlotsStartError && inputState.showStartDate}
              label="From"
              onChange={onChangeStartDate}
              onChangeInputState={setInputState}
              startDate={bookingCart.startDate}
              timePickerProps={{
                loading: isLoadingTimeSlots,
                options:
                  timeSlotsData?.start.timeSlots ||
                  createTimePickerOptions(
                    7,
                    22,
                    bookingCart.startDate,
                    '2-digit',
                  ),
                titleText: 'Available delivery times',
              }}
              disabled={timeSlotsStartError}
              emptyTitleText="No times available"
              hasNoOptions={timeSlotsData?.start.timeSlots.length === 0}
            />
          </Box>
          <Box flex={1}>
            <EndDate
              active={!timeSlotsEndError && inputState.showEndDate}
              endDate={bookingCart.endDate}
              label="To"
              onChange={onChangeEndDate}
              onChangeInputState={setInputState}
              startDate={bookingCart.startDate}
              timePickerProps={{
                loading: isLoadingTimeSlots,
                options:
                  timeSlotsData?.end.timeSlots ||
                  createTimePickerOptions(
                    7,
                    22,
                    bookingCart.endDate,
                    '2-digit',
                  ),
                titleText: 'Available return times',
              }}
              disabled={timeSlotsEndError}
              emptyTitleText="No times available"
              hasNoOptions={timeSlotsData?.end.timeSlots.length === 0}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" gap="$small" alignItems="center">
        <Switch
          value={isSameDeliveryReturnLocations}
          onChange={onChangeAddressToggle}
          size="small"
          disabled={!enableAddressToggler}
        />
        <Typography variant="paragraphSmall">{t('updated-title')}</Typography>
      </Box>
      <Button
        variant="primary"
        loading={isFetchingQuotes || isLoadingTimeSlots}
        disabled={!isFormValid}
        onPress={() => onSubmitWithQuotes()}
      >
        {submitButtonLabel}
      </Button>
    </Box>
  );
};

export default SearchContent;

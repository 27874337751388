import HandoverOutpostConfirmationDialog from '@/components/handover-outpost-confirmation';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';
import usePartnerReferral from '@/hooks/use-partner-referral';
import usePush from '@/hooks/use-push';
import useSearch from '@/hooks/use-search';
import useTranslation from '@/hooks/use-translation';
import { QueryBookingCart } from '@/types/booking-cart';
import { Box, Button } from '@drivekyte/ui';
import SameLocationToggler from './components/same-location-toggler';
import When from './components/when';
import Where from './components/where';
import useRentalSearchHistory from '@/hooks/use-rental-search-history';
import useVagueToLotRedirect from '@/hooks/use-vague-to-lot-redirect';
import { useRouter } from 'next/router';

type SearchBoxProps = {
  calendarTestID?: string;
  onClose?: () => void;
  ctaText?: string;
  startAddressLabel?: string;
  startAddressPlaceholder?: string;
  startAddressModalTitle?: string;
  returnAddressLabel?: string;
  returnAddressPlaceholder?: string;
  returnAddressModalTitle?: string;
};

const SearchForm = ({
  calendarTestID,
  onClose,
  ctaText,
  startAddressLabel,
  startAddressPlaceholder,
  startAddressModalTitle,
  returnAddressLabel,
  returnAddressPlaceholder,
  returnAddressModalTitle,
}: SearchBoxProps) => {
  const router = useRouter();
  const currentPath = router.asPath;
  const isHomePage = currentPath.split('?')[0] === '/';

  const push = usePush();
  const { hasToRefineAddress } = usePartnerReferral();
  const {
    bookingCart,
    onChange: onChangeSearch,
    onChangeAddressToggle,
    handoverOutpostState,
    acceptHandoverSuggestion,
    rejectHandoverSuggestions,
    isFetchingQuotes,
    isFormValid,
    isLoadingTimeSlots,
    onSubmitWithQuotes,
  } = useSearch();
  const { t } = useTranslation('pages.welcome-page.search');
  const { saveSearchHistory } = useRentalSearchHistory();
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();

  const {
    startPlace,
    startAddress,
    endPlace,
    endAddress,
    startDate,
    endDate,
    isSameDeliveryReturnLocations,
  } = bookingCart;

  const { enableAddressToggler } = useVagueToLotRedirect();

  const startHandoverAddress = startPlace || startAddress;
  const endHandoverAddress = endPlace || endAddress;

  const handleChangeAddress = (nextAddressQuery: Partial<QueryBookingCart>) => {
    push({ ...nextAddressQuery, refined_address: true });
    if (onClose) onClose();
  };

  const handleChangeDates = (
    nextEndDate: string,
    nextStartDate: string,
    nextEndTimeZone?: string,
    nextStartTimeZone?: string,
    disableCloseSearchBox?: boolean,
  ) => {
    trackEvent(AnalyticsEvents.BookingFlow.SearchDateSubmitted, {
      date: nextStartDate,
      is_start_leg: true,
      is_end_leg: false,
    });
    trackEvent(AnalyticsEvents.BookingFlow.SearchDateSubmitted, {
      date: nextEndDate,
      is_start_leg: false,
      is_end_leg: true,
    });
    onChangeSearch({
      end_date: nextEndDate,
      end_timezone: nextEndTimeZone,
      start_date: nextStartDate,
      start_timezone: nextStartTimeZone,
    });
    if (onClose && !disableCloseSearchBox) onClose();
  };

  const handleOnPress = () => {
    saveSearchHistory();
    onSubmitWithQuotes();
  };

  return (
    <Box gap="$small">
      <Box display="flex" flexDirection="column" gap="$tiny">
        <Where
          startAddress={hasToRefineAddress ? undefined : startHandoverAddress}
          endAddress={hasToRefineAddress ? undefined : endHandoverAddress}
          isSameDeliveryReturnLocations={isSameDeliveryReturnLocations}
          onChangeAddress={handleChangeAddress}
          startAddressLabel={startAddressLabel}
          startAddressPlaceholder={startAddressPlaceholder}
          startAddressModalTitle={startAddressModalTitle}
          returnAddressLabel={returnAddressLabel}
          returnAddressPlaceholder={returnAddressPlaceholder}
          returnAddressModalTitle={returnAddressModalTitle}
        />
        <When
          calendarTestID={calendarTestID}
          startDate={startDate}
          startAddress={startAddress}
          endDate={endDate}
          endAddress={endAddress}
          onChangeDates={handleChangeDates}
        />
      </Box>
      <SameLocationToggler
        value={!!isSameDeliveryReturnLocations}
        onChange={onChangeAddressToggle}
        disabled={!enableAddressToggler}
        isHomepage={isHomePage}
      />
      <Button
        variant="primary"
        loading={isFetchingQuotes || isLoadingTimeSlots}
        disabled={!isFormValid}
        onPress={handleOnPress}
      >
        {ctaText ?? t('submit-search-label')}
      </Button>
      {handoverOutpostState.isConfirmationEnabled && (
        <HandoverOutpostConfirmationDialog
          visible
          handoverOutpostState={handoverOutpostState}
          onSubmit={acceptHandoverSuggestion}
          onClose={rejectHandoverSuggestions}
        />
      )}
    </Box>
  );
};

export default SearchForm;

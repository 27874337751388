import React from 'react';
import { useTheme } from '@drivekyte/web-components';
import GoogleMap from 'google-map-react';
import styled, { css } from 'styled-components';
import { Exception, Geojson, Location } from '@/types/service-area';
import ExceptionMarker from '../exception-marker';

type ServiceAreaDetailsProps = {
  center: Location;
  exceptions: Exception[];
  geojson: Geojson;
  onExceptionPress: (exception: Exception) => void;
  zoom?: number;
};

const ServiceAreaDetails = ({
  center,
  exceptions,
  geojson,
  onExceptionPress,
  zoom,
}: ServiceAreaDetailsProps) => {
  const [theme] = useTheme();

  return (
    <Container>
      <GoogleMap
        bootstrapURLKeys={{
          key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string,
        }}
        defaultCenter={{ lat: center.lat, lng: center.lng }}
        defaultZoom={zoom ?? 11}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          map.data.addGeoJson(geojson);

          map.data.setStyle({
            fillColor: theme.shapeFills.secondary,
            strokeWeight: theme.borderWidths.base,
          });
        }}
      >
        {exceptions.map((exception) => (
          <ExceptionMarker
            center={exception.center}
            exception={exception}
            key={exception.uuid}
            onPress={onExceptionPress}
          />
        ))}
      </GoogleMap>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  > div {
    min-height: 455px;
  }

  > div > div {
    ${({ theme }) => css`
      border-radius: ${theme.borderRadius.large}px;
    `}
  }
`;

export default ServiceAreaDetails;

import * as Icons from '@drivekyte/icons';
import { Box, Typography } from '@drivekyte/ui';
import { LinkPondItem } from './link-pond-item';
import { LinkPondProps } from './link-pond.type';

const LinkPond = ({ data }: LinkPondProps) => {
  // @ts-ignore
  // eslint-disable-next-line import/namespace
  const Icon = Icons[data.linkIcon];

  return (
    <Box>
      {data.header && (
        <Typography
          variant="headingXLarge"
          marginBottom="$huge"
          alignSelf="center"
          textAlign="center"
        >
          {data.header}
        </Typography>
      )}
      <Box
        flexDirection="row"
        columnGap="$medium"
        rowGap="$xSmall"
        width="100%"
        flexWrap="wrap"
      >
        {data.links.map((item) => (
          <LinkPondItem
            id={item.id}
            Icon={Icon}
            backgroundColor={data.linkBackgroundColor}
            textColor={data.linkTextColor}
            text={item.text}
            url={item.url}
            newTab={item.newTab}
            key={item.id.toString()}
          />
        ))}
      </Box>
    </Box>
  );
};

export default LinkPond;

import CarGallery from '@/components/cms/fleet/components/car-gallery';
import { VehicleAttributes } from '@/components/cms/fleet/types';
import { Box, Typography } from '@drivekyte/web-components';

type CarTileProps = {
  vehicle: VehicleAttributes;
};

const CarTile = ({ vehicle }: CarTileProps) => {
  const { title, description, exampleModels, image } = vehicle;

  return (
    <>
      <CarGallery
        title={title}
        images={image?.data || []}
        onPress={() => {}}
        maxHeight={223}
        maxWidth={387}
      />
      <Box margin="xTiny">
        <Typography variant="headline-2" color="primary">
          {title}
        </Typography>
      </Box>
      <Box marginLeft="xTiny" marginBottom="small">
        <Typography variant="body-2" color="primary" fontWeight={500}>
          {exampleModels}
        </Typography>
        <Typography variant="body-2" color="quaternary">
          {description}
        </Typography>
      </Box>
    </>
  );
};

export default CarTile;

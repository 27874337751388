import { Box, Typography } from '@drivekyte/ui';
import Image from 'next/image';
import React from 'react';
import { MediaData } from '../types';

type ImageData = {
  id: number;
  title: string;
  description: string;
  imageData: MediaData;
  objectFit: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
};

type ImageModuleProps = {
  data: {
    header: string;
    images: ImageData[];
    aspectRatio: '1:1' | '3:2' | '16:9';
  };
};

const getAspectRatioStyle = (aspectRatio: string) => {
  switch (aspectRatio) {
    case '1:1':
      return '100%';
    case '3:2':
      return '66.67%';
    case '16:9':
      return '56.25%';
    default:
      return '56.25%'; // Default to 16:9
  }
};

const ImageModule: React.FC<ImageModuleProps> = ({ data }) => {
  const { header, images } = data;
  const imageCount = images.length;
  const aspectRatio = data.aspectRatio.replace('a', '');

  return (
    <Box>
      <Typography
        variant="headingXLarge"
        marginBottom="$xLarge"
        $gtMd={{ marginBottom: '$huge' }}
        textAlign="center"
      >
        {header}
      </Typography>
      <Box
        flexDirection="column"
        $gtXs={{
          flexDirection: imageCount > 2 ? 'column' : 'row',
        }}
        $gtMd={{ flexDirection: 'row' }}
        gap="$large"
      >
        {images.map((image, index) => (
          <Box
            key={index}
            flexDirection="column"
            gap="$small"
            width="100%"
            $gtXs={{
              width: imageCount === 2 ? 340 : '100%',
            }}
            $gtMd={{
              width: `calc(100% / ${imageCount})`,
            }}
          >
            <Box
              position="relative"
              width="100%"
              paddingBottom={getAspectRatioStyle(aspectRatio)}
              $gtXs={{ height: 288 }}
              $gtSm={{ height: imageCount === 2 ? 227 : 400 }}
              $gtMd={{
                height: imageCount > 1 ? (imageCount === 2 ? 310 : 168) : 540,
              }}
              $gtLg={{
                height: imageCount > 1 ? (imageCount === 2 ? 390 : 213) : 675,
              }}
            >
              <Image
                src={image.imageData.data.attributes.url}
                alt={image.title}
                style={{
                  objectFit: image.objectFit ?? 'cover',
                  borderRadius: 16,
                }}
                fill
              />
            </Box>
            <Box>
              <Typography variant="headingMedium">{image.title}</Typography>
              <Typography variant="paragraphMedium">
                {image.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImageModule;

import { Faq as FaqType } from '@/components/cms/types';
import SectionHeader from '@/components/growth/section-header';
import Markdown from '@/components/markdown';
import Accordion from '@/pages/car-subscription/components/faq-section/components/accordion';
import ShadedContainer from '@/pages/car-subscription/components/shaded-container/shaded-container';
import { ComponentDataContext } from '@/utils/strapi-cms/component-data-context';
import { Box, Container, Typography } from '@drivekyte/web-components';
import { useContext } from 'react';
import styled from 'styled-components';

enum FaqTemplate {
  CUSTOM = 'custom',
  GENERAL = 'general',
}

const Faq = ({ data }: { data: FaqType }) => {
  const { generalFaq } = useContext(ComponentDataContext);

  const mapFaqTemplateToData = {
    [FaqTemplate.GENERAL]: generalFaq,
    [FaqTemplate.CUSTOM]: data,
  };

  const faqData = mapFaqTemplateToData[data.template] || data;

  return (
    <ShadedContainer padding="small">
      <Container>
        <Box paddingVertical="xLarge">
          <SectionHeader
            primaryText={faqData?.primaryHeadline}
            tipText={faqData?.secondaryHeadline}
            noMargin
          />
          <Dl>
            {faqData?.questions.map(({ title, description, id }) => (
              <Accordion title={title} key={id} ariaId={id}>
                <Content>
                  <Typography color="quaternary" variant="body-1">
                    <Markdown>{description}</Markdown>
                  </Typography>
                </Content>
              </Accordion>
            ))}
          </Dl>
        </Box>
      </Container>
    </ShadedContainer>
  );
};

const Content = styled.div`
  white-space: pre-line;
`;

const Dl = styled.dl`
  margin-top: 1.5rem;

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    /* 1 */
    border-width: 0;
    /* 2 */
    border-style: solid;
    /* 2 */
    border-color: #e5e7eb;
    /* 2 */
  }

  ::before,
  ::after {
    --tw-content: '';
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    /* 1 */
    border-width: 0;
    /* 2 */
    border-style: solid;
    /* 2 */
    border-color: #e5e7eb;
    /* 2 */
  }

  ::before,
  ::after {
    --tw-content: '';
  }
  > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }

  > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
    border-style: solid;
  }

  > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity));
  }

  button,
  [role='button'] {
    cursor: pointer;
  }
`;

export default Faq;

import styled from 'styled-components';

const SearchContainerMobile = styled.div`
  padding-left: ${({ theme }) => theme.spacings.small}px;
  padding-right: ${({ theme }) => theme.spacings.small}px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export default SearchContainerMobile;

import React from 'react';
import { Box } from '@drivekyte/web-components';
import styled, { css } from 'styled-components';

type AutoCompleteInputProps = {
  label: string;
  placeholder: string;
  hasFocus: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  onFocus: () => void;
  onBlur: () => void;
  options: React.HTMLProps<HTMLInputElement>;
};

const AutoCompleteInput = ({
  options,
  label,
  placeholder,
  hasFocus,
  inputRef,
  onBlur,
  onFocus,
}: AutoCompleteInputProps) => (
  <Box
    borderColor={hasFocus ? 'tertiary' : 'transparent'}
    borderRadius="base"
    borderStyle="solid"
    borderWidth="large"
    cursor="pointer"
    display="flex"
    flex={1}
    flexDirection="column"
    justifyContent="space-around"
    onPress={onFocus}
    padding="small"
  >
    <Label id={options['aria-labelledby']} htmlFor={options.id}>
      {label}
    </Label>
    <Input
      aria-activedescendant={options['aria-activedescendant']}
      aria-autocomplete={options['aria-autocomplete']}
      aria-controls={options['aria-controls']}
      aria-labelledby={options['aria-labelledby']}
      autoComplete="off"
      id={options.id}
      onChange={options.onChange}
      onKeyDown={options.onKeyDown}
      placeholder={placeholder}
      ref={inputRef}
      value={options.value}
      onBlur={(event) => {
        if (options.onBlur) options.onBlur(event);
        onBlur();
      }}
      onFocus={onFocus}
    />
  </Box>
);

const Input = styled.input`
  background: transparent;
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ::placeholder {
    color: ${({ theme }) => theme.colors.quinary};
  }

  ${({ theme }) => css`
    background: ${theme.shapeFills.transparent};
    color: ${theme.colors.primary};
    font-family: ${theme.typographies['label-3'].fontFamily};
    font-size: ${theme.typographies['label-3'].fontSize}px;
    font-weight: ${theme.typographies['label-3'].fontWeight};
    line-height: ${theme.typographies['label-3'].lineHeight}px;
  `}
`;

const Label = styled.label`
  font-family: Hanken Grotesk;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #046720;
  display: inline;
`;

export default AutoCompleteInput;

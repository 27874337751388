import WPTypography from '@/components/typography';
import { Box } from '@drivekyte/web-components';
import Image from 'next/image';

/*
 * Example of markdown image string with all options:
 * ![Car Image Alt Text {768x432}{caption: Kyte vehicle.}{alignPhoto: center}{alignCaption: center}{borderRadius: 3%}{verticalMargin: large}](https://kyte-cms-media-dev.s3.amazonaws.com/image.png)
 *
 * Minimal markdown image string:
 * ![](https://kyte-cms-media-dev.s3.amazonaws.com/image_123.png)
 */

const alignments: { [key: string]: any } = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const getImageDimensions = (metastring: string) => {
  // match 1-4 digits before x
  const metaWidth = metastring?.match(/(\d{1,4})x/);
  // match 1-4 digits after x
  const metaHeight = metastring?.match(/x(\d{1,4})/);

  const width = metaWidth ? metaWidth[1] : '768';
  const height = metaHeight ? metaHeight[1] : '432';

  return { width: Number(width), height: Number(height) };
};

const ImageComponent = ({ node }: { node: any }) => {
  if (node?.tagName === 'img') {
    const metastring = node?.properties?.alt;
    const alt = metastring?.replace(/ *\{[^)]*\} */g, '') || 'Image';
    const { height } = getImageDimensions(metastring);

    // vertical margin (space above and below photo)
    const verticalMarginValue = metastring
      ?.match(/{verticalMargin: (.*?)}/)
      ?.pop();
    const verticalMargin = verticalMarginValue ? verticalMarginValue : 'large';

    // border radius of photo
    const borderRadiusValue = metastring?.match(/{borderRadius: (.*?)}/)?.pop();
    const borderRadius = borderRadiusValue ? borderRadiusValue : '3%';

    // photo alignment
    const alignPhotoValue = metastring?.match(/{alignPhoto: (.*?)}/)?.pop();
    const alignPhoto = alignPhotoValue ? alignments[alignPhotoValue] : 'center';

    // caption alignment
    const alignCaptionValue = metastring?.match(/{alignCaption: (.*?)}/)?.pop();
    const alignCaption = alignCaptionValue
      ? alignments[alignCaptionValue]
      : 'center';

    // caption
    const hasCaption = metastring?.toLowerCase().includes('{caption:');
    const caption = metastring?.match(/{caption: (.*?)}/)?.pop();

    return (
      <Box
        display="flex"
        justifyContent={alignPhoto}
        marginVertical={verticalMargin}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={alignCaption}
          position="relative"
          width="100%"
          style={{ height }}
        >
          <Image
            src={node?.properties?.src}
            alt={alt}
            style={{ borderRadius, objectFit: 'contain' }}
            fill
          />
          {hasCaption && (
            <Box marginTop="small">
              <WPTypography
                variant="span"
                color="primary"
                fontSize={16}
                lineHeight={24}
                fontWeight={100}
              >
                {caption}
              </WPTypography>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
};

export default ImageComponent;

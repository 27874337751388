import AnalyticsEvents from '@/config/analytics-events';
import useAnalyticsWithSource from '@/hooks/use-analytics-with-source';
import usePush from '@/hooks/use-push';
import PosthogFlags, { BookDiscountModuleGroups } from '@/types/posthog-flags';
import { Box, Button, Typography, useMedia } from '@drivekyte/ui';
import Image from 'next/image';
import { usePostHog } from 'posthog-js/react';
import { BookNowWithImageCTAProps } from './types';

const BookDiscountedRideWithImageCta = ({ data }: BookNowWithImageCTAProps) => {
  const media = useMedia();
  const push = usePush();

  const posthog = usePostHog();
  const { trackEvent } = useAnalyticsWithSource();

  const isExperimentEnabled =
    posthog.getFeatureFlag(PosthogFlags.BookDiscountModule) ===
    BookDiscountModuleGroups.enabled;

  const applyCoupon = () => {
    if (!data.discountCode) {
      return;
    }

    trackEvent(AnalyticsEvents.MerchandisingStrategy.BookDiscountCTAPressed);
    push({ code: data.discountCode }, { scroll: true });
  };

  if (!isExperimentEnabled) return;

  return (
    <Box $sm={{ padding: '$small' }} paddingVertical="$medium">
      <Box
        padding="$xLarge"
        borderRadius={40}
        backgroundColor="#064916"
        alignContent="space-between"
        justifyContent="space-between"
        $gtSm={{ flexDirection: 'row' }}
        $sm={{
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        maxWidth={1280}
        alignSelf="center"
        width="100%"
      >
        <Box
          flex={1}
          marginRight="$medium"
          flexDirection="column"
          justifyContent="center"
          $sm={{ marginRight: '$none' }}
        >
          <Typography
            textAlign="left"
            marginBottom="$small"
            variant="headingLarge"
            color="$contentInversePrimary"
            $sm={{ textAlign: 'center', marginBottom: '$none' }}
          >
            {data.headline}
          </Typography>
          {!media.gtSm && (
            <Box
              alignContent="center"
              justifyContent="center"
              marginVertical="$medium"
            >
              <Typography textAlign="center" color="$contentInversePrimary">
                {data.bookingInformation}
              </Typography>
            </Box>
          )}
          <Box
            alignItems="center"
            flexDirection="row"
            alignContent="center"
            justifyContent="flex-start"
            $sm={{ justifyContent: 'center' }}
          >
            <Button
              size="medium"
              variant="tertiary"
              onPress={applyCoupon}
              $sm={{ flex: 1, size: 'medium' }}
              cursor="pointer"
            >
              {data.cta}
            </Button>
            {media.gtSm && (
              <Typography marginLeft="$small" color="$contentInversePrimary">
                {data.bookingInformation}
              </Typography>
            )}
          </Box>
        </Box>
        {media.gtSm && (
          <Image
            src={data.image.data.attributes.url}
            width={458}
            height={172}
            alt="Discounted Ride Car Image"
            style={{ objectFit: 'cover' }}
          />
        )}
      </Box>
    </Box>
  );
};

export default BookDiscountedRideWithImageCta;

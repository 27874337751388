import { Link as LinkType } from '@/components/cms/types';
import Link from 'next/link';
import React from 'react';

type CmsLinkProps = {
  data: LinkType;
  children?: React.ReactNode;
  author?: string;
  style?: React.CSSProperties;
};

const CmsLink = ({ data, children, author, style }: CmsLinkProps) => {
  const isInternalLink = data.url.startsWith('/');

  const content = children ? children : data.text;

  let URLSrc = '';

  if (data.url && data.url.startsWith('https://www')) {
    // e.g. https://www.google.com -> google
    URLSrc = data.url.split('.')[1];
  } else {
    // e.g. https://google.com -> google.com
    URLSrc = data.url.split('//')[1];
  }

  // For internal links, use the Next.js Link component
  if (isInternalLink) {
    return (
      <Link href={data.url} style={style}>
        <a>{content}</a>
      </Link>
    );
  }

  // Plain <a> tags for external links
  if (data.newTab) {
    return (
      <a
        href={data.url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`${URLSrc}-review-link-by-${author}`}
        style={style}
      >
        {content}
      </a>
    );
  }

  return (
    <a href={data.url} target="_self" style={style}>
      {content}
    </a>
  );
};

export default CmsLink;

import React from 'react';
import { Box } from '@drivekyte/web-components';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import styled from 'styled-components';

const CodeComponent = ({ children }: CodeProps) => (
  <Box marginVertical="small">
    <Code>{children}</Code>
  </Box>
);

const Code = styled.code`
  display: block;
  font-family: monospace, Courier New, sans-serif;
  font-size: 14px;
  line-height: 1.75;
  color: #e0e0e0;
  background-color: #1f1f1f;
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacings.base}px;
  overflow-y: scroll;
`;

export default CodeComponent;

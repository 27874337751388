import { Place } from '@drivekyte/use-places-search';
import useEventLog from '../use-event-log';

const useSendSearchEvent = () => {
  const logEventMutation = useEventLog();

  const sendSearchEvent = (address: Place) => {
    logEventMutation.mutate({
      eventName: 'address-selected',
      params: {
        lat: address?.lat,
        lng: address?.lng,
        display_text: address?.display_text,
        formatted_address: address?.formatted_address,
        is_covered_location: address?.is_covered_location,
        place_type: address?.place_type,
        name: address?.name,
        primary_text: address?.primary_text,
        secondary: address?.secondary_text,
        service_area_uuid:
          address?.service_area_uuid === 'None'
            ? null
            : address?.service_area_uuid,
      },
    });
  };

  return sendSearchEvent;
};

export default useSendSearchEvent;

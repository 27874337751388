import { Meta, NavBarData } from '@/components/cms/types';
import {
  NavbarVariantColorEnum,
  NavbarVariantStateEnum,
} from '@/constants/navbar';

export const getNavBarData = (navigationBarData?: NavBarData) => {
  if (navigationBarData) {
    return navigationBarData;
  }
  return {
    color: NavbarVariantColorEnum.LIGHT,
    variant: NavbarVariantStateEnum.DEFAULT,
    fixedSearchOnScroll: false,
  };
};

export const getMetaTagsFromMetaData = (metaData: Meta) => {
  if (!metaData) {
    return {
      title: 'Rental Cars, Delivered On Demand',
      description: 'Kyte delivers rental cars straight to your door.',
    };
  }
  return {
    title: metaData.metaTitle,
    description: metaData.metaDescription,
  };
};

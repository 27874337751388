import { Box } from '@drivekyte/ui';
import { IconProp } from './icon-prop';
import { IconPropData } from './icon-props.type';

type IconPropsProps = {
  data: {
    IconPropItem: IconPropData[];
  };
};

const IconProps = ({ data }: IconPropsProps) => {
  return (
    <Box
      $gtSm={{
        gap: '$medium',
        flexDirection: 'row',
      }}
      gap="$xLarge"
      flex={1}
      display="flex"
    >
      {data.IconPropItem.map((item) => (
        <IconProp key={item.id} {...item} />
      ))}
    </Box>
  );
};

export default IconProps;

import React from 'react';
import { useMedia } from '@drivekyte/ui';
import { Box, Container, Grid } from '@drivekyte/web-components';
import SectionHeader from '@/components/growth/section-header';
import CarTile from './components/car-tile';
import { FleetProps } from './types';

const Fleet = (props: FleetProps) => {
  const data = props?.data;
  const media = useMedia();

  const vehicleClasses = data?.vehicleClasses?.data?.map(
    (item) => item?.attributes,
  );

  return (
    <Box marginBottom="xxLarge" minHeight={media.xs ? '1076px' : '314px'}>
      <Container>
        <Box marginLeft="xTiny" marginBottom="small">
          <SectionHeader
            tipText={data?.tipText}
            primaryText={data?.title}
            noMargin
          />
        </Box>
        <Grid container direction="row" spacing={4}>
          {(vehicleClasses || []).map((vehicleClass) => (
            <Grid item md={4} sm={6} xs={12} key={vehicleClass.title}>
              <CarTile vehicle={vehicleClass} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Fleet;

import WPTypography from '@/components/typography';
import { Typography } from '@drivekyte/ui';
import { Box, ThemeSpacings } from '@drivekyte/web-components';

type SectionHeaderProps = {
  tipText?: string;
  primaryText: string;
  successText?: string;
  captionText?: string;
  captionBoldText?: string;
  captionBoldComponent?: () => JSX.Element;
  noMargin?: boolean;
  marginBottom?: keyof ThemeSpacings;
};

const SectionHeader = ({
  tipText,
  primaryText,
  successText,
  captionBoldText,
  captionBoldComponent,
  captionText,
  noMargin = false,
  marginBottom = 'xxxxLarge',
}: SectionHeaderProps) => (
  <Box marginBottom={noMargin ? undefined : marginBottom}>
    {tipText && (
      <Box marginBottom="xSmall">
        <Typography variant="labelSmall" color="$backgroundPositiveHover">
          {tipText}
        </Typography>
      </Box>
    )}
    <Box marginBottom="xSmall">
      {primaryText && (
        <WPTypography
          variant="h2"
          color="primary"
          fontFamily="Gilroy"
          fontSize="28"
          lineHeight={32}
          fontWeight={700}
        >
          {primaryText}
        </WPTypography>
      )}
      {successText && (
        <WPTypography
          variant="h2"
          color="success"
          fontFamily="Gilroy"
          fontWeight={700}
          fontSize="28"
          lineHeight={32}
        >
          {successText}
        </WPTypography>
      )}
    </Box>
    {captionText && (
      <WPTypography
        variant="span"
        color="quaternary"
        fontSize="13"
        lineHeight={16}
      >
        {captionText}
      </WPTypography>
    )}
    {captionBoldText && (
      <Box whiteSpace="pre">
        <WPTypography
          variant="span"
          color="quaternary"
          fontWeight={500}
          fontSize="13"
          lineHeight={16}
        >
          {captionBoldText}
        </WPTypography>
      </Box>
    )}
    {!!captionBoldComponent && captionBoldComponent()}
  </Box>
);

export default SectionHeader;

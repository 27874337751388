import React from 'react';
import { Box, Switch, Typography } from '@drivekyte/web-components';

type SameLocationTogglerProps = {
  value: boolean;
  onChange: (nextValue: boolean) => void;
};

const SameLocationToggler = ({ value, onChange }: SameLocationTogglerProps) => (
  <Box
    display="flex"
    flexDirection="row"
    paddingHorizontal="xSmall"
    paddingVertical="tiny"
    justifyContent="space-between"
    alignItems="center"
  >
    <Typography color="primary" variant="label-3">
      Same delivery & return locations
    </Typography>
    <Switch value={value} onChange={onChange} />
  </Box>
);

export default SameLocationToggler;

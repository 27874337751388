import { useState, useEffect } from 'react';
import { Box, BasicInput } from '@drivekyte/ui';
import useTranslation from '@/hooks/use-translation';
import { formatDateTime } from '@/utils/date-formatter';
import EnhancedCalendar from '../../../enhanced-calendar';

type WhenProps = {
  endDate: Date;
  endAddress: string;
  startDate: Date;
  startAddress: string;
  calendarTestID?: string;
  onChangeDates: (
    nextEndDate: string,
    nextStartDate: string,
    nextEndTimeZone?: string,
    nextStartTimeZone?: string,
  ) => void;
};

const When = ({
  endDate,
  endAddress,
  startDate,
  startAddress,
  calendarTestID,
  onChangeDates,
}: WhenProps) => {
  const { t } = useTranslation('pages.landing-page.search-box.when');
  const [calendarVisibility, setCalendarVisibility] = useState(false);
  const [tempDates, setTempDates] = useState({
    startDate,
    endDate,
  });

  // force update the dates if the parent component changes the dates
  useEffect(() => {
    setTempDates({
      startDate,
      endDate,
    });
  }, [startDate, endDate]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        gap="$tiny"
        width="100%"
        onPress={() => setCalendarVisibility(true)}
      >
        <Box flex={1}>
          <BasicInput
            label={t('from')}
            value={formatDateTime(tempDates.startDate) ?? ''}
          />
        </Box>
        <Box flex={1}>
          <BasicInput
            label={t('to')}
            value={formatDateTime(tempDates.endDate) ?? ''}
          />
        </Box>
      </Box>
      <EnhancedCalendar
        testID={calendarTestID}
        setDates={setTempDates}
        onClose={() => setCalendarVisibility(false)}
        visible={calendarVisibility}
        onSubmit={onChangeDates}
        endDate={tempDates.endDate}
        endAddress={endAddress}
        startDate={tempDates.startDate}
        startAddress={startAddress}
      />
    </>
  );
};

export default When;

import { useMedia } from '@drivekyte/ui';
import updateHeroTitleFromUrlParams from '@/utils/update-hero-title-from-url-params';
import { Box } from '@drivekyte/web-components';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { SearchHeroProps } from './types';

const SearchDesktop = dynamic(() => import('./components/search-desktop'));

const SearchMobile = dynamic(() => import('./components/search-mobile'));

const SearchHero = (props: SearchHeroProps) => {
  const data = props?.data;
  const backgroundImageData = data?.backgroundImage?.data?.attributes;

  const media = useMedia();
  const isTablet = media.sm;
  const isMobile = media.xs;

  const imageUrl = isMobile
    ? backgroundImageData?.formats?.small?.url
    : isTablet
    ? backgroundImageData?.formats?.medium?.url
    : backgroundImageData?.url;

  useEffect(() => {
    updateHeroTitleFromUrlParams();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {isTablet ? (
        <SearchMobile
          backgroundImage={imageUrl || ''}
          backgroundImageAlt={backgroundImageData?.alternativeText || ''}
          firstLineText={data?.firstLineText}
          secondLineText={data?.secondLineText}
          secondaryFirstLineText={data?.secondaryFirstLineText}
          secondarySecondLineText={data?.secondarySecondLineText}
          submitButtonLabel={data?.submitButtonLabel}
          theme={data?.theme}
          scrim={data.scrim}
        />
      ) : (
        <SearchDesktop
          backgroundImage={imageUrl || ''}
          backgroundImageAlt={backgroundImageData?.alternativeText || ''}
          firstLineText={data?.firstLineText}
          secondLineText={data?.secondLineText}
          secondaryFirstLineText={data?.secondaryFirstLineText}
          secondarySecondLineText={data?.secondarySecondLineText}
          submitButtonLabel={data?.submitButtonLabel}
          showSameLocationToggler={data?.showSameLocationToggler}
          searchFieldLowerRightText={data?.searchFieldLowerRightText}
          reducedHeroImageHeight={data?.reducedHeroImageHeight || false}
          rating={data?.rating}
          theme={data?.theme}
          scrim={data.scrim}
        />
      )}
    </Box>
  );
};

export default SearchHero;

import React from 'react';
import { Box } from '@drivekyte/web-components';
import styled from 'styled-components';

const Divider = () => (
  <Box display="flex" alignItems="center" width="1px" height="100%">
    <VerticalLine />
  </Box>
);

const VerticalLine = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  background-color: #e0e0e0;
`;

export default Divider;

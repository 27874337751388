import React from 'react';
import { Box } from '@drivekyte/web-components';
import SplitParagraph from '@/components/cms/split-paragraph';
import SectionHeader from '@/components/growth/section-header';
import { LocalTipsProps } from './types';

const LocalTips = ({
  tipText,
  primaryText,
  successText,
  caption,
}: LocalTipsProps) => (
  <Box
    marginTop="xxxLarge"
    marginLeft="medium"
    marginRight="medium"
    marginBottom="xxxLarge"
  >
    <SectionHeader
      tipText={tipText}
      primaryText={primaryText}
      successText={successText}
      noMargin
    />
    <SplitParagraph>{caption}</SplitParagraph>
  </Box>
);

export default LocalTips;

import styled from 'styled-components';

type ShadedContainerProps = {
  padding?: string;
};

const ShadedContainer = styled.div<ShadedContainerProps>`
  ${({ theme, padding }) => `
    background: #FAFAFA;
    padding: ${padding ? theme.spacings[padding] || 0 : 0}px 0;
  `}
`;

export default ShadedContainer;

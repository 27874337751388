import { ThemeBackground } from '@/components/cms/types';
import WPTypography from '@/components/typography/typography';
import { ComponentDataContext } from '@/utils/strapi-cms/component-data-context';
import {
  Box,
  Container,
  Grid,
  Icon,
  LinkButton,
} from '@drivekyte/web-components';
import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { AdvantagesCarRental as AdvantagesCarRentalType } from './types';

const AdvantagesCarRental = ({ data }: { data: AdvantagesCarRentalType }) => {
  const { carRentalAdvantages } = useContext(ComponentDataContext);

  return (
    <>
      {!!carRentalAdvantages?.length ?? (
        <ShadedContainer backgroundTheme={data.theme}>
          <Container>
            <Box
              display="flex"
              flexDirection="row"
              paddingVertical="xLarge"
              width="100%"
              maxWidth="1280px"
            >
              <Grid container direction="row" spacing={5}>
                {(carRentalAdvantages || []).map(
                  ({ title, description, link, id }) => (
                    <Grid item key={id} sm={4} xs={12}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="start"
                      >
                        <Box marginRight="small">
                          <Icon name="24-checkmark-rounded" color="secondary" />
                        </Box>
                        <Box marginBottom="tiny">
                          <WPTypography
                            variant="h3"
                            color="primary"
                            fontFamily="Gilroy"
                            fontSize="17"
                            lineHeight={24}
                            fontWeight={600}
                            textAlign="left"
                          >
                            {title}
                          </WPTypography>
                          <WPTypography
                            variant="span"
                            color="primary"
                            fontSize="16"
                            lineHeight={24}
                            fontWeight={400}
                            textAlign="left"
                          >
                            {description}
                          </WPTypography>

                          {link && (
                            <Box marginTop="tiny">
                              <LinkButton href={link?.url}>
                                {link?.text}
                              </LinkButton>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  ),
                )}
              </Grid>
            </Box>
          </Container>
        </ShadedContainer>
      )}
    </>
  );
};

const ShadedContainer = styled.div<{ backgroundTheme?: ThemeBackground }>`
  ${({ backgroundTheme }) => css`
    background: ${backgroundTheme === 'light-green'
      ? 'rgba(9, 138, 34, 0.05)'
      : 'none'};
  `}
`;

export default AdvantagesCarRental;

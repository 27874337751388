import React from 'react';
import { Box } from '@drivekyte/web-components';
import Image from 'next/image';
import styled from 'styled-components';
import WPTypography from '@/components/typography';
import { RatingProps } from './types';

const Rating = (props: RatingProps) => {
  const data = props?.data;
  const starsIcon = data?.rating >= 4.8 ? '5stars.png' : '4-half-stars.svg';

  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <Box marginRight="tiny">
        <WPTypography color="primary" fontSize={14} variant="span">
          {data?.rating}
        </WPTypography>
      </Box>
      <Image
        src={`/images/icons/${starsIcon}`}
        width={100}
        height={20}
        alt={'rating'}
      />
      <RatingDivider />
      <WPTypography color="quaternary" fontSize={14} variant="span">
        {data?.label}
      </WPTypography>
    </Box>
  );
};

const RatingDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.quinary};
  height: 28px;
  width: 1px;
  margin-right: ${({ theme }) => theme.spacings.medium}px;
  margin-left: ${({ theme }) => theme.spacings.medium}px;
`;

export default Rating;

import { ComponentDataContext } from '@/utils/strapi-cms/component-data-context';
import { useMedia } from '@drivekyte/ui';
import dynamic from 'next/dynamic';
import { useContext } from 'react';
import { AsFeaturedInProps, Variation } from './types';

const RowComponent = dynamic(() => import('./components/row-component'));
const StackedComponent = dynamic(() =>
  import('./components/stacked-component'),
);

const AsFeaturedIn = (props: AsFeaturedInProps) => {
  const media = useMedia();

  const { generalAsFeaturedIn } = useContext(ComponentDataContext);

  // Use 'general-as-featured-in' component if the template is general, otherwise use the custom props data
  const data =
    props?.data?.template === 'general'
      ? generalAsFeaturedIn?.asFeaturedIn
      : props?.data;
  const variation = props?.variation || Variation.Row;
  const fullWidth = props?.data?.fullWidth || false;
  const allImages = data?.image?.data || [];

  const displayImages = media.xs
    ? allImages.filter((_: any, index: number) => index < 3)
    : allImages;

  if (variation === Variation.Stacked) {
    return (
      <StackedComponent
        isMobile={media.xs}
        title={data?.title || ''}
        displayImages={displayImages}
      />
    );
  }

  return (
    <RowComponent
      isMobile={media.xs}
      fullWidth={fullWidth}
      title={data?.title.toLocaleUpperCase() || ''}
      displayImages={displayImages}
    />
  );
};

export default AsFeaturedIn;

import React from 'react';
import { Image } from '@drivekyte/web-components';
import { HeadingProps, LiProps } from 'react-markdown/lib/ast-to-react';
import WPTypography from '@/components/typography';
import styled, { css } from 'styled-components';
import { Box, Typography } from '@drivekyte/ui';
import Link from '@/components/link/link';

export type ThemeTypographies =
  | 'headline-1'
  | 'headline-2'
  | 'headline-3'
  | 'body-1'
  | 'body-2'
  | 'label-1'
  | 'label-2'
  | 'label-3'
  | 'caption-1'
  | 'caption-2'
  | 'jumbo'
  | 'section-title';

export const HeadingComponent = ({ children, level }: HeadingProps) => {
  switch (level) {
    case 1:
      return <Typography variant="headingSmall">{children}</Typography>;
    case 2:
      return <Typography variant="headingMedium">{children}</Typography>;
    case 3:
      return <Typography variant="headingLarge">{children}</Typography>;
    default:
      return <Typography variant="headingLarge">{children}</Typography>;
  }
};

export const ParagraphComponent = ({
  children,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => (
  <Box marginVertical="small" backgroundColor="transparent">
    <Typography variant="paragraphMedium" color="$backgroundNeutralHover">
      {children}
    </Typography>
  </Box>
);

export const StrongComponent = ({
  children,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => <Typography variant="labelSmall">{children}</Typography>;

export const ItalicComponent = ({
  children,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => (
  <WPTypography
    variant="span"
    fontSize={16}
    fontWeight={400}
    color="primary"
    fontStyle="italic"
    lineHeight={24}
  >
    {children}
  </WPTypography>
);

export const LiComponent = ({ children, index, ordered }: LiProps) => (
  <ListItem>{`${ordered ? `${index + 1}.` : '• '} ${children}`}</ListItem>
);

export const ImageComponentBasic = ({
  src,
  alt,
  width,
  height,
}: React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
  // @ts-ignore
>) => <Image alt={alt} src={src} width={width} height={height} />;

export const LinkComponent = ({ href, children }: any) => (
  <Link
    href={href}
    openNewWindow
    color="$backgroundPositiveHover"
    variant={'labelMedium'}
    active
  >
    {children.toString()}
  </Link>
);

const ListItem = styled.li`
  ${({ theme }) =>
    css`
      color: ${theme.colors.primary};
      line-height: ${theme.typographies['body-1'].lineHeight}px;
      font-size: ${theme.typographies['body-1'].fontSize}px;
    `}
  font-family: 'Hanken Grotesk';
  box-sizing: border-box;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-top: 16px;
`;

import { ThemeBackground } from '@/components/cms/types';
import WPTypography from '@/components/typography';
import { ComponentDataContext } from '@/utils/strapi-cms/component-data-context';
import { useMedia } from '@drivekyte/ui';
import { Box, Container, Divider, Grid } from '@drivekyte/web-components';
import { useContext } from 'react';
import styled, { css } from 'styled-components';
import SectionItem from './components/section-item/section-item';
import { HowItWorksProps, Step } from './types';

enum HowItWorksTemplate {
  CUSTOM = 'custom',
  GENERAL = 'general',
}

const HowItWorks = ({ data }: { data: HowItWorksProps }) => {
  const { generalHowItWorks } = useContext(ComponentDataContext);

  const mapHowItWorksTemplateToData = {
    [HowItWorksTemplate.GENERAL]: generalHowItWorks,
    [HowItWorksTemplate.CUSTOM]: data,
  };

  const howItWorksData = mapHowItWorksTemplateToData[data.template] || data;

  const media = useMedia();

  return (
    <ShadedContainer
      backgroundTheme={data.theme}
      style={{ minHeight: media.xs ? '864px' : '400px' }}
    >
      <Container>
        <Box marginBottom="xLarge" paddingTop="xLarge" width="100%">
          <WPTypography
            variant="h2"
            color="primary"
            fontFamily="Gilroy"
            fontSize="28"
            lineHeight={32}
            fontWeight={700}
            textAlign="center"
          >
            {howItWorksData.headline}
          </WPTypography>
        </Box>
        {!media.xs && (
          <Box marginBottom="xxLarge">
            <Divider />
          </Box>
        )}
        <Grid container direction="row" spacing={!media.xs ? 10 : 2}>
          {(howItWorksData?.steps || []).map((step: Step) => (
            <Grid item key={step.title} md={4} xs={12}>
              <SectionItem
                icon={step?.icon}
                title={step.title}
                description={step.description}
              />
            </Grid>
          ))}
        </Grid>
        {media.xs && <Divider />}
      </Container>
    </ShadedContainer>
  );
};

type ShadedContainerProps = {
  padding?: string;
  backgroundTheme?: ThemeBackground;
};

const ShadedContainer = styled.div<ShadedContainerProps>`
  ${({ backgroundTheme, theme, padding }) => css`
    background: ${backgroundTheme === 'light-green'
      ? 'rgba(9, 138, 34, 0.05)'
      : 'none'};
    padding: ${padding ? theme.spacings[padding] || 0 : 0}px 0;
  `}
`;

export default HowItWorks;

import React from 'react';
import { Typography } from '@drivekyte/web-components';
import styled, { css } from 'styled-components';
import useTranslation from '@/hooks/use-translation';

const ListFooter = () => {
  const { t } = useTranslation('components.search.search-content');

  return (
    <Footer>
      <Content>
        <Typography color="quaternary" variant="caption-1">
          {t('footer')}
        </Typography>
      </Content>
    </Footer>
  );
};

const Footer = styled.div`
  ${({ theme }) => css`
    background: white;
    border-bottom-left-radius: ${theme.borderRadius.base}px;
    border-bottom-right-radius: ${theme.borderRadius.base}px;
    box-shadow: ${theme.boxShadows.base};
    border-color: ${theme.borderColors.primary};
    border-style: solid;
    border-width: ${theme.borderWidths.base}px;
  `}
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacings.small}px;
  background: ${({ theme }) => theme.shapeFills.primary}08;
`;

export default ListFooter;

import { usePostHog } from 'posthog-js/react';
import PosthogFlags from '@/types/posthog-flags';

const useStickyScrollBarStrapi = () => {
  const posthog = usePostHog();
  const featureFlag = posthog.getFeatureFlag(
    PosthogFlags.StickyScrollBarStrapi,
  );
  const isStickyScrollBarEnabled = featureFlag === 'test';

  return {
    enabled: isStickyScrollBarEnabled,
    isLoading: featureFlag === undefined,
  };
};

export default useStickyScrollBarStrapi;

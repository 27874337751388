import React from 'react';
import { Box } from '@drivekyte/web-components';

export type SearchBoxContainerProps = {
  children: React.ReactNode;
  onSubmit: (event: React.FormEvent<EventTarget>) => void;
};

const SearchBoxContainer = ({
  children,
  onSubmit,
}: SearchBoxContainerProps) => (
  <form onSubmit={onSubmit}>
    <Box
      borderColor="primary"
      borderRadius="base"
      borderStyle="solid"
      borderWidth="base"
      boxShadow="base"
      boxSizing="border-box"
      display="flex"
      height="80px"
      marginBottom="small"
      shapeFill="senary"
      zIndex={10}
    >
      {children}
    </Box>
  </form>
);

export default SearchBoxContainer;

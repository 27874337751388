import { QRCodeSVG } from 'qrcode.react';
import { BorderedSVG } from './bordered-svg';

type AppQrCodeProps = {
  url: string;
  src: string;
  useNativeSVG?: boolean;
};

const SVGDimensions = {
  width: 160,
  height: 160,
};

export const AppQrCode = ({
  url,
  src,
  useNativeSVG = false,
}: AppQrCodeProps) => {
  const { width, height } = SVGDimensions;

  const imageSize = Math.min(width, height) * 0.8;
  const imageX = (width - imageSize) / 2;
  const imageY = (height - imageSize) / 2;

  return (
    <BorderedSVG width={width} height={height}>
      {useNativeSVG ? (
        <image
          x={imageX}
          y={imageY}
          xlinkHref={src}
          width={imageSize}
          height={imageSize}
          preserveAspectRatio="xMidYMid slice"
        />
      ) : (
        <QRCodeSVG x={imageX} y={imageY} size={imageSize} value={url} />
      )}
    </BorderedSVG>
  );
};

import { Typography, useTheme } from '@drivekyte/ui';
import React from 'react';

interface LineItemProps {
  children: string;
}

const LineItem: React.FC<LineItemProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <td
      style={{
        borderTop: `1px solid ${theme.borderDefault.val}`,
        textAlign: 'left',
        height: '64px',
        verticalAlign: 'middle',
      }}
    >
      <Typography variant="labelLarge" color="$green500">
        {children}
      </Typography>
    </td>
  );
};

export default LineItem;

import React from 'react';
import { Place } from '@drivekyte/use-places-search';
import { Box, Divider } from '@drivekyte/web-components';
import { QueryBookingCart } from '@/types/booking-cart';
import SameLocationToggler from './components/same-location-toggler';
import WhereItem from './components/where-item';

type WhereProps = {
  startAddress?: string;
  endAddress?: string;
  isSameDeliveryReturnLocations: boolean;
  onChangeAddress: (nextAddressQuery: Partial<QueryBookingCart>) => void;
  onChangeToggler: (nextValue: boolean) => void;
};

const Where = ({
  startAddress = '',
  endAddress = '',
  isSameDeliveryReturnLocations,
  onChangeAddress,
  onChangeToggler,
}: WhereProps) => {
  const handleChangeAddress = (key: string) => (place: Place) => {
    const address = place.map_to?.display_text || place.display_text;
    if (isSameDeliveryReturnLocations) {
      onChangeAddress({
        start_address: address,
        end_address: address,
        start_place: place.display_text,
        end_place: place.display_text,
        service_area_uuid: place.service_area_uuid,
        service_area_code: place.service_area_code,
        end_service_area_uuid: place.service_area_uuid,
        end_service_area_code: place.service_area_code,
      });
    } else {
      onChangeAddress({
        [`${key}_address`]: address,
        [`${key}_place`]: place.display_text,
        [`${key === 'start' ? '' : `${key}_`}service_area_uuid`]:
          place.service_area_uuid,
        [`${key === 'start' ? '' : `${key}_`}service_area_code`]:
          place.service_area_code,
      });
    }
  };

  return (
    <Box
      borderRadius="base"
      borderColor="primary"
      borderWidth="large"
      backgroundColor="elevated"
      borderBottomWidth="large"
      boxShadow="base"
      borderStyle="solid"
    >
      {isSameDeliveryReturnLocations ? (
        <WhereItem
          title="Delivery and return location"
          iconName="16-pin"
          placeholder="Tap to search"
          onChange={handleChangeAddress('start')}
          value={startAddress}
        />
      ) : (
        <>
          <WhereItem
            title="Delivery location"
            iconName="16-pin"
            placeholder="Tap to search"
            onChange={handleChangeAddress('start')}
            value={startAddress}
          />
          <Divider />
          <WhereItem
            title="Return location"
            iconName="16-end-location"
            placeholder="Tap to search"
            onChange={handleChangeAddress('end')}
            value={endAddress}
          />
        </>
      )}
      <Divider />
      <SameLocationToggler
        value={!!isSameDeliveryReturnLocations}
        onChange={onChangeToggler}
      />
    </Box>
  );
};

export default Where;

import { Box } from '@drivekyte/ui';

const StepSeparator = () => {
  return (
    <Box
      height={40}
      width={2}
      borderLeftWidth={2}
      borderLeftColor="$green300"
      borderStyle="dashed"
    />
  );
};

export default StepSeparator;

import React from 'react';
import { Box, Button } from '@drivekyte/web-components';
import { useMedia } from '@drivekyte/ui';
import LiveDataNotice from '@/components/live-data-notice';
import SearchBox from '@/components/search-box/search-box';
import useSearch from '@/hooks/use-search';
import useTranslation from '@/hooks/use-translation';

const WhereAndWhen = () => {
  const { t } = useTranslation('pages.landing-page.search-box');
  const media = useMedia();
  const {
    isFormValid,
    isLoadingTimeSlots,
    isFetchingQuotes,
    onSubmitWithQuotes,
    socialLiveData,
    socialLiveDataParams,
  } = useSearch({ disableTimeslotFetching: true });
  const socialLiveDataMessage = socialLiveData?.data?.content.title;

  return (
    <SearchBox calendarTestID="responsive-landing-page-calendar">
      <Box marginTop="small" marginBottom="xxLarge">
        <Button
          testID="responsive-landing-page-search-cta"
          fullWidth
          loading={isFetchingQuotes || isLoadingTimeSlots}
          disabled={!isFormValid}
          onPress={onSubmitWithQuotes}
        >
          {t('cta')}
        </Button>
        {socialLiveDataMessage && !socialLiveData.error && (
          <Box marginTop="small">
            <LiveDataNotice
              center={media.xs}
              location="lp-search_bar"
              message={socialLiveDataMessage}
              isLoading={socialLiveData.isLoading}
              liveDataComponentId={socialLiveData.data?.id}
              liveDataParams={socialLiveDataParams}
            />
          </Box>
        )}
      </Box>
    </SearchBox>
  );
};

export default WhereAndWhen;

import React from 'react';
import { TimePickerProps } from '@drivekyte/web-components';
import dayjs from 'dayjs';
import { END_FROM_MONTH, END_TO_MONTH } from '@/constants/booking-flow';
import { SearchInputState } from '@/hooks/use-search-input-state';
import { formatDateTime } from '@/utils/date-formatter';
import InputDate from '../input-date';

export type EndDateProps = {
  active: boolean;
  endDate: Date;
  label: string;
  onChange: (
    nextSelectedDate: Date | undefined,
    origin: 'date-picker' | 'time-picker',
  ) => void;
  onChangeInputState: (newState: Partial<SearchInputState>) => void;
  startDate: Date;
  timePickerProps: Pick<
    TimePickerProps,
    'titleText' | 'options' | 'loading' | 'tooltip'
  >;
  disabled?: boolean;
  hasNoOptions: boolean;
  emptyTitleText: string;
};

const EndDate = ({
  active,
  endDate,
  label,
  onChange,
  onChangeInputState,
  startDate,
  timePickerProps,
  disabled,
  hasNoOptions,
  emptyTitleText,
}: EndDateProps) => {
  const handleActive = () => {
    onChangeInputState({ showEndDate: true });
  };

  const handleDeactivate = () => {
    onChangeInputState({ showEndDate: false });
  };

  return (
    <InputDate
      datePickerProps={{
        disabledDays: [{ before: dayjs(startDate).toDate() }],
        fromMonth: END_FROM_MONTH,
        initialMonth: endDate,
        toMonth: END_TO_MONTH,
      }}
      formattedValue={hasNoOptions ? emptyTitleText : formatDateTime(endDate)}
      label={label}
      onSelect={onChange}
      popoverProps={{
        offset: [-68, 12],
        onRequestToClose: handleDeactivate,
        onRequestToOpen: handleActive,
        visible: active,
      }}
      timePickerProps={{
        loading: timePickerProps.loading,
        options: timePickerProps.options,
        titleText: timePickerProps.titleText,
        tooltip: timePickerProps.tooltip,
      }}
      value={endDate}
      disabled={disabled}
    />
  );
};

export default EndDate;

import { getTokenValue, Typography, useTheme } from '@drivekyte/ui';
import React from 'react';

interface TableHeaderProps {
  isHiglighted?: boolean;
  label: string;
  totalColumns: number;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  isHiglighted,
  label,
  totalColumns,
}) => {
  const theme = useTheme();

  return (
    <th
      style={{
        backgroundColor: isHiglighted ? theme.green400.val : 'none',
        borderTopLeftRadius: getTokenValue('$large', 'radius'),
        borderTopRightRadius: getTokenValue('$large', 'radius'),
        height: '64px',
        textAlign: 'center',
        verticalAlign: 'middle',
        width: `calc(100% / ${totalColumns})`,
      }}
    >
      <Typography
        variant="headingSmall"
        color={isHiglighted ? '$contentInversePrimary' : '$contentSecondary'}
      >
        {label}
      </Typography>
    </th>
  );
};

export default TableHeader;

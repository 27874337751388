import { UseQueryResult, useQueries, useQuery } from 'react-query';
import { ServiceAreaDetails } from '@/types/service-area';
import request, { RequestError } from '@/utils/request';
import {
  CACHE_DURATION_MS,
  STALE_DURATION_MS,
} from '@/constants/requests-configuration';

const fetchServiceAreaDetails = async (
  serviceAreaID: string,
): Promise<ServiceAreaDetails> => {
  const {
    data: { data: response },
  } = await request({
    url: `/v2/service_areas/${serviceAreaID}`,
  });

  return response;
};

const useServiceAreaDetails = (serviceAreaId: string, enabled: boolean) =>
  useQuery<ServiceAreaDetails, RequestError>(
    ['serviceAreaDetails', serviceAreaId],
    () => fetchServiceAreaDetails(serviceAreaId),
    {
      enabled,
      staleTime: STALE_DURATION_MS.medium,
      cacheTime: CACHE_DURATION_MS.medium,
    },
  );

export const useCombinedServiceAreaDetails = (
  serviceAreaArr: string[],
  enabled: boolean,
) => {
  const responses = useQueries(
    serviceAreaArr.map((serviceAreaId) => {
      return {
        queryKey: ['combinedServiceAreaDetails', serviceAreaId],
        queryFn: () => fetchServiceAreaDetails(serviceAreaId),
        enabled,
      };
    }),
  ) as UseQueryResult<ServiceAreaDetails>[];
  let combinedServiceArea = responses[0].data;
  if (
    combinedServiceArea &&
    responses.every((area) => area.isSuccess && area.data)
  ) {
    combinedServiceArea.geojson.features = responses
      .map((area) => area.data?.geojson.features || [])
      .flat();
    combinedServiceArea.exceptions = responses
      .map((area) => area.data?.exceptions || [])
      .flat();
    return { data: combinedServiceArea, responses, isLoading: false };
  }
  return { responses, isLoading: true };
};

export default useServiceAreaDetails;

import { Download } from '@drivekyte/icons';
import { Box, Button, Typography, useMedia } from '@drivekyte/ui';
import { AppQrCode } from './components/app-qr-code';
import { DownloadCtaProps } from './types';
import React from 'react';

const DownloadCta = ({ data }: DownloadCtaProps) => {
  const media = useMedia();
  const download = () => window.open(data.URL, '_blank');

  return (
    <Box
      padding="$xLarge"
      borderRadius={40}
      backgroundColor="#064916"
      alignContent="space-between"
      justifyContent="space-between"
      $gtSm={{ flexDirection: 'row' }}
      $sm={{ flexDirection: 'column', justifyContent: 'center' }}
    >
      <Box
        flex={1}
        marginRight="$medium"
        flexDirection="column"
        justifyContent="space-around"
        $sm={{ marginRight: '$none' }}
      >
        <Typography
          textAlign="left"
          marginBottom="$small"
          variant="headingLarge"
          color="$contentInversePrimary"
          $sm={{ textAlign: 'center', marginBottom: '$none' }}
        >
          {media.gtSm ? data.Headline : data.MobileHeadline}
        </Typography>
        {!media.gtSm && (
          <Box
            alignContent="center"
            justifyContent="center"
            marginVertical="$medium"
          >
            <Typography textAlign="center" color="$contentInversePrimary">
              {data.Subtitle}
            </Typography>
          </Box>
        )}
        <Box
          alignItems="center"
          flexDirection="row"
          alignContent="center"
          justifyContent="flex-start"
          $sm={{ justifyContent: 'center' }}
        >
          <Button
            size="medium"
            variant="tertiary"
            onPress={download}
            icon={<Download />}
            $sm={{ flex: 1, size: 'medium' }}
          >
            {data.CTA}
          </Button>
          {media.gtSm && (
            <Typography marginLeft="$small" color="$contentInversePrimary">
              {data.Subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      {media.gtSm && (
        <AppQrCode url={data.URL} src={data.QRCodeImage.data.attributes.url} />
      )}
    </Box>
  );
};

export default DownloadCta;

import useStickyScrollBarStrapi from '@/hooks/experiments/use-sticky-scrollbar-strapi/use-sticky-scrollbar-strapi';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type SearchbarToggleContextType = {
  isSearchbarOpen: boolean;
  toggleSearchbar: (value?: boolean) => void;
  isSticky: boolean;
  // for strapi pages to determine whether there should
  // be a sticky searchbar
  isStickySearchBar: boolean;
  // for pages without a searchbar
  containsDefaultSearchBar: boolean;
};

const SearchbarToggleContext = createContext<
  SearchbarToggleContextType | undefined
>(undefined);

type SearchbarToggleProviderProps = {
  isStickySearchBar?: boolean;
  containsDefaultSearchBar?: boolean;
  children: ReactNode;
};

export const SearchbarToggleProvider = ({
  isStickySearchBar = false,
  containsDefaultSearchBar = true,
  children,
}: SearchbarToggleProviderProps) => {
  const [isSearchbarOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const toggleSearchbar = (value?: boolean) =>
    setIsOpen((prev) => value ?? !prev);
  // distance from the bottom of the first component on the page to the top of the curr. viewport
  const firstElementOffset = 100;
  const { enabled: stickyScrollBarFlagEnabled } = useStickyScrollBarStrapi();
  isStickySearchBar = isStickySearchBar || stickyScrollBarFlagEnabled;

  const toggleNonNavbarSearchVisibility = (visibilityValue: string) => {
    const searchbarBox = document.getElementById('search-bar');
    if (searchbarBox) {
      searchbarBox.style.visibility = visibilityValue;
    }
  };

  // no searchbars or rental heros on the first div
  // e.g. /checkout
  const handleNoPrevSearchBar = () => {
    if (window.scrollY > firstElementOffset) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const handleScroll = () => {
    if (!containsDefaultSearchBar) {
      handleNoPrevSearchBar();
      return;
    }
    const mainElement = document.querySelector('main');
    const wrapperDiv = mainElement?.firstElementChild;
    if (mainElement && wrapperDiv) {
      const firstElementBelowNav = wrapperDiv.firstElementChild;
      if (!firstElementBelowNav) {
        return;
      }
      const rect = firstElementBelowNav.getBoundingClientRect();
      if (rect.bottom <= firstElementOffset) {
        setIsSticky(true);
        toggleNonNavbarSearchVisibility('hidden');
      } else {
        setIsSticky(false);
        toggleNonNavbarSearchVisibility('visible');
      }
    }
  };

  useEffect(() => {
    if (!isStickySearchBar) return;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <SearchbarToggleContext.Provider
      value={{
        isSearchbarOpen,
        toggleSearchbar,
        isSticky,
        isStickySearchBar,
        containsDefaultSearchBar,
      }}
    >
      {children}
    </SearchbarToggleContext.Provider>
  );
};

export const useSearchbarProvider = () => {
  const context = useContext(SearchbarToggleContext);
  if (!context) {
    throw new Error(
      'useSearchBar must be used within the SearchbarToggleProvider',
    );
  }
  return context;
};

import { useEffect } from 'react';
import { Currency } from '@drivekyte/currency-utils';
import type { i18n as I18n } from 'i18next';
import { useRouter } from 'next/router';
import { useTranslation as useTranslationI18next } from 'react-i18next';
import { IS_PRODUCTION } from '@/config/global-constants';
import { Language } from '../../config/translations';
import useCurrentCurrency from '../use-current-currency';

const currencyLanguageMap: Record<Currency, Language> = {
  CAD: 'enCAN',
  USD: 'enUS',
};

type UseTranslationOptions = {
  ns?: string;
  returnObjects?: true;
};

const useTranslation = (baseKey?: string, options?: UseTranslationOptions) => {
  const { t: translate, i18n } = useTranslationI18next();
  const { currentCurrency } = useCurrentCurrency();
  const { asPath } = useRouter();

  useEffect(() => {
    i18n?.changeLanguage(currencyLanguageMap[currentCurrency]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCurrency]);
  const t = (key: string, extraData = {}) => {
    const combinedKey = baseKey ? `${baseKey}.${key}` : key;
    // Strapi CMS uses URL path as i18n namespace, will fall back to default if not loaded
    const withStrapi =
      i18n.exists && i18n.exists(combinedKey, { ns: asPath })
        ? { ns: asPath }
        : {};
    const combinedOptions = { ...withStrapi, ...options, ...extraData };
    const translation = translate(combinedKey, combinedOptions);
    // If there is no translation key value, log as an error
    if (
      !IS_PRODUCTION &&
      i18n.exists &&
      !i18n.exists(combinedKey, combinedOptions)
    ) {
      const translationErrorMessage = `Translation key ${translation} is missing a value on ${asPath}`;
      console.error(translationErrorMessage);
    }

    return translation;
  };

  return { t, i18n };
};

export function useTranslationAsObject(
  baseKey?: string,
  options?: { ns?: string },
): {
  t: (key: string, extraData?: UseTranslationOptions | {}) => any;
  i18n: I18n;
} {
  return useTranslation(baseKey, { ...options, returnObjects: true });
}

export default useTranslation;

import { ThemeBackground } from '@/components/cms/types';
import SectionHeader from '@/components/growth/section-header';
import Map from '@/components/service-area-details/components/service-area-map';
import useServiceAreaDetails from '@/hooks/use-service-area-details';
import { useMedia } from '@drivekyte/ui';
import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
} from '@drivekyte/web-components';
import styled, { css } from 'styled-components';
import { NeighborhoodProps, ServiceMapProps } from './types';

const ServiceMap = (props: ServiceMapProps) => {
  const data = props?.data;
  const header = data?.header;
  const serviceAreaId = data?.service_area?.data?.attributes?.uuid || '';
  const neighborhoodString =
    data?.service_area?.data?.attributes?.neighborhoods;
  const neighborhoods =
    neighborhoodString?.split(',')?.map((item) => item.trim()) ?? [];

  const media = useMedia();
  const isMobile = media.xs;

  const { data: serviceAreaDetails } = useServiceAreaDetails(
    serviceAreaId,
    Boolean(serviceAreaId),
  );

  return (
    <ShadedContainer backgroundTheme={data.theme}>
      <Container>
        <Box
          display="flex"
          flexDirection="row"
          paddingBottom="xxxLarge"
          paddingTop="xxLarge"
          width="100%"
          maxWidth="1280px"
        >
          <Grid container spacing={3}>
            <Grid item md={5} sm={12}>
              <SectionHeader
                primaryText={header?.primaryText || ''}
                successText={header?.successText || ''}
                captionText={header?.captionText || ''}
                noMargin
              />
              <Box marginBottom="base" marginTop="large">
                <NeighborhoodList>
                  {neighborhoods.map((name: string) => (
                    <Neighborhood
                      key={name}
                      isSplit={neighborhoods.length > 10 || isMobile}
                    >
                      {name}
                    </Neighborhood>
                  ))}
                </NeighborhoodList>
              </Box>
            </Grid>

            <Grid container item md={7} sm={12}>
              <Box width="100%" minHeight={isMobile ? '350px' : '464px'}>
                {serviceAreaDetails?.center &&
                  serviceAreaDetails?.exceptions &&
                  serviceAreaDetails?.geojson && (
                    <Map
                      center={serviceAreaDetails.center}
                      exceptions={[]}
                      geojson={serviceAreaDetails.geojson}
                      onExceptionPress={() => {}}
                      zoom={10}
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Hidden smUp>
          <Divider />
        </Hidden>
      </Container>
    </ShadedContainer>
  );
};

const ShadedContainer = styled.div<{ backgroundTheme?: ThemeBackground }>`
  ${({ backgroundTheme }) => css`
    background: ${backgroundTheme === 'light-green'
      ? 'rgba(9, 138, 34, 0.05)'
      : 'none'};
  `}
`;

const NeighborhoodList = styled.ul`
  display: flex;
  flex-flow: row wrap;
`;

const Neighborhood = styled.li<NeighborhoodProps>`
  ${({ isSplit, theme }) =>
    css`
      width: ${isSplit ? '50%' : '100%'};
      color: ${theme.colors.quaternary};
      padding-right: ${theme.spacings.small}px;
      line-height: ${theme.typographies['headline-2'].lineHeight}px;
      font-size: ${theme.typographies['body-1'].fontSize}px;
    `}
  font-family: 'Hanken Grotesk';
  box-sizing: border-box;
`;

export default ServiceMap;

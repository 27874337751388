import { RentalHero as RentalHeroType } from './types';
import { Container } from '@drivekyte/web-components';
import { useMedia } from '@drivekyte/ui';
import SearchDesktop from './components/search-desktop';
import SearchMobile from './components/search-mobile';
import useBase64EncodedData from '@/hooks/use-base64-encoded-data.ts';

const RentalHero = ({ data }: { data: RentalHeroType }) => {
  const media = useMedia();
  const isMobile = media.xs;
  const isTablet = media.sm;
  const isDesktop = !isMobile && !isTablet;

  const base64Data = useBase64EncodedData();

  const { headline, description, ctaText } = data;

  const base64Headline = base64Data?.h1 || headline;
  const base64Description = base64Data?.description || description;
  const base64CTAText = base64Data?.cta || ctaText;

  return (
    <Container>
      {isDesktop ? (
        <SearchDesktop
          headline={base64Headline}
          description={base64Description}
          ctaText={base64CTAText}
          backgroundImageSrc={data.backgroundImage.data.attributes.url}
          backgroundImageAltText={
            data.backgroundImage.data.attributes.alternativeText ||
            'Search hero'
          }
        />
      ) : (
        <SearchMobile
          headline={base64Headline}
          description={base64Description}
          ctaText={base64CTAText}
          backgroundImageSrc={data.backgroundImage.data.attributes.url}
          backgroundImageAltText={
            data.backgroundImage.data.attributes.alternativeText ||
            'Search hero'
          }
        />
      )}
    </Container>
  );
};

export default RentalHero;

import React from 'react';
import { Box } from '@drivekyte/web-components';
import { Step } from '@/components/cms/how-it-works/types';
import NextImage from '@/components/cms/image/image';
import { useMedia, Typography } from '@drivekyte/ui';

const SectionItem = ({ icon, title, description }: Step) => {
  const media = useMedia();

  const iconUrl = icon?.data?.attributes?.url;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginBottom={media.xs ? 'xxxLarge' : 'none'}
      width="100%"
    >
      <Box maxWidth="300px">
        <Box marginBottom="base" textAlign="center" width="100%">
          {iconUrl && <NextImage media={icon} width={50} height={50} />}
        </Box>
        <Box marginBottom="tiny">
          <Typography
            variant="headingSmall"
            display="flex"
            justifyContent={media.sm ? 'center' : 'flex-start'}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="paragraphMedium"
          textAlign={media.sm ? 'center' : 'auto'}
          display="flex"
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default SectionItem;

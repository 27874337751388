import React from 'react';
import { Box } from '@drivekyte/web-components';
import {
  MAX_IMAGE_HEIGHT,
  MAX_IMAGE_WIDTH,
} from '@/components/cms/service-areas/constants';

const ServiceAreaPlaceholder = () => (
  <Box
    marginBottom="xLarge"
    width={`${MAX_IMAGE_WIDTH}px`}
    height={`${MAX_IMAGE_HEIGHT}px`}
  />
);

export default ServiceAreaPlaceholder;

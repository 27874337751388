import React from 'react';
import { Divider } from '@drivekyte/web-components';
import ReactMarkdown from 'react-markdown';
import {
  BlockQuoteComponent,
  CodeComponent,
  HeadingComponent,
  ImageComponent,
  ItalicComponent,
  LiComponent,
  LinkComponent,
  ParagraphComponent,
  StrongComponent,
} from './components';

export type MarkdownProps = {
  children: string;
};

const Markdown = ({ children }: MarkdownProps) => (
  <ReactMarkdown
    components={{
      h1: HeadingComponent,
      h2: HeadingComponent,
      h3: HeadingComponent,
      h4: HeadingComponent,
      h5: HeadingComponent,
      h6: HeadingComponent,
      p: ParagraphComponent,
      strong: StrongComponent,
      em: ItalicComponent,
      blockquote: BlockQuoteComponent,
      li: LiComponent,
      code: CodeComponent,
      // @ts-ignore
      img: ImageComponent,
      hr: Divider,
      a: LinkComponent,
    }}
  >
    {children}
  </ReactMarkdown>
);

export default Markdown;

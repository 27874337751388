import React from 'react';
import { Box, Container, Grid } from '@drivekyte/web-components';
import NextImage from 'next/image';
import styled, { css } from 'styled-components';
import { ThemeBackground } from '@/components/cms/types';
import Markdown from '@/components/markdown';
import WPTypography from '@/components/typography';
import { Advantages as AdvantagesType } from './types';

const Advantages = ({ data }: { data: AdvantagesType }) => (
  <ShadedContainer backgroundTheme={data.theme}>
    <Container>
      <Box
        display="flex"
        flexDirection="row"
        paddingTop="large"
        paddingBottom="xxLarge"
        width="100%"
        maxWidth="1280px"
      >
        <Grid container direction="row" spacing={5}>
          {(data?.advantages || []).map(
            ({ title, description, image }, index) => (
              <Grid item key={index} sm={6} xs={12}>
                {!!(
                  image?.data?.attributes?.width &&
                  image?.data?.attributes?.height
                ) ? (
                  <NextImage
                    layout="responsive"
                    width={image?.data?.attributes?.width}
                    height={image?.data?.attributes?.height}
                    objectFit="contain"
                    alt={title}
                    src={image?.data?.attributes?.url}
                    quality={50}
                  />
                ) : (
                  <NextImage
                    layout="fill"
                    alt={title}
                    src={image?.data?.attributes?.url}
                    quality={50}
                    fill={true}
                  />
                )}

                <Box marginVertical="xSmall">
                  <WPTypography
                    variant="h2"
                    color="primary"
                    fontFamily="Gilroy"
                    fontSize="22"
                    lineHeight={32}
                    fontWeight={600}
                    textAlign="left"
                  >
                    {title}
                  </WPTypography>
                </Box>

                <Markdown>{description}</Markdown>
              </Grid>
            ),
          )}
        </Grid>
      </Box>
    </Container>
  </ShadedContainer>
);

type ShadedContainerProps = {
  padding?: string;
  backgroundTheme?: ThemeBackground;
};

const ShadedContainer = styled.div<ShadedContainerProps>`
  ${({ backgroundTheme, theme, padding }) => css`
    background: ${backgroundTheme === 'light-green'
      ? 'rgba(9, 138, 34, 0.05)'
      : 'none'};
    padding: ${padding ? theme.spacings[padding] || 0 : 0}px 0;
  `}
`;

export default Advantages;

import React from 'react';
import dayjs from 'dayjs';
import InputDate from '@/components/cms/shared-search-components/search-content/components/input-date';
import { END_FROM_MONTH, END_TO_MONTH } from '@/constants/booking-flow';
import { formatDateTime } from '@/utils/date-formatter';
import { EndDateProps } from './types';

const EndDate = ({
  active,
  endDate,
  label,
  onChange,
  onChangeInputState,
  startDate,
  timePickerProps,
  disabled,
  hasNoOptions,
  emptyTitleText,
}: EndDateProps) => {
  const handleActive = () => {
    onChangeInputState({ showEndDate: true });
  };

  const handleDeactivate = () => {
    onChangeInputState({ showEndDate: false });
  };

  return (
    <InputDate
      datePickerProps={{
        disabledDays: [{ before: dayjs(startDate).toDate() }],
        fromMonth: END_FROM_MONTH,
        initialMonth: endDate,
        toMonth: END_TO_MONTH,
      }}
      formattedValue={hasNoOptions ? emptyTitleText : formatDateTime(endDate)}
      label={label}
      onSelect={onChange}
      popoverProps={{
        offset: [-68, 12],
        onRequestToClose: handleDeactivate,
        onRequestToOpen: handleActive,
        visible: active,
      }}
      timePickerProps={{
        loading: timePickerProps.loading,
        options: timePickerProps.options,
        titleText: timePickerProps.titleText,
        tooltip: timePickerProps.tooltip,
      }}
      value={endDate}
      disabled={disabled}
    />
  );
};

export default EndDate;

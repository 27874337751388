import React, { useState } from 'react';
import { Typography } from '@drivekyte/web-components';
import styled, { css } from 'styled-components';

type AccordionProps = {
  title: string;
  children: React.ReactNode;
  ariaId?: number;
};

const Accordion = ({ title, children, ariaId }: AccordionProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div style={{ paddingTop: '1.5rem' }}>
      <dt style={{ fontSize: '1.125rem', lineHeight: '1.75rem' }}>
        <StyledButton
          type="button"
          aria-controls={`faq-${ariaId}`}
          aria-expanded="false"
          onClick={() => setExpanded(!expanded)}
          className="tw"
          aria-label={
            expanded
              ? `Expand-less-${ariaId}-accordion`
              : `Expand-more-${ariaId}-accordion`
          }
        >
          <Typography color="primary" variant="headline-3">
            {title}
          </Typography>
          <span
            style={{
              marginLeft: '1.5rem',
              height: '1.75rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <StyledIcon
              expanded={expanded}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </StyledIcon>
          </span>
        </StyledButton>
      </dt>
      <dd
        style={{ marginTop: '0.5rem', paddingRight: '3rem' }}
        id={`faq-${ariaId}`}
      >
        <div style={{ listStyle: 'inside' }}>{expanded && children}</div>
      </dd>
    </div>
  );
};

const StyledButton = styled.button`
  background: #fafafa;
  text-align: left;
  padding-left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
`;

const StyledIcon = styled.svg<{ expanded: boolean }>`
  ${({ expanded }) => css`
    height: 1.5rem;
    width: 1.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-rotate: ${expanded ? '-180deg' : '0deg'};
  `}
`;

export default Accordion;

import LiveDataNotice from '@/components/live-data-notice/live-data-notice';
import useCalendarValidDates from '@/hooks/use-calendar-valid-dates';
import useSearch from '@/hooks/use-search';
import useTranslation from '@/hooks/use-translation';
import {
  Box,
  Button,
  createTimePickerOptions,
} from '@drivekyte/web-components';
import dynamic from 'next/dynamic';
import DeliveryAndReturnToggler from './components/delivery-and-return-toggler';
import Divider from './components/divider';
import EndDate from './components/end-date';
import InputAddress from './components/input-address';
import SearchBoxContainer from './components/search-box-container';
import SearchSubmit from './components/search-submit';
import StartDate from './components/start-date';
import useVagueToLotRedirect from '@/hooks/use-vague-to-lot-redirect';

const HandoverOutpostConfirmationDialog = dynamic(() =>
  import('@/components/handover-outpost-confirmation'),
);

type SearchBarProps = {
  buttonPosition?: 'inline' | 'bottom';
  cta?: string;
};

const SearchBar = ({ buttonPosition = 'inline', cta }: SearchBarProps) => {
  const { t } = useTranslation('components.search.search-content');
  const { t: tCta } = useTranslation('components.search');

  const {
    bookingCart,
    inputState,
    isFetchingQuotes,
    isFormValid,
    isLoadingTimeSlots,
    onChange,
    onChangeAddressToggle,
    onChangeEndAddress,
    onChangeEndDate,
    onChangeStartAddress,
    onChangeStartDate,
    onSubmitWithQuotes,
    setInputState,
    timeSlotsData,
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    timeSlotsStartError,
    timeSlotsEndError,
    socialLiveData,
    socialLiveDataParams,
    handoverOutpostState,
    acceptHandoverSuggestion,
    rejectHandoverSuggestions,
  } = useSearch();
  const { enableAddressToggler } = useVagueToLotRedirect();

  const socialLiveDataMessage = socialLiveData?.data?.content.title;

  useCalendarValidDates({
    startTimeSlotsTimestamps,
    endTimeSlotsTimestamps,
    startTz: timeSlotsData?.start.tz,
    endTz: timeSlotsData?.end.tz,
    selectedDates: bookingCart,
    onChange,
  });

  return (
    <>
      <SearchBoxContainer onSubmit={onSubmitWithQuotes}>
        {bookingCart.isSameDeliveryReturnLocations ? (
          <Box display="flex" flex={6.5} flexGrow={1}>
            <InputAddress
              active={inputState.showStartAddress}
              initialInputValue={
                bookingCart.startPlace || bookingCart.startAddress
              }
              label={t('start-and-end-address.input.label')}
              onSelect={onChangeStartAddress}
              placeholder={t('start-and-end-address.input.placeholder')}
              testID="address-search-input"
              loadingTestID="address-search-loading"
            />
            <Divider />
          </Box>
        ) : (
          <Box display="flex" flex={6.5} flexGrow={1}>
            <Box width="50%">
              <InputAddress
                active={inputState.showStartAddress}
                initialInputValue={
                  bookingCart.startPlace || bookingCart.startAddress
                }
                onSelect={onChangeStartAddress}
                label={t('start-address.input.label')}
                placeholder={t('start-address.input.placeholder')}
              />
            </Box>
            <Divider />
            <Box width="50%">
              <InputAddress
                active={inputState.showEndAddress}
                initialInputValue={
                  bookingCart.endPlace || bookingCart.endAddress
                }
                onSelect={onChangeEndAddress}
                label={t('end-address.input.label')}
                placeholder={t('end-address.input.placeholder')}
              />
            </Box>
            <Divider />
          </Box>
        )}
        <Box display="flex" flex={2.5} cursor="pointer">
          <StartDate
            active={!timeSlotsStartError && inputState.showStartDate}
            label={t('start-date.input.label')}
            onChange={onChangeStartDate}
            onChangeInputState={setInputState}
            startDate={bookingCart.startDate}
            timePickerProps={{
              loading: isLoadingTimeSlots,
              options:
                timeSlotsData?.start.timeSlots ||
                createTimePickerOptions(
                  7,
                  22,
                  bookingCart.startDate,
                  '2-digit',
                ),
              titleText: t('start-date.time-picker.title'),
            }}
            disabled={timeSlotsStartError}
            emptyTitleText={t('start-date.empty-state')}
            hasNoOptions={timeSlotsData?.start.timeSlots.length === 0}
          />
        </Box>
        <Divider />
        <Box display="flex" flex={2.5} cursor="pointer">
          <EndDate
            active={!timeSlotsEndError && inputState.showEndDate}
            endDate={bookingCart.endDate}
            label={t('end-date.input.label')}
            onChange={onChangeEndDate}
            onChangeInputState={setInputState}
            startDate={bookingCart.startDate}
            timePickerProps={{
              loading: isLoadingTimeSlots,
              options:
                timeSlotsData?.end.timeSlots ||
                createTimePickerOptions(7, 22, bookingCart.endDate, '2-digit'),
              titleText: t('end-date.time-picker.title'),
            }}
            disabled={timeSlotsEndError}
            emptyTitleText={t('start-date.empty-state')}
            hasNoOptions={timeSlotsData?.end.timeSlots.length === 0}
          />
        </Box>
        {buttonPosition === 'inline' && (
          <Box display="flex" width="100px" margin="small">
            <SearchSubmit
              cta={cta}
              loading={isFetchingQuotes}
              disabled={!isFormValid || isLoadingTimeSlots}
            />
          </Box>
        )}
      </SearchBoxContainer>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <DeliveryAndReturnToggler
          value={bookingCart.isSameDeliveryReturnLocations}
          onChange={onChangeAddressToggle}
          enableAddressToggler={enableAddressToggler}
        />
        {socialLiveDataMessage && !socialLiveData.error && (
          <LiveDataNotice
            location="desktop-search_bar"
            message={socialLiveDataMessage}
            isLoading={socialLiveData.isLoading}
            liveDataComponentId={socialLiveData.data?.id}
            liveDataParams={socialLiveDataParams}
          />
        )}
      </Box>
      {buttonPosition === 'bottom' && (
        <Box display="flex" width="100%" marginTop="xLarge">
          <Button
            onPress={onSubmitWithQuotes}
            loading={isFetchingQuotes}
            disabled={!isFormValid || isLoadingTimeSlots}
            type="submit"
            variant="primary"
            fullWidth
            testID="search-submit-button"
          >
            {cta || tCta('submit')}
          </Button>
        </Box>
      )}
      {handoverOutpostState.isConfirmationEnabled && (
        <HandoverOutpostConfirmationDialog
          visible
          handoverOutpostState={handoverOutpostState}
          onSubmit={acceptHandoverSuggestion}
          onClose={rejectHandoverSuggestions}
        />
      )}
    </>
  );
};

export default SearchBar;

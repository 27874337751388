import { Media } from '@/components/cms/types';
import { useMedia } from '@drivekyte/ui';
import imageShimmer from '@/utils/image-shimmer';
import { Box, Swiper } from '@drivekyte/web-components';
import NextImage from 'next/image';
import React from 'react';
import styled from 'styled-components';

const DEFAULT_IMAGE_WIDTH = 378;
const DEFAULT_IMAGE_HEIGHT = 213;

type CarGalleryProps = {
  title: string;
  images: Media[];
  onPress: () => void;
  maxHeight?: number;
  maxWidth?: number;
};

const CarGallery = ({
  title,
  images,
  onPress,
  maxHeight,
  maxWidth,
}: CarGalleryProps) => {
  const media = useMedia();

  const handlePress = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (media.xs) {
      onPress();
      return;
    }
    event.stopPropagation();
  };

  return (
    <Swiper>
      {(images || []).map((image) => (
        <Box
          key={`${image.id}-images`}
          onPress={handlePress}
          style={{
            width: maxWidth || DEFAULT_IMAGE_WIDTH,
            height: maxHeight || DEFAULT_IMAGE_HEIGHT,
          }}
          position="relative"
          borderRadius="base"
        >
          <Image
            alt={`${title} image`}
            src={image?.attributes?.url}
            placeholder="blur"
            blurDataURL={imageShimmer(
              DEFAULT_IMAGE_WIDTH,
              DEFAULT_IMAGE_HEIGHT,
            )}
            fill
            quality={media.xs ? 10 : 25}
            sizes="100vw"
            style={{
              objectFit: 'cover',
            }}
          />
        </Box>
      ))}
    </Swiper>
  );
};

const Image = styled(NextImage)`
  border-radius: ${({ theme }) => theme.borderRadius.base}px;
`;

export default CarGallery;

import React from 'react';
import { Box, Switch, Typography } from '@drivekyte/web-components';

type ReturnTogglerProps = {
  sameLocationToggleLabel: string;
  value: boolean;
  onChange: (nextValue: boolean) => void;
};

const ReturnToggler = ({
  sameLocationToggleLabel,
  value,
  onChange,
}: ReturnTogglerProps) => {
  const handleChange = (nextValue: boolean) => onChange(nextValue);

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      <Box marginRight="small">
        <Switch onChange={handleChange} value={value} />
      </Box>
      <Box>
        <Typography color="quaternary" variant="label-1">
          {sameLocationToggleLabel}
        </Typography>
      </Box>
    </Box>
  );
};

export default ReturnToggler;

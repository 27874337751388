import React from 'react';
import { Box, Button, Grid, Typography } from '@drivekyte/web-components';
import { Box as BoxV2, useMedia } from '@drivekyte/ui';
import Image from 'next/image';
import styled, { css } from 'styled-components';
import SplitParagraph from '@/components/cms/split-paragraph';
import WPTypography from '@/components/typography';
import { TripItemProps } from './types';

const TripItem = ({
  index,
  title,
  subtitle,
  image,
  description,
  carClass,
  recommendation,
  onSearch,
}: TripItemProps) => {
  const media = useMedia();
  const isTablet = media.sm;
  const imageUrl = image?.attributes?.url;

  return (
    <Box marginBottom="small">
      <Grid
        container
        spacing={4}
        direction={
          isTablet ? 'column-reverse' : index % 2 ? 'row' : 'row-reverse'
        }
      >
        <Grid item md={6} sm={12}>
          <Box position="relative" height="100%">
            <ResponsiveSquare>
              <BoxV2 padding="$medium" $gtMd={{ padding: '$xLarge' }}>
                <Box marginBottom="small">
                  <WPTypography
                    fontFamily="Gilroy"
                    color="primary"
                    fontSize="28"
                    fontWeight={800}
                    variant="h3"
                  >
                    {`${index}. ${title}`}
                  </WPTypography>
                </Box>
                <Box marginBottom="small">
                  <WPTypography
                    variant="p"
                    fontSize="17"
                    fontWeight={800}
                    color="primary"
                  >
                    {subtitle}
                  </WPTypography>
                </Box>
                <SplitParagraph>{description}</SplitParagraph>
              </BoxV2>
              <Box
                padding="xLarge"
                width={isTablet ? 'auto' : '100%'}
                position={isTablet ? 'relative' : 'absolute'}
                bottom={0}
                left={0}
              >
                <Grid container justifyContent="space-around">
                  <Grid item sm={6}>
                    <Typography variant="body-2" center color="tertiary">
                      {recommendation}
                    </Typography>
                    <Typography
                      variant="body-1"
                      center
                      color="tertiary"
                      fontWeight={800}
                    >
                      {carClass}
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Button onPress={onSearch}>Book Now</Button>
                  </Grid>
                </Grid>
              </Box>
            </ResponsiveSquare>
          </Box>
        </Grid>
        {imageUrl && (
          <Grid item md={6} sm={12}>
            <Box
              overflow="hidden"
              borderRadius="large"
              height="100%"
              minHeight="300px"
              position="relative"
            >
              <ResponsiveSquare>
                <Image
                  src={imageUrl}
                  alt={`Image ${index + 1}`}
                  layout="fill"
                  objectFit="cover"
                  quality={media.xs ? 25 : isTablet ? 50 : 25}
                />
              </ResponsiveSquare>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const ResponsiveSquare = styled.div`
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.md}) {
      padding-bottom: 105%;
      position: relative;
      top: 0;
      left: 0;
      height: 0;
    }
    @media (min-width: ${theme.breakpoints.lg}) {
      padding-bottom: 86.25%;
    }
    background: rgba(9, 138, 34, 0.05);
    border-radius: ${theme.borderRadius.large}px;
    overflow: hidden;
  `}
`;

export default TripItem;

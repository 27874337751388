import React from 'react';
import { Box, Typography } from '@drivekyte/web-components';
import { useMedia } from '@drivekyte/ui';
import NextImage from 'next/image';
import styled from 'styled-components';
import {
  MAX_IMAGE_HEIGHT,
  MAX_IMAGE_WIDTH,
} from '@/components/cms/service-areas/constants';
import imageShimmer from '@/utils/image-shimmer';
import { ServiceAreaItemProps } from './types';

const ServiceAreaItem = ({
  imageUrl,
  name,
  allowBookingModal,
  onClick,
}: ServiceAreaItemProps) => {
  const media = useMedia();

  return (
    <Box marginBottom="xLarge" marginRight={media.sm ? 'xLarge' : undefined}>
      <Box
        display="flex"
        position="relative"
        borderRadius="round"
        cursor={allowBookingModal ? 'pointer' : 'default'}
        height={`${MAX_IMAGE_HEIGHT}px`}
        width={`${MAX_IMAGE_WIDTH}px`}
        marginBottom="tiny"
      >
        {imageUrl && (
          <Image
            fill
            style={{ objectFit: 'cover' }}
            src={imageUrl}
            alt={`${name} Image`}
            placeholder="blur"
            blurDataURL={imageShimmer(MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT)}
            onClick={onClick}
            quality={media.sm ? 1 : 75}
          />
        )}
      </Box>
      <Box width="180px">
        <Typography variant="label-2" color="quinary">
          {name.toUpperCase()}
        </Typography>
      </Box>
    </Box>
  );
};

const Image = styled(NextImage)`
  border-radius: ${({ theme }) => theme.borderRadius.large}px;
`;

export default ServiceAreaItem;

import React, { useState } from 'react';
import { Place } from '@drivekyte/use-places-search';
import { Box, Icon, Icons16, Typography } from '@drivekyte/web-components';
import SearchAddressModal from '@/components/search-address-modal';

type WhereItemProps = {
  title: string;
  placeholder: string;
  value?: string;
  iconName: Icons16;
  onChange: (address: Place) => void;
};

const WhereItem = ({
  title,
  placeholder,
  value,
  iconName,
  onChange,
}: WhereItemProps) => {
  const [modalVisibility, setModalVisibility] = useState(false);

  return (
    <>
      <Box
        paddingHorizontal="xSmall"
        paddingVertical="tiny"
        display="flex"
        alignItems="center"
        onPress={() => setModalVisibility(true)}
      >
        <Box marginRight="xSmall">
          <Icon name={iconName} />
        </Box>
        <Box>
          <Typography variant="label-3" color="tertiary">
            {title}
          </Typography>
          {value ? (
            <Typography color="primary" variant="label-3">
              {value}
            </Typography>
          ) : (
            <Typography color="quinary" variant="label-3">
              {placeholder}
            </Typography>
          )}
        </Box>
      </Box>
      <SearchAddressModal
        title={title}
        visible={modalVisibility}
        onClose={() => setModalVisibility(false)}
        onSelect={onChange}
        searchDefaultValue={value}
      />
    </>
  );
};

export default WhereItem;

import React from 'react';
import { Box, Container } from '@drivekyte/web-components';
import styled, { css } from 'styled-components';
import { ThemeBackground } from '@/components/cms/types';
import Markdown from '@/components/markdown';
import { RichText as RichTextType } from './types';

const RichText = ({ data }: { data: RichTextType }) => {
  return (
    <ShadedContainer backgroundTheme={data.theme}>
      <Container style={{ backgroundColor: 'transparent' }}>
        <Box marginVertical="xxLarge">
          <Markdown>{data.content}</Markdown>
        </Box>
      </Container>
    </ShadedContainer>
  );
};

const ShadedContainer = styled.div<{ backgroundTheme?: ThemeBackground }>`
  ${({ backgroundTheme }) => css`
    background: ${backgroundTheme === 'light-green'
      ? 'rgba(9, 138, 34, 0.05)'
      : 'none'};
  `}
`;

export default RichText;

import React, { useState } from 'react';
import { Box, Icon, Typography } from '@drivekyte/web-components';
import { formatDateTime } from '@/utils/date-formatter';
import EnhancedCalendar from '../enhanced-calendar';

type WhenProps = {
  endDate: Date;
  endAddress: string;
  startDate: Date;
  startAddress: string;
  serviceAreaUUID?: string;
  endServiceAreaUUID?: string;
  onChangeDates: (
    nextEndDate: string,
    nextStartDate: string,
    nextEndTimeZone?: string,
    nextStartTimeZone?: string,
  ) => void;
};

const When = ({
  endDate,
  endAddress,
  startDate,
  startAddress,
  serviceAreaUUID,
  endServiceAreaUUID,
  onChangeDates,
}: WhenProps) => {
  const [calendarVisiblity, setCalendarVisiblity] = useState(false);

  return (
    <>
      <Box
        backgroundColor="elevated"
        borderBottomWidth="large"
        borderColor="primary"
        borderRadius="base"
        borderWidth="large"
        boxShadow="base"
        marginTop="xSmall"
        borderStyle="solid"
        onPress={() => setCalendarVisiblity(true)}
      >
        <Box
          display="flex"
          flexDirection="row"
          padding="tiny"
          borderColor="primary"
          alignItems="center"
        >
          <Box marginRight="xSmall" alignItems="center" justifyContent="center">
            <Icon name="16-calendar" color="primary" />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box marginRight="tiny">
              <Typography color="tertiary" variant="label-3">
                From
              </Typography>
              <Box marginTop="tiny">
                <Typography color="tertiary" variant="label-3">
                  To
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography color="primary" variant="label-3">
                {formatDateTime(startDate)}
              </Typography>
              <Box marginTop="tiny">
                <Typography color="primary" variant="label-3">
                  {formatDateTime(endDate)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <EnhancedCalendar
        onClose={() => setCalendarVisiblity(false)}
        visible={calendarVisiblity}
        onSubmit={onChangeDates}
        endDate={endDate}
        endAddress={endAddress}
        startDate={startDate}
        startAddress={startAddress}
        serviceAreaUUID={serviceAreaUUID}
        endServiceAreaUUID={endServiceAreaUUID}
      />
    </>
  );
};

export default When;

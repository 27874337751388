import React from 'react';
import { Button } from '@drivekyte/web-components';
import useTranslation from '@/hooks/use-translation';

export type SearchSubmitProps = {
  loading?: boolean;
  disabled?: boolean;
  cta?: string;
};

const SearchSubmit = ({ loading, disabled, cta }: SearchSubmitProps) => {
  const { t } = useTranslation('components.search');

  return (
    <Button
      disabled={disabled}
      loading={loading}
      type="submit"
      variant="primary"
      fullWidth
      testID="search-submit-button"
    >
      {cta ?? t('submit')}
    </Button>
  );
};

export default SearchSubmit;

import React from 'react';
import SectionHeader from '@/components/growth/section-header';
import useServiceAreas from '@/hooks/use-service-areas';
import ServiceAreas from './components/service-areas';
import { ServiceAreasListProps } from './types';

const ServiceAreasList = (props: ServiceAreasListProps) => {
  const data = props?.data;
  const header = data?.header;
  const { data: serviceAreasData } = useServiceAreas();

  return (
    <ServiceAreas
      serviceAreas={serviceAreasData ?? []}
      expandLabel={data?.expandLabel}
      clickOnItemLabel={data?.clickOnItemLabel}
    >
      <SectionHeader
        tipText={data?.header?.tipText}
        primaryText={header?.primaryText}
        successText={header?.successText}
        captionText={header?.captionText}
        captionBoldText={header?.captionBoldText}
        noMargin
      />
    </ServiceAreas>
  );
};

export default ServiceAreasList;

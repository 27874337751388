type BorderedSVGProps = {
  width: number;
  height: number;
  children: React.ReactNode;
};

export const BorderedSVG = ({ children, width, height }: BorderedSVGProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <g clipPath="url(#clip0_13030_4183)">
        <path
          d="M16 3.19922H3.20001V15.9992"
          stroke="#20D14F"
          strokeWidth="2"
        />
        <path
          d="M156.8 15.9992L156.8 3.19922L144 3.19922"
          stroke="#20D14F"
          strokeWidth="2"
        />
        <path
          d="M3.20001 144.001L3.20001 156.801L16 156.801"
          stroke="#20D14F"
          strokeWidth="2"
        />
        <path
          d="M144 156.801L156.8 156.801L156.8 144.001"
          stroke="#20D14F"
          strokeWidth="2"
        />
        {children}
      </g>
      <defs>
        <clipPath id="clip0_13030_4183">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

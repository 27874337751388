import React, { MouseEvent, useEffect, useRef } from 'react';
import {
  Box,
  DateTimePicker,
  EmptyState,
  Popover,
  Typography,
} from '@drivekyte/web-components';
import styled from 'styled-components';
import InputText from '../input-text';
import { InputDateProps } from './types';

const InputDate = ({
  datePickerProps,
  formattedValue,
  label,
  onSelect,
  popoverProps,
  timePickerProps,
  value,
  disabled,
}: InputDateProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isActive = popoverProps.visible;
  const optionsCount = timePickerProps.options.length;

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus();
    }
  }, [isActive]);

  const handleOpenPopover = (event?: MouseEvent) => {
    event?.preventDefault();
    if (!disabled) popoverProps.onRequestToOpen();
  };

  const renderOptionsEmptyState = () => (
    <EmptyStateContainer>
      <EmptyState
        descriptionText="There are no time slots available for this date."
        illustrationName="empty-state"
        titleText="Check your location"
      />
    </EmptyStateContainer>
  );

  return (
    <Box width="100%" style={{ opacity: disabled ? 0.5 : 1 }}>
      <Popover
        offset={popoverProps.offset}
        onRequestToClose={popoverProps.onRequestToClose}
        renderContent={() => (
          <Box style={{ textAlign: 'center' }}>
            <PickUpSpreadWrapper>
              <Typography variant="label-3" color="info">
                Be ready to receive your Kyte +/- 15 min of the scheduled time
              </Typography>
            </PickUpSpreadWrapper>
            <Box padding="xLarge">
              <DateTimePicker
                datePickerProps={datePickerProps}
                onSelect={onSelect}
                selectedDate={value}
                timePickerProps={{
                  loading: timePickerProps.loading,
                  titleText: timePickerProps.titleText,
                  options: timePickerProps.options,
                  renderEmptyState: optionsCount
                    ? undefined
                    : renderOptionsEmptyState,
                }}
              />
            </Box>
          </Box>
        )}
        visible={popoverProps.visible}
        width="632px"
        zIndex={99}
      >
        <Box
          borderColor={isActive ? 'tertiary' : 'transparent'}
          borderRadius="base"
          borderStyle="solid"
          borderWidth="large"
          boxSizing="border-box"
          display="flex"
          flex={1}
          flexDirection="column"
          height="78px"
          justifyContent="space-around"
          onPress={handleOpenPopover}
          padding="small"
        >
          <Label htmlFor={label}>{label}</Label>
          <InputText
            id={label}
            onFocus={handleOpenPopover}
            readOnly
            ref={inputRef}
            value={formattedValue}
          />
        </Box>
      </Popover>
    </Box>
  );
};

const Label = styled.label`
  font-family: Hanken Grotesk;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #046720;
  display: inline;
`;

const EmptyStateContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
  zoom: 0.8;
`;

const PickUpSpreadWrapper = styled.div`
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.base}px;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.base}px;
  background-color: ${({ theme }) => theme.colors.marinerLight};
`;

export default InputDate;

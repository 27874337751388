import { Box, Typography, useMedia } from '@drivekyte/ui';
import Image from 'next/image';
import IconProps from '../icon-props';
import { IconPropData } from '../icon-props/icon-props.type';
import { MediaData } from '../types';

type VisionModuleProps = {
  data: {
    header: string;
    image: MediaData;
    iconProps: {
      IconPropItem: IconPropData[];
    };
    imgAspectRatio: number;
  };
};

const VisionModule = ({ data }: VisionModuleProps) => {
  const { header, image, iconProps, imgAspectRatio } = data;
  const imgWidth = window.innerWidth + window.innerWidth * 0.1;

  const media = useMedia();

  return (
    <Box gap="$huge" alignItems="center">
      <Box>
        <Typography
          fontFamily="$gilroy"
          fontSize={40}
          fontWeight={600}
          lineHeight={48}
          color="$contentPrimary"
          textAlign="center"
          letterSpacing={-0.5}
          // @ts-expect-error
          overflowWrap="break-word"
          maxWidth={760}
        >
          {header}
        </Typography>
      </Box>
      {media.gtSm && (
        <Image
          src={image.data.attributes.url}
          alt="Vision image"
          width={imgWidth}
          height={imgWidth / (imgAspectRatio ?? 6.53)}
          style={{
            objectFit: 'contain',
          }}
          sizes="110vw"
        />
      )}
      {iconProps && <IconProps data={iconProps} />}
    </Box>
  );
};

export default VisionModule;

import AnalyticsEvents from '@/config/analytics-events/analytics-events';
import useAnalyticsWithSource from '@/hooks/use-analytics-with-source';
import usePush from '@/hooks/use-push';
import PosthogFlags, { BookDiscountModuleGroups } from '@/types/posthog-flags';
import { Close } from '@drivekyte/icons';
import { Box, Button, IconButton, Typography, useMedia } from '@drivekyte/ui';
import { usePostHog } from 'posthog-js/react';
import { useState } from 'react';
import { BookNowCTAProps } from './types';

const BookDiscountedRideCta = ({ data }: BookNowCTAProps) => {
  const [isCTABannerVisible, setIsCTABannerVisible] = useState(true);
  const media = useMedia();
  const push = usePush();
  const posthog = usePostHog();
  const { trackEvent } = useAnalyticsWithSource();

  const isExperimentEnabled =
    posthog.getFeatureFlag(PosthogFlags.BookDiscountModule) ===
    BookDiscountModuleGroups.enabled;

  const applyPromoCode = () => {
    if (!data.discountCode) {
      return;
    }

    trackEvent(AnalyticsEvents.MerchandisingStrategy.BookDiscountCTAPressed);
    push({ code: data.discountCode }, { scroll: true });
  };

  const handleCloseCTABanner = () => {
    setIsCTABannerVisible(false);
  };

  if (!isCTABannerVisible || !isExperimentEnabled) {
    return null;
  }

  return (
    <Box $sm={{ padding: '$small' }} paddingVertical="$medium">
      <Box
        padding="$xLarge"
        borderRadius={24}
        backgroundColor="#064916"
        alignContent="space-between"
        justifyContent="space-between"
        $gtSm={{ flexDirection: 'row' }}
        $sm={{
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        maxWidth={1280}
        alignSelf="center"
        width="100%"
      >
        <Box
          flex={1}
          marginRight="$medium"
          flexDirection="column"
          justifyContent="center"
          $sm={{ marginRight: '$none' }}
        >
          <Typography
            textAlign="left"
            marginBottom="$small"
            variant="headingLarge"
            color="$contentInversePrimary"
            $sm={{ textAlign: 'center', marginBottom: '$none' }}
          >
            {data.headline}
          </Typography>
          {!media.gtSm && (
            <Box
              alignContent="center"
              justifyContent="center"
              marginVertical="$medium"
            >
              <Typography textAlign="center" color="$contentInversePrimary">
                {data.subtitle}
              </Typography>
            </Box>
          )}
          <Box
            alignItems="center"
            flexDirection="row"
            alignContent="center"
            justifyContent="flex-start"
            $sm={{ justifyContent: 'center' }}
          >
            {media.gtSm && (
              <Typography color="$contentInversePrimary">
                {data.subtitle}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="row">
          <Button
            size="large"
            variant="tertiary"
            onPress={applyPromoCode}
            $sm={{ flex: 1, size: 'medium' }}
            cursor="pointer"
          >
            {data.cta}
          </Button>
          <Box display="flex" justifyContent="center" paddingLeft="$small">
            <IconButton
              cursor="pointer"
              size="small"
              icon={Close}
              onPress={handleCloseCTABanner}
            ></IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookDiscountedRideCta;

import { Box } from '@drivekyte/ui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import sectionComponents from './section-components';

export type SectionCms = {
  id: string;
  __component: string;
  [key: string]: any;
};

type SectionProps = {
  section: SectionCms;
};

type SectionsProps = {
  sections: SectionCms[];
  preview: boolean | null;
  gap?: string;
};

// Display a section individually
const Section = ({ section }: SectionProps) => {
  // map section component (coming from Strapi) to client component
  const SectionComponent =
    sectionComponents[section.__component as keyof typeof sectionComponents];

  if (!SectionComponent) {
    return null;
  }

  // @ts-ignore
  return <SectionComponent data={section} />;
};

const PreviewModeBanner = () => {
  const { asPath } = useRouter();

  return (
    <Box>
      Preview mode is on.{' '}
      <Link href={`/api/exit-preview?redirect=${asPath}`}>Turn off</Link>
    </Box>
  );
};

const Sections = ({ sections, preview, gap }: SectionsProps) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" gap={`$${gap}`}>
      {/* Show a banner if preview mode is on */}
      {preview && <PreviewModeBanner />}
      {/* Show the actual sections */}
      {sections.map((section: SectionCms) => (
        <Section
          section={section}
          key={`${section.__component}${section.id}`}
        />
      ))}
    </Box>
  );
};

export default Sections;

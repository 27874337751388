import {
  GetThemeValueForKey,
  Typography,
  TypographyVariant,
} from '@drivekyte/ui';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React, { MouseEvent } from 'react';

export type LinkProps = {
  active?: boolean;
  children: string | React.ReactNode;
  color?: GetThemeValueForKey<'color'>;
  onPress?: (event: MouseEvent<HTMLAnchorElement>) => void;
  openNewWindow?: boolean;
  shallow?: boolean;
  testID?: string;
  variant?: TypographyVariant;
} & NextLinkProps;

const Link = ({
  active = false,
  children,
  color = '$contentPrimary',
  href,
  onPress,
  openNewWindow,
  shallow,
  testID,
  variant = 'labelMedium',
}: LinkProps) => (
  <NextLink
    data-testid={testID}
    passHref
    href={href}
    onClick={onPress}
    rel={openNewWindow ? 'noopener noreferrer' : undefined}
    target={openNewWindow ? '_blank' : '_self'}
    shallow={shallow}
    aria-label={testID}
    style={{ textDecoration: active ? 'underline' : 'initial' }}
  >
    <Typography
      variant={variant}
      color={color}
      hoverStyle={{ textDecorationLine: 'underline' }}
    >
      {children}
    </Typography>
  </NextLink>
);

export default Link;

import { useState, useRef, useEffect } from 'react';
import { BasicInput, Box } from '@drivekyte/ui';
import { Place } from '@drivekyte/use-places-search';
import SearchAddressModal from '@/components/search-address-modal';
import useAnalyticsWithBookingCart from '@/hooks/use-analytics-with-booking-cart-data';

type WhereItemProps = {
  title: string;
  value?: string;
  onChange: (address: Place) => void;
  label?: string;
  placeholder?: string;
  isStartLeg?: boolean;
  isEndLeg?: boolean;
};

const WhereItem = ({
  title,
  value,
  onChange,
  label,
  placeholder,
  isStartLeg,
  isEndLeg,
}: WhereItemProps) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const { trackEvent, AnalyticsEvents } = useAnalyticsWithBookingCart();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleTrackSearchTypeEvent = (address: string) => {
    trackEvent(AnalyticsEvents.BookingFlow.SearchAddressType, {
      address,
      is_start_leg: !!isStartLeg,
      is_end_leg: !!isEndLeg,
    });
  };

  const handleOnPress = () => {
    trackEvent(AnalyticsEvents.BookingFlow.SearchBarClicked);
    setModalVisibility(true);
  };

  useEffect(() => {
    if (!modalVisibility) {
      inputRef.current?.blur();
    }
  }, [modalVisibility]);

  return (
    <>
      <Box onPress={handleOnPress}>
        <BasicInput label={label ?? title} value={value} ref={inputRef} />
      </Box>
      <SearchAddressModal
        title={title}
        visible={modalVisibility}
        onClose={() => setModalVisibility(false)}
        onSelect={onChange}
        onAutoCompleteDataUpdate={handleTrackSearchTypeEvent}
        searchDefaultValue={value}
        inputPlaceholder={placeholder}
      />
    </>
  );
};

export default WhereItem;

import { Box, Typography } from '@drivekyte/ui';
import LineItem from './line-item';
import TableData from './table-data';
import TableHeader from './table-header';

type ComparisonTableProps = {
  data: {
    header: string;
    columns: {
      id: number;
      label: string;
      isHighlighted?: boolean;
    }[];
    lines: {
      id: number;
      description: string;
      columns: {
        id: number;
        checked: boolean;
        isHighlighted?: boolean;
      }[];
    }[];
  };
};

const ComparisonTable: React.FC<ComparisonTableProps> = ({ data }) => {
  const { header, columns, lines } = data;

  return (
    <Box gap="$huge" width="100%">
      <Typography variant="headingXLarge">{header}</Typography>
      <Box overflow="scroll" width="100%">
        <table
          style={{
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: '0px',
            minWidth: '480px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: `calc(100% / ${columns.length + 1}` }}></th>
              {columns.map((column) => (
                <TableHeader
                  key={column.id}
                  isHiglighted={column.isHighlighted}
                  label={column.label}
                  totalColumns={columns.length + 1}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {lines.map((line, index) => (
              <tr key={line.id}>
                <LineItem>{line.description}</LineItem>
                {line.columns.map((lineColumn) => (
                  <TableData
                    key={lineColumn.id}
                    checked={lineColumn.checked}
                    isLast={index === lines.length - 1}
                    isHighlighted={lineColumn.isHighlighted}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default ComparisonTable;

import { Box, Typography } from '@drivekyte/ui';
import Step from './step';
import StepSeparator from './step-separator';
import { StepperItemProps } from './stepper.type';

type StepperProps = {
  data: {
    id: number;
    steps: StepperItemProps[];
    header?: string;
  };
};

const Stepper = ({ data }: StepperProps) => {
  return (
    <Box alignItems="center">
      {data.header && (
        <Typography
          variant="headingXLarge"
          textAlign="center"
          marginBottom="$xLarge"
        >
          {data.header}
        </Typography>
      )}
      <Box
        $gtMd={{ width: '800px' }}
        $gtSm={{ width: '712px' }}
        $gtXs={{ width: '432px' }}
        width="327px"
      >
        {data.steps.map((step, index) => (
          <Box
            key={step.id}
            gap="$tiny"
            $gtSm={{ gap: 'unset' }}
            alignItems="center"
          >
            <Step
              id={step.id}
              key={step.id}
              title={step.title}
              description={step.description}
              isRight={index % 2 !== 0}
              linkIcon={step.linkIcon || 'Check'}
            />
            {index !== data.steps.length - 1 && <StepSeparator />}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Stepper;

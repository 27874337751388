import React, { MouseEvent, useEffect, useRef } from 'react';
import {
  Box,
  DatePickerProps,
  DateTimePicker,
  EmptyState,
  Popover,
  TimePickerProps,
  Typography,
} from '@drivekyte/web-components';
import styled from 'styled-components';
import useTranslation from '@/hooks/use-translation';
import InputText from '../input-text';

export type InputDateProps = {
  datePickerProps: Omit<DatePickerProps, 'onSelect' | 'selectedDate'>;
  formattedValue: string;
  label: string;
  onSelect: (
    nextSelectedDate: Date | undefined,
    origin: 'date-picker' | 'time-picker',
  ) => void;
  popoverProps: {
    offset: [number, number];
    onRequestToClose: () => void;
    onRequestToOpen: () => void;
    visible: boolean;
  };
  timePickerProps: Pick<
    TimePickerProps,
    'titleText' | 'options' | 'loading' | 'tooltip'
  >;
  value: Date;
  testID?: string;
  disabled?: boolean;
  isTimeInvalid?: boolean;
};

const InputDate = ({
  datePickerProps,
  formattedValue,
  label,
  onSelect,
  popoverProps,
  timePickerProps,
  value,
  testID,
  disabled,
  isTimeInvalid,
}: InputDateProps) => {
  const { t } = useTranslation('components.search');
  const { t: tCommon } = useTranslation('', { ns: 'common' });
  const inputRef = useRef<HTMLInputElement>(null);
  const isActive = popoverProps.visible;
  const optionsCount = timePickerProps.options.length;

  useEffect(() => {
    if (isActive) {
      inputRef.current?.focus();
    }
  }, [isActive]);

  const handleOpenPopover = (event?: MouseEvent) => {
    event?.preventDefault();
    if (!disabled) popoverProps.onRequestToOpen();
  };

  const renderOptionsEmptyState = () => (
    <EmptyStateContainer>
      <EmptyState
        descriptionText={t('errors.address-without-number.description')}
        illustrationName="empty-state"
        titleText={t('errors.address-without-number.title')}
      />
    </EmptyStateContainer>
  );

  return (
    <Box width="100%" style={{ opacity: disabled ? 0.5 : 1 }}>
      <Popover
        offset={popoverProps.offset}
        onRequestToClose={popoverProps.onRequestToClose}
        renderContent={() => (
          <Box>
            <PickUpSpreadWrapper>
              <Typography variant="label-3" color="info">
                {tCommon('pick-up-spread')}
              </Typography>
            </PickUpSpreadWrapper>
            <Box padding="xLarge">
              <DateTimePicker
                datePickerProps={datePickerProps}
                onSelect={onSelect}
                selectedDate={value}
                testID={testID}
                timePickerProps={{
                  loading: timePickerProps.loading,
                  titleText: timePickerProps.titleText,
                  options: timePickerProps.options,
                  renderEmptyState: optionsCount
                    ? undefined
                    : renderOptionsEmptyState,
                }}
              />
            </Box>
          </Box>
        )}
        visible={popoverProps.visible}
        width="632px"
        zIndex={99}
      >
        <Box
          borderColor={isActive ? 'tertiary' : 'transparent'}
          borderRadius="base"
          borderStyle="solid"
          borderWidth="large"
          boxSizing="border-box"
          display="flex"
          flex={1}
          flexDirection="column"
          height={isTimeInvalid ? '86px' : '78px'}
          justifyContent="space-around"
          onPress={handleOpenPopover}
          padding="small"
        >
          <Label htmlFor={label}>{label}</Label>
          <InputText
            id={label}
            onFocus={handleOpenPopover}
            readOnly
            ref={inputRef}
            value={formattedValue}
          />
          {isTimeInvalid && (
            <Typography variant="label-3" color="danger" fontWeight={400}>
              Choose an available timeslot
            </Typography>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

const Label = styled.label`
  font-family: Hanken Grotesk;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #046720;
  display: inline;
`;

const EmptyStateContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
  zoom: 0.8;
`;

const PickUpSpreadWrapper = styled.div`
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.base}px;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.base}px;
  background-color: ${({ theme }) => theme.colors.marinerLight};
`;

export default InputDate;
